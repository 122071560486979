import {useEffect, useState} from "react";

const ImageUserBlank = ({user}) => {
    const [image, setImage] = useState("")
    const [exist, setExist] = useState(false)
    useEffect(() => {
        const url = userImageSrc(user)
        // console.log(user)
        setImage(url)
        imageExists(url)
    }, [user])

    const imageExists = url => {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            setExist(true);
        };
        img.onerror = function () {
            setExist(false);
        };
    }
    return (

                <div className="image" style={{backgroundImage: `url(${image})`}}>
                    {!exist && (
                        <span>{user?.firstName?.substring(0, 1)}</span>
                    )}
                </div>
    )
}

export default ImageUserBlank

export const userImageSrc = (user) => {
    const imageUrl = user?.imageUrl
    if (user?.imageUrl !== ``) {
        if (imageUrl?.includes(process.env.REACT_APP_API_BASE)) {
            return user?.imageUrl
        } else {
            return `${process.env.REACT_APP_API_BASE}/media/${user?.imageUrl}`
        }
    }
    // if (user?.imageUrl !== ``) {
    //     return user?.imageUrl
    // }
    // return `${process.env.REACT_APP_API_BASE}/media/${user?.imageUrl}`


    if (user?.media)
        return `${process.env.REACT_APP_API_BASE}/images/${user?.media.name}`

    return ``
}
