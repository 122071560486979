import cover_step3 from "../../../../common/assets/img/cover/cover_write_memo_step3.gif";
import {
    MemoDeathTrigger,
    MemoKycRequired,
    MemoReceiverAlert,
    MemoStatus,
    MemoType
} from "../../../../common/config/app";
import ReactTooltip from "react-tooltip";
import {CgInfo} from "react-icons/cg";
import {GrFormNext, GrFormPrevious} from "react-icons/gr";
import React, {useEffect, useState} from "react";
import {memoWriteState, setMemoDescription, setMemoStep} from "../../../../features/reducer/memo/memoWriterSlice";
import {useDispatch, useSelector} from "react-redux";
import {notificationErrorCustomMessage} from "../../../../common/config/message";
import {setOpenModalContent} from "../../../../features/reducer/utility";
import parse from "html-react-parser";
import logo from "../../../../common/assets/img/mali/icon_app.png";
import ContentModal from "../../dashboard/ContentModal";
import {useTranslation} from "react-i18next";

const MemoSetting = () => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [data, setData] = useState({})
    const [error, setError] = useState({})
    const {t} = useTranslation();

    useEffect(() => {
        const desc = memo.description
        setData({
            title: desc?.title,
            type: desc?.type,
            deathTrigger: desc?.deathTrigger,
            kycRequired: desc?.kycRequired,
            receiverAlert: desc?.receiverAlert,
            memoStatus: desc?.memoStatus
        })
    }, [memo.description])

    const handleInput = e => {
        const {name, value} = e.target
        let v = value
        if (name == `deathTrigger` || name == `kycRequired` || name == `receiverAlert`) {
            v = parseInt(value)
        }
        if(typeof error[name] !== `undefined`) {
            let e = {...error}
            delete e[name]
            setError(e)
        }
        setData({...data, [name]: v})
        dispatch(setMemoDescription({name: name, value: v}))
    }

    const handleStep = key => {
        let errors = {}, hasError = false
        if (key === `preview`) {
            if (data.title === ``) {
                errors.title = true
                hasError = true
            }
            if (data.type === `` || data.type === 0 || data.type === "0") {
                errors.type = true
                hasError = true
            }
        }
        if (hasError) {
            notificationErrorCustomMessage(t('writememo.setting-alert-input-all'), `info`)
            setError(errors)
            return
        }
        dispatch(setMemoStep(key))
    }

    return (
        <>
            <div className="d-flex col-12 justify-content-between">
                <button className="btn btn-primary btn-prev" onClick={() => handleStep('receiver')}>
                    <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                </button>
                <button className="btn btn-primary btn-next" onClick={() => handleStep('write')}>
                    {t('writememo.next-button-page2')} <GrFormNext className="memo_write_icon"/>
                </button>
            </div>
            <div className="row g-3">
                <div className="col-12">
                    <div className="img-fluid rounded-2 py-5 w-100 d-flex align-items-center  flex-column justify-content-center">
                        <h4 className="fw-bold">2. {t('writememo.setting-topic-h1')}</h4>
                        <img className="image_gif_cover" src={cover_step3}/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className={`form-group mb-1 ${error?.title ? `has-error` : ``}`}>
                        <label className="form-label" htmlFor="dealTitle">{t('writememo.setting-hint-input-name')} <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            id="dealTitle"
                            name="title"
                            className="form-control"
                            placeholder=""
                            value={data?.title}
                            onChange={e => handleInput(e)}
                        />
                    </div>
                </div>
                {/*} <div className="col-sm-6">
                    <div className="form-group mb-1">
                        <label className="form-label" htmlFor="dealStatus">สถานะบันทึก <span className="text-danger">*</span></label>
                        <select id="dealStatus" name="memoStatus" onChange={e => handleInput(e)} value={data?.memoStatus} defaultValue={``} className="form-select">
                            {MemoStatus.map(item => (
                                <option value={item.key}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>{*/}
                <div className="col-sm-6">
                    <div className={`form-group mb-1 ${error?.type ? `has-error` : ``}`}>
                        <label className="form-label" htmlFor="dealPaymentMethod">{t('writememo.setting-hint-label-name')} <span className="text-danger">*</span> (
                            <a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({
                            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>'+ t('writememo.setting-hint-label-name-is')),
                            size: `md`, //sm lg xl
                            content:
                                <ContentModal icon="notebook" content={(t('writememo.setting-content-memo-is-detail'))} />, //component or html tag
                        }))} data-tip={t('writememo.setting-hint-label-name-is')}>{t('writememo.setting-content-read-more')}</a> )</label>
                        <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                        <select id="dealPaymentMethod" name="type" onChange={e => handleInput(e)} value={data?.type} className="form-select">
                            <option value="">{t('writememo.setting-input-option-memo-type')}</option>
                            {MemoType.map(item => (
                                <option value={item.key}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group mb-1">
                        <label className="form-label" htmlFor="dealPaymentMethod">{t('writememo.setting-input-time-perior')} <span className="text-danger">*</span> ({t('writememo.setting-input-time-perior-back')} <a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({
                            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>DEATHTRIGGER '+ t('writememo.setting-input-time-perior-is')),
                            size: `md`, //sm lg xl
                            content:
                                <ContentModal icon="clock" content={parse(t('writememo.setting-content-deathtirgger-is-detail'))}/>, //component or html tag
                        }))} data-tip={t('writememo.setting-content-deathtirgger-is')}>DeathTrigger</a> {t('writememo.setting-content-deathtirgger-work')})</label>
                        <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                        <select id="dealPaymentMethod" name="deathTrigger" onChange={e => handleInput(e)} value={data?.deathTrigger} defaultValue={``} className="form-select">
                            {MemoDeathTrigger.map(item => (
                                <option value={item.key}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6 ">
                    <div className="form-group mb-1">
                        <label className="form-label" htmlFor="dealStatus">{t('writememo.setting-input-condtion-receiver')} <span className="text-danger">*</span> ( <a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({
                            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>'+ t('writememo.setting-hint-why-kyc')),
                            size: `md`, //sm lg xl
                            content:
                                <ContentModal icon="lock" content={parse(t('writememo.setting-hint-why-kyc-detail'))}/>, //component or html tag
                        }))} data-tip={t('writememo.setting-hint-kyc-is')}>{t('writememo.setting-hint-why-kyc')}</a> )</label>
                        <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                        <select id="dealStatus" name="kycRequired" onChange={e => handleInput(e)} value={data?.kycRequired} className="form-select">
                            {MemoKycRequired.map(item => (
                                <option value={item.key}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>


                <div className="col-sm-6 margin-bottom-20">
                    <div className="form-group mb-1">
                        <label className="form-label" htmlFor="dealStatus">{t('writememo.setting-alert-reciver-hint')} <span className="text-danger">*</span>
                            <CgInfo data-tip={t('writememo.setting-alert-reciver-tooltip')} className="icon_form_notice"></CgInfo></label>
                        <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                        <select id="dealStatus" name="receiverAlert" onChange={e => handleInput(e)} value={data?.receiverAlert} defaultValue={``} className="form-select">
                            {MemoReceiverAlert.map(item => (
                                <option value={item.key}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>


            </div>
            <div className="d-flex col-12 justify-content-between">
                <button className="btn btn-primary btn-prev" onClick={() => handleStep('receiver')}>
                    <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                </button>
                <button className="btn btn-primary btn-next" onClick={() => handleStep('write')}>
                    {t('writememo.next-button-page2')} <GrFormNext className="memo_write_icon"/>
                </button>
            </div>
        </>
    )
}

export default MemoSetting
