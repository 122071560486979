import {GiHouse, GiPartyPopper} from "react-icons/gi";
import {MdBusinessCenter} from "react-icons/md";
import {CgInfinity} from "react-icons/cg";

export const Status = [
    "Full", "Empty"
]

export const ContainerStatus = [
    {key: "in", name: "อยู่ในลาน"},
    {key: "out", name: "รับตู้เรียบร้อย"},
]

export const ContainerType = [
    "DC", "HQ", "Tank", "RF",
    "OT", "FR", "DG",
]

export const MonthTH = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
]

export const Roles = [
    {key: `admin`, label: `ผู้ดูแลระบบ`},
    {key: `emp`, label: `พนักงาน`},
]

export const ContactType = [
    {type: `family`, name: `ครอบครัวของคุณ`, className: `bg-label-info`, icon: <GiHouse className="icon_family"/>},
    {
        type: `friend`,
        name: `เพื่อนของคุณ`,
        className: `bg-label-danger`,
        icon: <GiPartyPopper className="icon_friends"/>
    },
    {
        type: `business`,
        name: `ธุรกิจของคุณ`,
        className: `label_business`,
        icon: <MdBusinessCenter className="icon_business"/>
    },
    {type: `etc`, name: `อื่นๆ ที่ไม่ทราบกลุ่ม`, className: `label_etc`, icon: <CgInfinity className="icon_etc"/>},
]


export const MemoType = [
    {key: `memo`, name: `ข้อความปกติ (MEMO)`},
   // {key: `iwish`, name: `ความปรารถนา (I-Wish)`},
    {key: `account`, name: `ข้อมูล Account & Password`},
]

export const MemoDeathTrigger = [
    {key: 0, name: `ทันที`},
    {key: 1, name: `1 สัปดาห์`},
    {key: 2, name: `1 เดือน`},
    {key: 3, name: `3 เดือน`},
    {key: 4, name: `6 เดือน`},
    {key: 5, name: `1 ปี`},
]


export const MemoKycRequired = [
    {key: 0, name: `ไม่ต้องยืนยันตัว`},
    {key: 1, name: `ยืนยันตัวแล้วเท่านั้น (KYC)`},
]

export const MemoReceiverAlert = [
    {key: 0, name: `ปิดการแจ้ง`},
    {key: 1, name: `แจ้งให้ทราบ`},
]

export const MemoStatus = [
    {key: 'draft', name: `แบบร่าง`},
    {key: 'publish', name: `เตรียมส่ง`},
]
