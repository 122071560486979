import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {
    addMemoApi,
    addWidgetApi,
    addWidgetJsonApi,
    deleteMemoApi,
    deleteWidgetApi,
    fetchMemoByReceiverApi,
    fetchMyMemoByApi,
    getMemoApi,
    getMemoGuideApi,
    getMyMemoGuideApi,
    updateMemoApi
} from "../../../common/api/memoApi";
import {setDataWriteMemoProgress, setStep, setWidgetAfterSave} from "./memoSlice";
import {setRedirect} from "../utility";
import {notificationSuccessMessage} from "../../../common/config/message";
import {setMemoStep} from "./memoWriterSlice";

export const fetchAsyncReceiverMemo = createAsyncThunk(
    'memo/fetchAsyncMemos',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchMemoByReceiverApi(filter))
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)
export const fetchAsyncMyMemo = createAsyncThunk(
    'memo/fetchAsyncMyMemo',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchMyMemoByApi(filter))
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const getAsyncMemo = createAsyncThunk(
    'memo/getAsyncMemo',
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getMemoApi(id))
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const updateAsyncMemo = createAsyncThunk(
    'memo/updateAsyncMemo',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateMemoApi(data))
            baseThunk.dispatch(setStep(`receiver`))
            notificationSuccessMessage(`บันทึก MEMO สำเร็จ`)
            baseThunk.dispatch(setRedirect(`/memo/me`))
        } catch (e) {
            console.log(e)
        }
    }
)
export const deleteAsyncMemo = createAsyncThunk(
    'memo/deleteAsyncMemo',
    async ({id, search, handleSearch}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, deleteMemoApi(id))
            notificationSuccessMessage(`ลบ MEMO สำเร็จ`)
            if (typeof handleSearch !== `undefined`) {
                handleSearch()
            } else {
                baseThunk.dispatch(fetchAsyncMyMemo(search))
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export const addAsyncWidgetMemo = createAsyncThunk(
    'memo/addAsyncWidgetMemo',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, addWidgetApi(data))
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const addAsyncWidgetJsonMemo = createAsyncThunk(
    'memo/addAsyncWidgetJsonMemo',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, addWidgetJsonApi(data.form))
            baseThunk.dispatch(setDataWriteMemoProgress({
                progress: parseInt(data.progress) + 1,
                id: response.data.id
            }))
            if (data.success) {
                baseThunk.dispatch(setWidgetAfterSave(response.data))
                baseThunk.dispatch(setStep(`setting`))

            }
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)
export const deleteAsyncWidget = createAsyncThunk(
    'memo/deleteAsyncWidget',
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, deleteWidgetApi(id))

            notificationSuccessMessage("ลบ Widget สำเร็จ")
            if (typeof response?.data?.memoId !== `undefined`) {

            }

        } catch (e) {
            console.log(e)
        }
    }
)
export const getMemoGuideAsync = createAsyncThunk(
    'memo/getMemoGuideAsync',
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getMemoGuideApi())
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const getMyMemoGuideAsync = createAsyncThunk(
    'memo/getMyMemoGuideAsync',
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getMyMemoGuideApi())
            return response.data.data
        } catch (e) {
            console.log(e)
        }
    }
)


