import React from "react";
import {RiCheckboxBlankCircleFill, RiCheckboxBlankCircleLine, RiCheckFill, RiEditLine} from "react-icons/ri";
import {useTranslation} from "react-i18next";



const MemoStatusTag = ({name}) => {

    const {t} = useTranslation();

    switch (name) {
        case 'draft' :
            return (
                <span className="badge bg-mali-darft" data-tip={t('memostatus.tag-draft')}><RiEditLine className="icon_table_size"/> {t('memostatus.tag-draft')}</span>
            )
        case 'publish' :
            return (
                <span className="badge bg-success" data-tip={t('memostatus.tag-publish')}><RiCheckFill className="icon_table_size"/> {t('memostatus.tag-publish')}</span>
            )
        default:
            return (
                <span className="badge bg-secondary rounded-pill" data-tip={t('memostatus.tag-unknow')}>{t('memostatus.tag-unknow')}</span>
            )
    }
}

export default MemoStatusTag
