import {LinkPreview} from "@dhaiwat10/react-link-preview";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {memoWriteState, updateMemoWidgetByIndex} from "../../../../../features/reducer/memo/memoWriterSlice";
import {notificationErrorCustomMessage} from "../../../../../common/config/message";
import {useTranslation} from "react-i18next";

const Link = ({index}) => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [widget, setWidget] = useState()
    const [link, setLink] = useState(``)
    const {t} = useTranslation();

    useEffect(() => {
        if (typeof memo.widgets[index] !== `undefined`) {
            const w = memo.widgets[index]
            setWidget(w)
            setLink(w?.content)
        }
    }, [memo.widgets])

    function handleMessageChange(e) {
        const {value} = e.target
        setLink(value)

        let mWidget = {...widget, content: value}
        dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
    }


    const validURL = str => {
        var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(str)) {
            return false;
        } else {
            return true;
        }
    }
    return (
        <>
            <div className={"widget_link active"}>
                <div className="card-body">
                    {/*{data?.link}*/}
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="floatingInputLinkInput"
                            defaultValue={link}
                            value={link}
                            onChange={e => handleMessageChange(e)}
                            placeholder="https://www.ma-li.io"
                            aria-describedby="floatingInputHelp"
                        />
                        <label htmlFor="floatingInputLinkInput">{t('writememo.form-hint-url')}</label>
                        <div id="floatingInputHelpLink" className="form-text">

                        </div>
                    </div>
                    {widget?.content && validURL(widget?.content) && (
                        <div className="row mt-3 justify-content-center">
                            <div className="col-lg-6">
                                <LinkPreview className="link mt-4" url={widget?.content}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Link
