import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {
    addContactApi,
    addContactByUsernameApi, deleteContactApi,
    fetchMyContactsApi,
    getMyContactsApi, updateContactApi
} from "../../../common/api/contactApi";
import {setRedirect} from "../utility";
import {notificationSuccessMessage} from "../../../common/config/message";

export const getAsyncContact = createAsyncThunk(
    'contact/getAsyncContact',
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getMyContactsApi(id))
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)
export const fetchAsyncContacts = createAsyncThunk(
    'contact/fetchAsyncContacts',
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchMyContactsApi(filter))
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)


export const addAsyncContactByUsername = createAsyncThunk(
    'contact/addAsyncContactByUsername',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, addContactByUsernameApi(data))
            baseThunk.dispatch(setRedirect(`/receiver/list`))
            notificationSuccessMessage("เพิ่มรายชื่อผู้รับสำเร็จ")
        } catch (e) {
            console.log(e)
        }
    }
)

export const addAsyncContact = createAsyncThunk(
    'contact/addAsyncContact',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, addContactApi(data))
            baseThunk.dispatch(setRedirect(`/receiver/list`))
            notificationSuccessMessage("เพิ่มรายชื่อผู้รับสำเร็จ")
        } catch (e) {
            console.log(e)
        }
    }
)

export const updateAsyncContact = createAsyncThunk(
    'contact/updateAsyncContact',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateContactApi(data))
            baseThunk.dispatch(setRedirect(`/receiver/list`))
            notificationSuccessMessage("แก้ไขรายชื่อผู้รับสำเร็จ")
        } catch (e) {
            console.log(e)
        }
    }
)
export const deleteAsyncContact = createAsyncThunk(
    'contact/deleteAsyncContact',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, deleteContactApi(data.id))
            if (typeof data.handleCallback !== `undefined`) {
                data.handleCallback()
            } else {
                baseThunk.dispatch(setRedirect(`/receiver/list`))
            }
            notificationSuccessMessage("ลบรายชื่อผู้รับสำเร็จ")
        } catch (e) {
            console.log(e)
        }
    }
)
