import {useDispatch, useSelector} from "react-redux";
import {memoWriteState, setLeaveMemoPage, setMemoStep} from "../../../../features/reducer/memo/memoWriterSlice";
import React, {useEffect, useState} from "react";
import {
    MemoDeathTrigger,
    MemoKycRequired,
    MemoReceiverAlert,
    MemoStatus,
    MemoType
} from "../../../../common/config/app";
import cover_step5 from "../../../../common/assets/img/explan/stand_typing.png";
import logo from "../../../../common/assets/img/mali/icon_app.png";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ImageUserProfile from "../../../utility/ImageUserProfile";
import MemoTypeTag from "../../../utility/MemoTypeTag";
import {RiCheckboxCircleFill, RiCloseCircleFill} from "react-icons/ri";
import {PieChart} from "react-minimal-pie-chart";
import {GrFormNext, GrFormPrevious, GrSave} from "react-icons/gr";
import {confirmAlert} from "react-confirm-alert";
import {updateMemoAsync, setLogGuideMemoAsync} from "../../../../features/reducer/memo/memoWriterThunk";
import {AiOutlineSave} from "react-icons/ai";
import {getMemoGuides} from "../../../../features/reducer/memo/memoSlice";
import {getProfile} from "../../../../features/reducer/user/userSlice";
import {useTranslation} from "react-i18next";

const MemoOverview = () => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const profile = useSelector(getProfile)
    const [shortMessage, setShortMessage] = useState()
    const [description, setDescription] = useState()

    const queryParams = new URLSearchParams(window.location.search)
    const [guideCodeParams] = useState(queryParams.get("guide"))

    const {t} = useTranslation();


    useEffect(() => {
        if (memo?.widgets?.length) {
            handleShortMessage()
            let data = [
                {title: t('writememo.tooltip-message'), value: 0, color: '#28b5b5', type: 'message', percent: 0},
                {title: t('writememo.tooltip-image'), value: 0, color: '#71DFE7', type: 'image', percent: 0},
                {title: t('writememo.tooltip-video'), value: 0, color: '#C2FFF9', type: 'video', percent: 0},
                {title: t('writememo.tooltip-audio'), value: 0, color: '#FFE652', type: 'audio', percent: 0},
                {title: t('writememo.tooltip-link'), value: 0, color: '#fdac34', type: 'link', percent: 0},
                {title: t('writememo.tooltip-password'), value: 0, color: '#5a8dee', type: 'password', percent: 0},
            ]
            const totalWidget = memo?.widgets?.length
            memo?.widgets?.map(v => {
                data.filter(i => i.type == v.majorType ? {...i, value: i.value++} : {i})
            })

            let dt = data.map(i => {
                let percent = i.value / totalWidget * 100
                return {
                    ...i,
                    title: `${i.value} ${i.title}`,
                    percent: percent
                }
            })
            setDescription({
                deathTrigger: MemoDeathTrigger.find(v => v.key == memo?.description?.deathTrigger),
                receiverAlert: MemoReceiverAlert.find(v => v.key == memo?.description?.receiverAlert),
                type: MemoType.find(v => v.key == memo?.description?.type),
                kyc: MemoKycRequired.find(v => v.key == memo?.description?.kycRequired),
                status: MemoStatus.find(v => v.key == memo?.description?.memoStatus),
                chart: dt
            })


        }
    }, [memo.widgets]);

    const handleShortMessage = () => {
        memo?.widgets?.length && memo?.widgets?.map(widget => {
            if (widget.majorType === `message`) {
                setShortMessage(widget?.content)
                return
            }
        })
    }

    const handleStep = key => {
        dispatch(setMemoStep(key))
    }

    const ClickConfirmAlert = () => {
        confirmAlert({
            title: t('writememo.confirm-title'), message: t('writememo.confirm-detail'), buttons: [{
                label: t('writememo.confirm-yes'), onClick: () => {
                    dispatch(setLeaveMemoPage(false))
                    const dataSet = {memo: memo, guideCode: guideCodeParams};
                    dispatch(updateMemoAsync(dataSet))
                    //console.log(memoRs)

                }
            }, {label: t('writememo.confirm-no')}
            ]
        });
    };

    return (
        <>

            <div className="d-flex col-12 justify-content-between">
                <button className="btn btn-primary btn-prev" onClick={() => handleStep('preview')}>
                    <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                </button>

                    <button className="btn btn-primary btn-next" onClick={() => ClickConfirmAlert()}>
                        <AiOutlineSave className="memo_write_icon"/> {t('writememo.btn-save-real-save')}
                    </button>

            </div>

            <div className="img-fluid rounded-2 py-5 w-100 d-flex align-items-center  flex-column justify-content-center">
                <h4 className="fw-bold">5. {t('writememo.topic-review-h1')}</h4>
                <img className="image_gif_cover2" src={cover_step5}/>
            </div>
            <div className=" h-100 margin-bottom-10">
                <div className="card-header d-flex  justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-3">
                        <img src={logo} alt="Avatar" className="rounded-circle me-3" width="54"/>
                        <div className="card-title mb-0">
                            <h5 className="mb-0">REVIEW</h5>
                            <small className="text-muted">{t('writememo.topic-review-h1-detail')}</small>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex flex-column flex-grow-1">
                        <div className="col-md-8 mb-1">
                            <div className="mb-4">
                                <h6 className="fw-semibold mb-2">{t('writememo.review-title-topic')} :</h6>
                                <p>{memo?.description?.title}</p>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex flex-column flex-grow-1">
                        <div className="col-md-8 mb-1">
                            <div className="mb-4">
                                <h6 className="fw-semibold mb-2">{t('writememo.review-title-topic-small')} :</h6>
                                <p>
                                    {shortMessage}
                                    (<a href="#">{t('writememo.review-title-seemore')}</a>)
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="gap-4 d-flex flex-wrap mt-4 mb-5">
                        <div className="d-flex flex-column me-2">
                            <h6>{t('writememo.review-title-datesave')}</h6>
                            <span className="badge bg-label-success">{moment(memo?.description?.updatedAt !== `` ? memo?.description?.updatedAt : new Date()).format("DD MMM YY HH:mm")}</span>
                        </div>
                        <div className="d-flex flex-column me-2">
                            <h6>{t('writememo.review-title-timesend')}</h6>
                            <ReactTooltip place="top" effect="solid" className="tooltip_style"></ReactTooltip>
                            <span data-tip={t('writememo.tooltip-deathtrigger-review')} className="badge bg-label-danger">{description?.deathTrigger?.name}</span>
                            {/*{memo?.description?.deathTriggerAlert}*/}
                        </div>
                        <div className="d-flex flex-column me-2">
                            <h6>{t('writememo.review-title-receiver')}</h6>

                            <ReactTooltip place="top" effect="solid" className="tooltip_style"></ReactTooltip>
                            <ul className="table_list_avatar overlay py-0 my-0">
                                {memo?.receivers && memo?.receivers?.length > 0 && memo?.receivers.map(r => (
                                    <li className="mr-1">
                                        <ImageUserProfile size={`xs`} user={r}/>
                                    </li>))}
                            </ul>
                        </div>

                        <div className="d-flex flex-column me-2">
                            <h6>{t('writememo.review-title-typememo')}</h6>
                            <MemoTypeTag name={description?.type?.key}/>
                        </div>

                        {/*<div className="d-flex flex-column me-2">
                            <h6>สถานะ</h6>
                            {description?.status?.key === `draft` &&
                                <span className="badge bg-mali-darft">{description?.status?.name}</span>}
                            {description?.status?.key === `publish` &&
                                <span className="badge bg-success">{description?.status?.name}</span>}

                        </div>*/}
                        <div className="d-flex flex-column me-2">
                            <h6>{t('writememo.review-title-condition')}</h6>
                            {description?.kyc?.key === 1 &&
                                <span className="d-flex  align-items-center"><RiCheckboxCircleFill className="color_mali margin_right_3px"/> {description?.kyc?.name}
                                </span>}
                            {description?.kyc?.key === 0 &&
                                <span className="d-flex  align-items-center"><RiCloseCircleFill className="color_mali margin_right_3px text-danger"/> {description?.kyc?.name}
                                </span>}

                        </div>
                        <div className="d-flex flex-column me-2">
                            <h6>{t('writememo.review-title-sms')}</h6>
                            {description?.receiverAlert?.key === 1 &&
                                <span className="d-flex  align-items-center"><RiCheckboxCircleFill className="color_mali margin_right_3px"/> {description?.receiverAlert?.name}
                                </span>}
                            {description?.receiverAlert?.key === 0 &&
                                <span className="d-flex  align-items-center"><RiCloseCircleFill className="color_mali margin_right_3px text-danger"/> {description?.receiverAlert?.name}
                                </span>}
                            {/*<span className="d-flex  align-items-center"><RiCheckboxCircleFill className="color_mali margin_right_3px"/> แจ้งให้ทราบ</span>*/}
                        </div>

                    </div>

                    <div className="d-flex flex-column flex-grow-1">
                        <div className="col-md-8 mb-1">
                            <div className="mb-4">
                                <p>{t('writememo.review-title-howtouse1')} <a href="#">DeathTrigger</a> {t('writememo.review-title-howtouse1')}
                                </p>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="d-flex col-12 justify-content-center">
                    <PieChart style={{}} lineWidth={60}
                              animate
                              segmentsStyle={{
                                  transition: 'stroke .1s', cursor: 'pointer'
                              }} className="chart_review_box"
                              data={description?.chart}
                    />
                </div>
                <div className="chart_review">

                    <ul className="doughnut-legend d-flex justify-content-around pt-1 ps-0 mb-2 chart_review_bar row row-sm">
                        {description?.chart?.length && description?.chart?.map(v => (
                            <li className="ct-series-0 d-flex flex-column col">
                                <h5 className="fw-bold mb-0">{v.title}</h5>
                                <div>
                                    <span className="badge badge-dot rounded-pill my-2 cursor-pointer bar_text" style={{
                                        width: `${v.percent}%`,
                                        backgroundColor: `${v.color}`
                                    }}></span></div>
                                <div className="text-muted">{(v.percent.toFixed(2)).length >= 4 ? v.percent.toFixed(1) : v.percent} %</div>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>

            <div className="d-flex col-12 justify-content-between">
                <button className="btn btn-primary btn-prev" onClick={() => handleStep('preview')}>
                    <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                </button>

                    <button className="btn btn-primary btn-next" onClick={() => ClickConfirmAlert()}>
                        <AiOutlineSave className="memo_write_icon"/> {t('writememo.btn-save-real-save')}
                    </button>

            </div>
        </>
    )
}

export default MemoOverview
