import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import imgProfile from '../../../common/assets/img/mali/profile_temp/img.jpg'
import {CgLock} from "react-icons/cg";
import {BsPerson} from "react-icons/bs";
import {RiFilePaper2Line, RiNotification4Line} from "react-icons/ri";
import {AiOutlineUpload} from "react-icons/ai";
import {BiReset} from "react-icons/bi";
import {getProfile} from "../../../features/reducer/user/userSlice";
import {NotificationManager} from "react-light-notifications";
import {setWidget} from "../../../features/reducer/memo/memoSlice";
import {updateAsyncUser, updateAsyncUserProfile} from "../../../features/reducer/user/userThunk";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {useTranslation} from "react-i18next";

const SettingsAccounts = () => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const [data, setData] = useState(profile)
    const [image, setImage] = useState()
    const {t} = useTranslation();
    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        setData(profile)
    }, [profile])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handleFileChange = (event, name = `image`, size = (1024 * 5)) => {
        let files = event.target.files;
        if (typeof files[0]?.size == 'undefined') {
            setImage(``)
            return false
        }
        if (files[0].size / 1024 > size) {
            // NotificationManager.warning({
            //     title: "แจ้งเตือน",
            //     message: "ขนาดไฟล์ใหญ่เกินกว่าที่กำหนด",
            // });
            notificationErrorCustomMessage(t('mainsetting.account-alert-file-size'), `warning`)
            return false
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setImage(e.target.result)
        }
    }

    const handleInput = (e) => {
        const {name, value} = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const handleAddressInput = (e) => {
        const {name, value} = e.target
        // const dt = {
        //     ...data,
        //     address: {
        //         ...data.address,
        //         [name]: value
        //     },
        // }
        setData({
            ...data,
            [name]: value
        })
        // console.log(dt)
        // setData(dt)
    }

    const handleUpdateProfile = () => {
        let dt = {
            ...data,
            image: image
        }
        const required = ['firstName', 'lastName', 'cardNumber'];
        let errors = false
        required.map(v => {
            if (typeof dt[v] === `undefined` || dt[v] === ``) {
                errors = true
            }
        })
        if (dt?.address === `` || dt?.province === `` || dt?.zipcode === `` || dt?.country === ``) {
            errors = true
        }
        if (errors) {
            notificationErrorCustomMessage(t('mainsetting.alert-please-input-all-star'))
            return
        }
        console.log(dt)
        dispatch(updateAsyncUserProfile(dt))
    }



    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y customer_profile_page">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">{t('settingaccount.page-nav-title-1')} /</span> {t('settingaccount.page-nav-title-2')}
                </h4>


                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-pills flex-column flex-md-row mb-3">
                            <li className="nav-item">
                                <Link to="/settings/account" className="nav-link active"><BsPerson className="icon_white icon_size_18"/> {t('settingaccount.menu-header-user-account')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/settings/security" className="nav-link"><CgLock className="icon_size_18"/> {t('settingaccount.menu-header-security')}</Link>
                            </li>
                            <li className="nav-item hide">
                                <Link to="/settings/bill" className="nav-link"><RiFilePaper2Line className="icon_size_18"/> {t('settingaccount.menu-header-billing')}</Link>
                            </li>
                            <li className="nav-item hide">
                                <Link to="/settings/notification" className="nav-link"><RiNotification4Line className="icon_size_18"/> {t('settingaccount.menu-header-noti')}</Link>
                            </li>
                        </ul>
                        <div className="card mb-4">
                            <h5 className="card-header">{t('settingaccount.text-title-account-details')}</h5>
                            <div className="card-body">
                                <div className="gap-4 d-flex align-items-start align-items-sm-center">
                                    {image ? (
                                        <>
                                            <div className={`avatar-image normal`}>
                                                <div className={`img-card-placeholder img-card-s1`}>
                                                    <div className="image" style={{backgroundImage: `url(${image})`}}>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={`avatar-image normal`}>
                                                <div className={`img-card-placeholder img-card-s1`}>
                                                    <div className="image" style={{backgroundImage: `url(${data?.imageUrl ? data?.imageUrl : imgProfile})`}}>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="button-wrapper">
                                        <label htmlFor="upload" className="btn btn-primary me-2 mb-4" tabIndex="0">
                                            <span className="d-none d-sm-block">{t('settingaccount.text-button-edit-photo')}</span>
                                            <AiOutlineUpload className=" d-sm-none d-block"/>
                                            <input
                                                onChange={e => handleFileChange(e)}
                                                type="file"
                                                id="upload"
                                                className="account-file-input"
                                                hidden
                                                accept="image/png, image/jpeg"
                                            />
                                        </label>
                                        {/*<button type="button" className="btn btn-label-secondary account-image-reset mb-4">
                                            <BiReset className=" d-sm-none d-block "/>
                                            <span className="d-none d-sm-block">รีเซ็ต</span>
                                        </button>*/}

                                        <p className="mb-0">{t('settingaccount.text-bottom-edit-photo')}</p>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-0"/>
                            <div className="card-body">
                                <form id="formAccountSettings" method="POST" onSubmit="return false">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="firstName" className="form-label">{t('settingaccount.text-title-first-name')} <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="firstName"
                                                name="firstName"
                                                onChange={e => handleInput(e)}
                                                value={data?.firstName}
                                                autoFocus
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="lastName" className="form-label">{t('settingaccount.text-title-last-name')} <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" onChange={e => handleInput(e)} name="lastName" value={data?.lastName} id="lastName"/>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="nickName" className="form-label">{t('settingaccount.text-title-nickname')}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="nickName"
                                                name="nickName"
                                                onChange={e => handleInput(e)}
                                                value={data?.nickName}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="cardNumber" className="form-label">{t('settingaccount.text-title-id-card-code')} <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cardNumber"
                                                name="cardNumber"
                                                onChange={e => handleInput(e)}
                                                value={data?.cardNumber}

                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="email" className="form-label">{t('settingaccount.text-title-email')} <span className="text-danger">*</span></label>
                                            <input
                                                disabled={true}
                                                className="form-control"
                                                type="text"
                                                id="email"
                                                name="email"
                                                onChange={e => handleInput(e)}
                                                value={data?.email}
                                                placeholder=""
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label className="form-label" htmlFor="phone">{t('settingaccount.text-title-phone-number')} <span className="text-danger">*</span></label>
                                            <div className="input-group input-group-merge">
                                                <span className="input-group-text">TH (+66)</span>
                                                <input
                                                    disabled={true}
                                                    type="text"
                                                    id="phone"
                                                    name="phone"
                                                    onChange={e => handleInput(e)}
                                                    value={data?.phone}
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="address" className="form-label">{t('settingaccount.text-title-contactable-address')} <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="address" name="address" onChange={e => handleAddressInput(e)} value={data?.address} placeholder=""/>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="province" className="form-label">{t('settingaccount.text-title-province')} <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" id="province" name="province" onChange={e => handleAddressInput(e)} value={data?.province} placeholder=""/>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="zipcode" className="form-label">{t('settingaccount.text-title-zip-code')} <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="zipcode"
                                                name="zipcode"
                                                value={data?.zipcode}
                                                placeholder=""
                                                onChange={e => handleAddressInput(e)}
                                                maxLength="6"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label" htmlFor="country">{t('settingaccount.text-title-country')} <span className="text-danger">*</span></label>
                                            <select id="country" className="select2 form-select" name="country" onChange={e => handleAddressInput(e)} value={data?.country}>
                                                <option>{t('settingaccount.text-title-choose')}</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="Canada">Canada</option>
                                                <option value="China">China</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Korea">Korea, Republic of</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Russia">Russian Federation</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Emirates">United Arab Emirates</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="United States">United States</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="zipcode" className="form-label">{t('settingaccount.text-title-your-career')} </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="work"
                                            value={data?.work}
                                            onChange={e => handleAddressInput(e)}
                                            placeholder={t('settingaccount.text-inbox-your-career')}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <button type="button" onClick={() => handleUpdateProfile()} className="btn btn-primary me-2">{t('settingaccount.text-save-button')}</button>
                                        <button type="reset" className="btn btn-label-secondary">{t('settingaccount.text-cancel-button')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <h5 className="card-header">{t('settingaccount.text-bottom-delete-account')}</h5>
                            <div className="card-body">
                                <div className="col-12 mb-0 mb-3">
                                    <div className="alert alert-warning">
                                        <h6 className="alert-heading mb-1">{t('settingaccount.text-alert-1')}</h6>
                                        <p className="mb-0">{t('settingaccount.text-alert-2')}</p>
                                    </div>
                                </div>
                                <form id="formAccountDeactivation" onSubmit="return false">
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="accountActivation"
                                            id="accountActivation"
                                        />
                                        <label className="form-check-label" htmlFor="accountActivation"
                                        >{t('settingaccount.text-choose')}</label
                                        >
                                    </div>
                                    <button type="submit" className="btn btn-danger deactivate-account">{t('settingaccount.text-delete-account-button')}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsAccounts
