import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";

import {Link} from "react-router-dom";
import {IoIosPaperPlane} from "react-icons/io";
import ReactTooltip from "react-tooltip";
import {RiDeleteBinLine, RiEditBoxLine, RiSlideshow4Line} from "react-icons/ri";
import {deleteAsyncContact, getAsyncContact} from "../../../features/reducer/contact/contactThunk";
import {useParams} from "react-router";
import {getContact} from "../../../features/reducer/contact/contactSlice";
import ReceiverTag from "../../utility/ReceiverTag";
import ImageUserProfile from "../../utility/ImageUserProfile";
import {deleteAsyncMemo, fetchAsyncReceiverMemo} from "../../../features/reducer/memo/memoThunk";
import {getReceiverMemo} from "../../../features/reducer/memo/memoSlice";
import moment from "moment";
import MemoStatusTag from "../../utility/MemoStatusTag";
import {confirmAlert} from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import MemoTypeTag from "../../utility/MemoTypeTag";
import {useTranslation} from "react-i18next";

const ReceiverView = () => {
    const dispatch = useDispatch()
    const user = useSelector(getContact)
    const receiverMemo = useSelector(getReceiverMemo)
    const {id} = useParams();
    const {t} = useTranslation();
    const [search, setSearch] = useState({
        id: id, page: 1, pageSize: 10,
    })

    useEffect(() => {
        dispatch(activeMenu({menu: `receiverMemo`}))
        dispatch(getAsyncContact(id))
        handleSearch()
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSearch = () => {
        dispatch(fetchAsyncReceiverMemo(search))
    }

    const handlePageClick = (event) => {
        const page = event.selected + 1
        const s = {
            ...search, page: page
        }
        setSearch(s)
        dispatch(fetchAsyncReceiverMemo(s))
    }

    const ClickConfirmAlert = (e, id) => {
        e.preventDefault()
        confirmAlert({
            title: t('receiver.alert-confirm'), message: t('receiver.alert-your-want-delete'), buttons: [{
                label: t('receiver.text-yes'), onClick: () => {
                    dispatch(deleteAsyncMemo({id, search, handleSearch}))
                }
            }, {
                label: t('receiver.text-no')
            }]
        });
    };
    const ConfirmDeleteContact = (e, id) => {
        e.preventDefault()
        confirmAlert({
            title: t('receiver.alert-confirm'), message: t('receiver.alert-your-want-delete-member'), buttons: [{
                label: t('receiver.text-yes'), onClick: () => {
                    dispatch(deleteAsyncContact({id}))
                }
            }, {
                label: t('receiver.text-no')
            }]
        });
    };

    return (<>
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">MALI / {t('addreceiver.navi-receiver')} /</span> {t('receiver.text-view-profile')}
            </h4>
            <div className="row gy-4">
                <div className="col-md-5 col-lg-5 col-xl-4 order-1 order-md-0">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="user-avatar-section bg_theme receiver_profile">
                                <div className="d-flex flex-column align-items-center">
                                    <ImageUserProfile size={`lg`} user={user}/>
                                    <div className="user-info text-center">
                                        <h5 className="mb-1">{user?.firstName} {user?.lastName}</h5>
                                        <div className="nickname mb-2">{user?.nickName}</div>
                                        <ReceiverTag name={user?.type}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-around py-3 my-4">
                                <Link to={`/memo?receiver=${user?.code}`} className="menu-link">
                                    <button type="button" className="btn rounded-pill btn-primary">
                                        <IoIosPaperPlane className="icon icon_button_size" data-tip="จำนวน MEMO ที่เขียนถึง"/>&nbsp; {t('receiver.text-write-memo-to')} {user?.nickName}
                                    </button>
                                </Link>
                            </div>
                            <h5 className="border-bottom pb-2 mb-4">{t('receiver.text-profile-data')}</h5>
                            <div className="info-container">
                                <ul className="list-unstyled">
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{t('receiver.text-tel-data')}:</span>
                                        <span>{user?.phone}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{t('receiver.text-email-data')}:</span>
                                        <span>{user?.email ?? '-'}</span>
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{t('receiver.text-status-data')}:</span>
                                        {!user?.verifyUserId ?
                                            <span className="badge bg-label-secondary" data-tip={t('receiver.text-confirm-human')}>{t('receiver.text-confirm-human')}</span> :
                                            <span className="badge bg-success" data-tip={t('receiver.text-unconfirm-human')}>{t('receiver.text-unconfirm-human')}</span>}
                                    </li>
                                    <li className="mb-3">
                                        <span className="fw-bold me-2">{t('receiver.text-note')}:</span>
                                        <span>{user?.note ?? '-'}</span>
                                    </li>
                                </ul>
                                <div className="d-flex justify-content-center pt-3">
                                    <Link to={`/receiver/${user?.id}/edit`} data-tip={t('receiver.text-edit-memo')}
                                          className="btn btn-primary me-3"
                                          data-bs-target="#editUser"
                                          data-bs-toggle="modal"
                                    >{t('receiver.text-edit')}</Link
                                    >
                                    <a href="javascript:;" onClick={e => ConfirmDeleteContact(e, user?.id)} className="btn btn-label-danger suspend-user">{t('receiver.text-del')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-7 col-lg-7 col-xl-8 order-0 order-md-1">
                    <div className="card mb-4">
                        <h5 className="card-header">{t('receiver.text-memo-to-for')} {user?.firstName} {user?.lastName}</h5>
                        <div className="table-responsive mb-3">
                            <table className="datatables-users table border-top">
                                <thead>
                                <tr>
                                    <th className="text-center" width={130}>{t('receiver.text-datecreate')}</th>
                                    <th>{t('receiver.text-title')}</th>
                                    <th className="text-center" width={100}>{t('receiver.text-status')}</th>
                                    <th className="text-center" width={150}>{t('receiver.text-manage')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {receiverMemo?.data?.length > 0 ? receiverMemo.data.map(item => (<tr key={item.id}>
                                    <td className="sorting_1 table_date">
                                        <ReactTooltip place="top" effect="solid" className="tooltip_style"/>

                                        <div className="DateMonth text-uppercase">{moment(item.createdAt).format("MMM YYYY")}</div>
                                        <div className="boxDate">
                                            <span className="DateDay">{moment(item.createdAt).format("DD")}</span>
                                            <span className="DateDayText text-muted pl-1 pt-1">{moment(item.createdAt).format("dddd")}</span>
                                        </div>
                                        <br/>
                                        <MemoTypeTag size={`sm`} name={item?.type}/>
                                    </td>
                                    <td>
                                        <span>{item?.title}</span>
                                        <div className="d-flex flex-column">
                                            <small className="text-muted d-flex align-items-center flex-wrap">
                                                <span className="mr-2">To</span>
                                                {item?.receiver && item?.receiver?.length > 0 && item?.receiver.map(r => (
                                                    <span className="mr-1">{r?.nickName}, </span>))}
                                            </small>
                                        </div>
                                        <ul className="table_list_avatar">
                                            {item?.receiver && item?.receiver?.length > 0 && item?.receiver.map(r => (
                                                <li className="mr-1">
                                                    <ImageUserProfile size={`sm`} user={r}/>
                                                </li>))}

                                        </ul>
                                    </td>
                                    <td>
                                        <MemoStatusTag name={item?.memoStatus}/>
                                    </td>
                                    <td>
                                        <Link to={`/memo/${item?.code}`} data-tip={t('receiver.text-edit-memo')}><RiEditBoxLine className="manage_icon icon_table_size"/></Link>
                                        <Link to={`/memo/${item?.code}/view`} data-tip={t('receiver.text-view-memo')}>
                                            <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                        <a href='#' onClick={e => ClickConfirmAlert(e, item.code)}>
                                            <RiDeleteBinLine className="manage_icon icon_table_size"/>
                                        </a>
                                    </td>
                                </tr>)) : (<tr>
                                    <td colSpan={10} className="text-center">{t('receiver.text-not-found')}</td>
                                </tr>)}


                                </tbody>


                            </table>
                        </div>
                    </div>


                    <div className="paginate justify-content-between">
                        <div className="total-items mr-3">
                            {t('receiver.text-all-amount')} {receiverMemo?.total} {t('receiver.text-all-items')}
                        </div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={t('receiver.text-next-page')}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={receiverMemo?.totalPage}
                            previousLabel={t('receiver.text-previous-page')}
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </div>
            </div>


        </div>
    </>)
}

export default ReceiverView
