import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {memoWriteState, updateMemoWidgetByIndex} from "../../../../../features/reducer/memo/memoWriterSlice";
import {useTranslation} from "react-i18next";

const Message = ({index}) => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [widget, setWidget] = useState()
    const [message, setMessage] = useState(``)
    const {t} = useTranslation();

    useEffect(() => {
        if (typeof memo.widgets[index] !== `undefined`) {
            setWidget(memo.widgets[index])
            setMessage(memo.widgets[index].content)
        }
    }, [memo.widgets])

    const handleMessageChange = (e) => {
        const {value} = e.target
        setMessage(value)

        let mWidget = {...widget, content: value}
        dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
    }

    const handleSetMessageWidget = () => {
        let mWidget = {...widget, content: message}
        dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
    }

    return (
        <>
            <div className="widget_message active">
                <div className="card-body">
                    <div className="form-floating">
                        <textarea className="form-control fix_min_height" style={{
                            border: `1px solid #d4d8dd`,
                            borderRadius: 0,
                            height: `200px`
                        }} onChange={e => handleMessageChange(e)} rows="4" value={message}></textarea>
                        <div id="floatingInputHelp" className="form-text help_padding">
                            *{t('writememo.form-hint-message-seedphase')}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Message
