import React from "react";
import JoyRide from "react-joyride";
import parse from 'html-react-parser';
import logo from "../../common/assets/img/mali/logo_small.png";
import localStorageService from "../../services/localStorageService";
import tour_reciver from "../../common/assets/img/explan/tour_receiver.jpg";
import tour_writememo from "../../common/assets/img/explan/tour_writememo.jpg";
import tour_memo from "../../common/assets/img/explan/tour_memo.jpg";
import tour_profile from "../../common/assets/img/explan/tour_profile.jpg";
import send_gift from "../../common/assets/img/explan/send_gift.png";
import writememo_banner_light from "../../common/assets/img/cover/cover_my_receiver.gif";
import {useTranslation} from "react-i18next";




const Tour = () => {

    const {t} = useTranslation();


    const TOUR_STEPS = [
        {
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content:
                parse("<center><img width='60%' src='" + logo + "' />" + "</center><br/><br/>" + t('tour.title-welcome')),
        },
        {
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content:
                parse("<center><img width='30%' src='" + send_gift + "' />" + "</center><br/><br/>" + t('tour.title-welcome2')),
        },
        {
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content:
                parse("<center><img width='90%' src='" + tour_reciver + "'/>" + "<br/><br/><br/>" + t('tour.title-step2') + "</center>"),
        },
        {
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content: parse("<center><img width='90%' src='" + tour_writememo + "'/>" + "<br/><br/><br/>" + t('tour.title-step3') + "</center>"),
        },
        {
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content: parse("<center><img width='90%' src='" + tour_memo + "'/>" + "<br/><br/><br/>" + t('tour.title-step4') + "</center>"),

        },{
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content:
                parse("<center><img height='200' src='" + writememo_banner_light + "' />" + "</center><br/><br/>" + '<b>DEATHtrigger</b> คือระบบตรวจสอบการผู้ใช้งาน ว่ายังมีการใช้งานอยู่หรือเปล่า?<br><br>โดย MALI จะมีการตรวจสอบลูกค้า โดยทาง E-mail / SMS / เว็บไซต์ MALI  โดยหากมีการเข้ามาแสดงตัว <b>CHECK-IN</b> และใช้งานอยู่เสมอ ทางระบบก็จะมองว่าเป็นผู้ใช้งานที่ปกติ'+
                'แต่หากไม่ได้มีการเข้ามา <b>CHECK-IN</b> ในระยะเวลาตามที่ตั้งค่าไว้ เช่น 1 เดือน / 3 เดือน / 6 เดือน / 12 เดือน ระบบจะทำการย้ายสถานะไปอยู่ส่วนของ <b><a target="blank" href="https://ma-li.io/index.php#mali_deathtrigger">DEATHtrigger</a></b>'),
        },
        /*{
            target: ".TourStep5",
            content: "กรณีเพื่อนคุณของได้เขียน MALI ถึงคุณ ณ วันที่เขาจากไปบันทึกต่างๆ จะถูกส่งมาถึงคุณที่เมนูนี่",
        },*/
        {
            target: "body",
            placement: 'center',
            disableBeacon: true,
            content: parse("<center><img width='90%' src='" + tour_profile + "'/>" + "<br/><br/><br/>" + t('tour.title-step5') +  "</center>"),
        }
    ];


    const handleClose = data => {
        const localStorage = localStorageService.getService()
        // console.log(data)
        if (data.action == `reset`) {
            // alert(555)
            localStorage.setTour()
        }
        // alert(555)
    }
    return (
        <>
            <JoyRide
                callback={handleClose}
                showProgress={true}
                steps={TOUR_STEPS}
                continuous={true}
                showSkipButton={true}
                styles={{
                    tooltipContainer: {
                        textAlign: "left"
                    },
                    buttonNext: {
                        backgroundColor: "#26b5b4"
                    },
                    buttonBack: {
                        marginRight: 10,
                        backgroundColor: "#26b5b4"
                    },
                    options: {
                        zIndex: 10000,
                        primaryColor: '#ff5b5c',
                        beaconSize: 50,
                        overlayColor: 'rgba(0, 0, 0, 0.80)',
                        width: 700,
                    }
                }}
                locale={{
                    last: t('tour.title-end'),
                    skip: t('tour.title-close')
                }}
            />
        </>
    );
};


export default Tour
