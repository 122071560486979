import httpClient from "../config/httpClient";


export const sendContactEmailApi = (data) => {
    return httpClient.post(`/email/contact`, data)
}
export const sendReportEmailApi = (data) => {
    return httpClient.post(`/email/contact`, {...data, type: `report`})
}


