import logo from '../../common/assets/img/mali/icon_app.png'
import {BiDotsVertical, BiDotsVerticalRounded, BiHomeCircle} from "react-icons/bi";
import {FaFeather, FaFlagCheckered, FaHandshake, FaReadme, FaScroll, FaSmile,FaRegDotCircle} from "react-icons/fa";
import {ImPhone, ImUsers} from "react-icons/im";
import {RiCloseFill,RiArrowLeftRightLine} from "react-icons/ri";
import {MdLaptopMac, MdMail} from "react-icons/md";
import {AiFillDollarCircle, AiFillQuestionCircle,AiOutlineLeft,AiOutlineMenu} from "react-icons/ai";
import {CgReadme} from "react-icons/cg";
import {GoAlert} from "react-icons/go";
import {currentActiveMenu} from "../../features/reducer/menu/menuSlice";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import {useRef, useState} from "react";

const Sidebar = ({routes, open, handleSideMenuToggle, isClickHide}) => {
    const root = document.getElementsByTagName(`html`)[0]
    const {t} = useTranslation();
    const activeMenu = useSelector(currentActiveMenu)
    // const [isClickHide, setIsClickHide] = useState(false)
    // const [isOpen, setIsOpen] = useState(false)
    const sideMenuRef = useRef(null)

    // const handleSideMenuToggle = (e) => {
    //     e.preventDefault()
    //     let c = !isOpen
    //     setIsOpen(c)
    //     root.classList.toggle('layout-menu-collapsed', c);
    //     setIsClickHide(c)
    //     if (!c) {
    //         root.classList.toggle('layout-menu-hover', false);
    //     }
    // }

    const handleHoverSidemenu = () => {
        if (isClickHide) {
            root.classList.toggle('layout-menu-hover', true);
        }
    }

    const handleHoverOutSidemenu = () => {
        if (isClickHide) {
            root.classList.toggle('layout-menu-hover', false);
        }
    }


    return (
        <>
            <aside ref={sideMenuRef} id="layout-menu" onMouseEnter={handleHoverSidemenu} onMouseLeave={handleHoverOutSidemenu} className="layout-menu menu-vertical menu bg-menu-theme" data-bg-class="bg-menu-theme">
                <div className="app-brand main">
                    <a href="/dashboard" className="TourWelcome app-brand-link">
                      <span className="app-brand-logo main">
                         <img width="100%" src={logo}/>
                      </span>
                        <span className=" app-brand-text main menu-text fw-bold ms-2">MALI</span>
                    </a>

                    <a href="#" onClick={e => handleSideMenuToggle(e)} className="layout-menu-toggle menu-link text-large ms-auto">
                        {/*<i className="bx bx-dots-vertical-rounded d-none d-xl-block fs-4 align-middle"></i>*/}
                        <RiArrowLeftRightLine className=" d-none d-xl-block fs-4 align-middle"/>
                        <RiCloseFill className=" d-block d-xl-none bx-sm align-middle"/>
                        {/*<i className="bx bx-dots-vertical-rounded d-block d-xl-none bx-sm align-middle"></i>*/}
                    </a>
                </div>

                <div className="menu-divider mt-0"></div>

                <div className="menu-inner-shadow"></div>
                <PerfectScrollbar>
                    <ul className="menu-inner py-1 ps ps--active-y">
                        <li className={"menu-item " + (activeMenu == "dashboard" ? "active" : "")}>
                            <Link to="/dashboard" className="menu-link">
                                <BiHomeCircle className="menu-icon tf-icons"/>
                                <div data-i18n="แดชบอร์ด">{t('sidemenu.dashboard')}</div>
                            </Link>
                        </li>

                        <li className="menu-header small text-uppercase"><span className="menu-header-text">MEMO</span>
                        </li>
                        <li className={"TourStep3 menu-item " + (activeMenu == "writeMemo" ? "active" : "")}>
                            <Link to="/memo" className="menu-link">
                                {/*<i className="menu-icon tf-icons fa fa-feather"> </i>*/}
                                <FaFeather className="menu-icon tf-icons"/>
                                <div data-i18n="เขียน MEMO">{t('sidemenu.write-memo')}</div>
                            </Link>
                        </li>

                        <li className={"TourStep4 menu-item " + (activeMenu == "myMemo" ? "active" : "")}>
                            <Link to="/memo/me" className="menu-link">
                                {/*<i className="menu-icon tf-icons fa fa-vault"></i>*/}
                                <FaReadme className="menu-icon tf-icons"/>
                                <div data-i18n="MEMO ของฉัน">{t('sidemenu.my-memo')}</div>
                            </Link>
                        </li>


                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text">INHERITOR</span>
                        </li>
                        <li className={"TourStep2 menu-item " + (activeMenu == "receiverMemo" ? "active" : "")}>
                            <Link to="/receiver/list" className="menu-link">
                                <ImUsers className="menu-icon tf-icons"/>
                                <div data-i18n="ผู้รับ MEMO">{t('sidemenu.receiver-memo')}</div>
                            </Link>
                        </li>


                        <li className="hide menu-header small text-uppercase">
                            <span className="menu-header-text">FOR YOU</span>
                        </li>
                        <li className={"hide TourStep5 menu-item " + (activeMenu == "mali" ? "active" : "")}>
                            <Link to="/mali" className="menu-link">
                                <MdMail className="menu-icon tf-icons"/>
                                <div data-i18n="MALI ของคุณ">MALI ของคุณ</div>
                            </Link>
                        </li>

                        {/*
                        <li className=" menu-header small text-uppercase"><span className="menu-header-text">PRICE</span>
                        </li>
                        <li className={" menu-item " + (activeMenu == "serviceCharge" ? "active" : "")}>
                            <Link to="/price" className="menu-link">
                                <AiFillDollarCircle className="menu-icon tf-icons"/>
                                <div data-i18n="ค่าบริการ">{t('sidemenu.pricing-service')}</div>
                            </Link>
                        </li>*/}


                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text">MANUAL</span>
                        </li>
                        <li className={"menu-item " + (activeMenu == "faq" ? "active" : "")}>
                            <Link to="/faq" className="menu-link">
                                <AiFillQuestionCircle className="menu-icon tf-icons"/>
                                <div data-i18n="คำถามที่พบบ่อย">{t('sidemenu.faq-menu')}</div>
                            </Link>
                        </li>
                        <li className={" menu-item " + (activeMenu == "readme" ? "active" : "")}>
                            <Link to="/readme" className="menu-link">
                                <CgReadme className="menu-icon tf-icons"/>
                                <div data-i18n="วิธีการใช้งาน">{t('sidemenu.how-to-use')}</div>
                            </Link>
                        </li>

                        <li className={"hide menu-item " + (activeMenu == "readme" ? "active" : "")}>
                            <div to="/readme" className="menu-link">
                                <CgReadme className="menu-icon tf-icons"/>
                                <div data-i18n="วิธีการใช้งาน">{t('sidemenu.procedure-menu')}</div>
                            </div>
                        </li>

                        <li className={"menu-item TourDEATHtrigger " + (activeMenu == "procedure" ? "active" : "")}>
                            <Link to="/procedure" className="menu-link">
                                <MdLaptopMac className="menu-icon tf-icons"/>
                                <div data-i18n="หลักการทำงาน">DEATHtrigger</div>
                            </Link>
                        </li>


                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text">WE ARE</span>
                        </li>
                        <li className={"menu-item " + (activeMenu == "whatMali" ? "active" : "")}>
                            <Link to="/aboutus" className="menu-link">
                                <FaSmile className="menu-icon tf-icons"/>
                                <div data-i18n="MALI คือ?">{t('sidemenu.what-mali')}</div>
                            </Link>
                        </li>

                        <li className={"menu-item " + (activeMenu == "service" ? "active" : "")}>
                            <Link to="/service" className="menu-link">
                                <FaFlagCheckered className="menu-icon tf-icons"/>
                                <div data-i18n="บริการของเรา">{t('sidemenu.our-service')}</div>
                            </Link>
                        </li>

                        <li className={"menu-item " + (activeMenu == "policy" ? "active" : "")}>
                            <Link to="/policy" className="menu-link">
                                <FaScroll className="menu-icon tf-icons"/>
                                <div data-i18n="นโยบายความเป็นส่วนตัว">{t('sidemenu.privacy-policy')}</div>
                            </Link>
                        </li>

                        <li className={"menu-item " + (activeMenu == "rule" ? "active" : "")}>
                            <Link to="/rule" className="menu-link">
                                <FaHandshake className="menu-icon tf-icons"/>
                                <div data-i18n="ข้อตกลงและเงื่อนไข">{t('sidemenu.terms-of-service')}</div>
                            </Link>
                        </li>


                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text">CONTACT US</span></li>
                        <li className={"menu-item " + (activeMenu == "contact" ? "active" : "")}>
                            <Link to="/contact" className="menu-link">
                                <ImPhone className="menu-icon tf-icons"/>
                                <div data-i18n="ติดต่อเรา">{t('sidemenu.contact-us')}</div>
                            </Link>
                        </li>

                        <li className={"menu-item " + (activeMenu == "issue" ? "active" : "")}>
                            <Link to="/report-issue" className="menu-link">
                                <GoAlert className="menu-icon tf-icons"/>
                                <div data-i18n="แจ้งปัญหา">{t('sidemenu.support-menu')}</div>
                            </Link>
                        </li>


                    </ul>
                </PerfectScrollbar>
            </aside>
        </>
    )
}

export default Sidebar
