import httpClient, {httpObjQueryString} from "../config/httpClient";


export const fetchMyContactsApi = (filter) => {
    return httpClient.get(`/contact/me?${httpObjQueryString(filter)}`)
}

export const getMyContactsApi = (id) => {
    return httpClient.get(`/contact/${id}`)
}

export const addContactApi = (data) => {
    return httpClient.post(`/contact`, data)
}

export const addContactByUsernameApi = (data) => {
    return httpClient.post(`/contact/user/${data.username}`, data)
}

export const updateContactApi = (data) => {
    return httpClient.put(`/contact/${data.code}`, data)
}

export const deleteContactApi = id => {
    return httpClient.delete(`/contact/${id}`)
}
