import axios from "axios";

import localStorageService from "../../services/localStorageService";
import {setLoading, setProgressDialog} from "../../features/reducer/utility";
import {notificationErrorMessage} from "./message";

let isRefreshing = false;
let failedQueue = [];

const localStorage = localStorageService.getService()

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

httpClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


const httpClientFormValue = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

httpClientFormValue.interceptors.request.use(
    (config) => {
        const token = localStorage.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = 'multipart/form-data';
        // config.headers["Content-Type"] = 'application/x-www-form-urlencoded';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        // console.log(error)
        if (error.response?.status === 401 && originalRequest.url === `/user/refreshToken`) {
            window.location.href = "/login";
            return Promise.reject(error);
        }

        const accessToken = localStorage.getAccessToken();
        const refreshToken = localStorage.getRefreshToken();

        const model = {
            accessToken: accessToken,
            refreshToken: refreshToken,
        };


        if (error.response?.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject});
                }).then((token) => {
                    originalRequest.headers[
                        "Authorization"
                        ] = `Bearer ${model.accessToken}`;
                    return axios(originalRequest);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
                axios.post(`${process.env.REACT_APP_API_BASE}/user/refreshToken`, model, {
                    headers: {Authorization: `Bearer ${model.accessToken}`},
                }).then(resp => {
                    const token = {
                        accessToken: resp.data.access_token,
                        refreshToken: resp.data.refresh_token,
                    }
                    localStorage.setToken(token);
                    httpClient.defaults.headers.common["Authorization"] = `Bearer ${token.accessToken}`;
                    originalRequest.headers["Authorization"] = `Bearer ${token.accessToken}`;
                    resolve(axios(originalRequest));
                }).catch((err) => {
                    console.log("refresh err:", err);

                    processQueue(err, null);

                    localStorage.forgetToken();
                    window.location.href = "/login";
                    reject({status: error.response.status, error: error});
                }).finally(() => {
                    isRefreshing = false;
                });
            });
        }

        return Promise.reject(error);
    }
);

export default httpClient;
export const httpClientForm = httpClientFormValue


export const httpObjQueryString = (data) => {
    return Object.entries(data).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
}


export const httpRequestFunc = async (baseThunk, callback) => {
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await callback
        if (response.status === 200) {
            setTimeout(() => {
                baseThunk.dispatch(setProgressDialog(false))
            }, 800)
            setTimeout(() => {
                baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
            }, 500)
            return response
        }
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        setTimeout(() => {
            baseThunk.dispatch(setProgressDialog(false))
        }, 800)

    } catch (e) {
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        setTimeout(() => {
            baseThunk.dispatch(setProgressDialog(false))
            notificationErrorMessage(e)
        }, 800)

        const data = e?.response?.data
        throw baseThunk.rejectWithValue(data?.message)
    }
}
