import React, {useEffect} from "react";
import {FaBitcoin} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {IoIosPaperPlane} from "react-icons/io";
import bg_image from "../../../common/assets/img/cover/cover_write_memo_step4.gif";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {setModal} from "../../../features/reducer/utility";
import parse from "html-react-parser";

const WelcomeBoxModal = ({guide}) => {

    const dispatch = useDispatch()
    const {t} = useTranslation();
    let navigate = useNavigate();

    const toPayment = (e) => {
        e.preventDefault()
        navigate("/settings/bill");
        dispatch(setModal(false))
    }

    return (
        <>
            <div className="card-body text-center">
                <img className="image_redeem_cover" src={bg_image}/>
                <h3>{guide}</h3>
                <div className="mb-4">{t('trialcodebox.text-text-explan-redeem')} <br/><b><a href={`#`} onClick={e => toPayment(e)}>"{t('trialcodebox.text-setting-pay')}"</a></b>  {parse(t('nonmember.text-click-this-2'))}</div>
            </div>

        </>
    )
}

export default WelcomeBoxModal
