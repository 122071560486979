/*New Memo*/

import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {addLogGuideMemoApi, addMemoApi, getMemoApi} from "../../../common/api/memoApi";
import {notificationSuccessMessage} from "../../../common/config/message";
import {setMemoStep} from "./memoWriterSlice";
import {setRedirect} from "../utility";
import {useSelector} from "react-redux";
import {getMemoGuides} from "./memoSlice";

export const getNewAsyncMemo = createAsyncThunk('memoWriter/getAsyncMemo', async (id, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, getMemoApi(id))
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const updateMemoAsync = createAsyncThunk('memoWriter/updateMemoAsync', async (dataSet, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, addMemoApi(dataSet.memo))


        // console.log("xxxx===>"+dataSet.guideCode)


        if (dataSet.guideCode !== null && typeof dataSet.guideCode !== `undefined` && dataSet.guideCode?.length > 0) {
           // const guides = useSelector(getMemoGuides)
            //let guide = guides.find(r => r.code === dataSet.guideCode)
           // if (typeof guide?.code !== `undefined`) {

                const logguide = {
                    memoCode: response.data.code,
                    guideCode: dataSet.guideCode
                }

                httpRequestFunc(baseThunk, addLogGuideMemoApi(logguide))
                //console.log(response2)

           // }
        }


        notificationSuccessMessage(`บันทึก MEMO สำเร็จ`)
        baseThunk.dispatch(setMemoStep(`receiver`))
        baseThunk.dispatch(setRedirect(`/memo/me`))
        return response.data
    } catch (e) {
        console.log(e)
    }
})

