import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import banner_cover2 from "../../../common/assets/img/cover/cover2.jpg";
import imgProfile from '../../../common/assets/img/mali/profile_temp/knot.jpg'
import {CgLock} from "react-icons/cg";
import {BsPerson} from "react-icons/bs";
import {RiFilePaper2Line, RiNotification4Line} from "react-icons/ri";
import {IoLogoWindows} from "react-icons/io";
import {MdOutlinePhoneIphone} from "react-icons/md"
import {DiAndroid} from "react-icons/di";
import {FaApple} from "react-icons/fa";
import banner_ma from "../../../common/assets/img/illustrations/boy-working-light.png";

const SettingsNotification = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
        <div className="container-xxl flex-grow-1 container-p-y customer_profile_page">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">Settings /</span> Notification
            </h4>


            <div className="row">
                <div className="col-md-12">
                    <ul className="nav nav-pills flex-column flex-md-row mb-3">
                        <li className="nav-item">
                            <Link to="/settings/account" className="nav-link"><BsPerson className="icon_size_18"/> บัญชีผู้ใช้</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/settings/security" className="nav-link"><CgLock className="icon_size_18"/> ความปลอดภัย</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/settings/bill" className="nav-link"><RiFilePaper2Line className="icon_size_18"/> การเรียกเก็บเงิน</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/settings/notification" className="nav-link active"><RiNotification4Line className="icon_white icon_size_18"/> การแจ้งเตือน</Link>
                        </li>
                    </ul>


                    <div className="card">
                        <h5 className="card-header">ตั้งค่าการแจ้งเตือน</h5>
                        <div className="card-body">
                                  <span>คุณสามารถตั้งค่าการแจ้งเตือนต่างๆ ได้ตามที่ต้องการ เพื่อไม่ให้เป็นการรบกวนการใช้งานของคุณ</span>
                            <div className="error"></div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-borderless">
                                <thead>
                                <tr>
                                    <th className="text-nowrap">ประเภท</th>
                                    <th className="text-center text-nowrap">✉️ อีเมล</th>
                                    <th className="text-center text-nowrap">🖥 บราวเซอร์</th>
                                    <th className="text-center text-nowrap">👩🏻‍💻 แอพพลิเคชั่น</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="text-nowrap">เพื่อนใหม่ที่คุณอาจรู้จัก</td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck1" checked/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck2" checked/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck3" checked/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-nowrap">การล๊อคอินเข้าสู่ระบบ</td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck4" checked/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck5" checked/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck6" checked/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-nowrap">แจ้งเปลี่ยนรหัสผ่าน</td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck7" checked/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck8" checked/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck9"/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-body">
                            <form action="javascript:void(0);">
                                <div className="row">
                                    <div className="mt-4">
                                        <button type="submit" className="btn btn-primary me-2">บันทึก</button>
                                        <button type="reset" className="btn btn-label-secondary">ยกเลิก</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </>)
}

export default SettingsNotification
