import React, {useEffect} from "react";
import {FaBitcoin} from "react-icons/fa";
import {Link} from "react-router-dom";
import {IoIosPaperPlane} from "react-icons/io";
import bg_image from "../../../common/assets/img/cover/cover_write_memo_step5.gif";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const WelcomeBoxModal = ({guide}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="card-body text-center">
                <img className="image_redeem_cover" src={bg_image}/>
                <h5>{t('welcomemodal.header-title')}</h5>
                <div className="mb-4">{t('welcomemodal.text-bottom')}</div>
            </div>

        </>
    )
}

export default WelcomeBoxModal
