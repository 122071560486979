import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {RiUserSearchFill} from "react-icons/ri";
import {fetchAsyncUsers} from "../../../features/reducer/user/userThunk";
import {getProfile, getUsers, setUser} from "../../../features/reducer/user/userSlice";
import ReceiverAddBySearch from "./ReceiverAddBySearch";
import ReceiverAddManual from "./ReceiverAddManual";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {useTranslation} from "react-i18next";

const Faq = () => {
    const dispatch = useDispatch()
    const users = useSelector(getUsers)
    const profile = useSelector(getProfile)
    const [addStep, setAddStep] = useState(0)
    const [clickSearch, setClickSearch] = useState(false)
    const [searchType, setSearchType] = useState('phone')
    /*const [search, setSearch] = useState("0906105210")*/
    const [search, setSearch] = useState("")
    const [searchResult, setSearchResult] = useState(null)
    const [countResult, setCountResult] = useState(-1)
    const {t} = useTranslation();


    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    useEffect(() => {
        dispatch(activeMenu({menu: `receiverMemo`}))
        dispatch(setUser([]))
        handleOnResetSearch()
    }, [dispatch])

    useEffect(() => {
        // console.log(profile)
    }, [profile]);


    useEffect(() => {
        if (clickSearch) {
            if (typeof users?.total != `undefined`) {
                if (users.total > 0) {
                    setSearchResult(users?.data[0])
                    setCountResult(1)
                } else {
                    setCountResult(0)
                    setSearchResult(null)
                }
            } else {
                setSearchResult(null)

            }
        }
    }, [users])

    const handleSetSearchType = (e) => {
        const {checked, value} = e.target
        setSearchType(checked ? value : "")
        setSearch("")
        handleOnResetSearch()
    }

    const handleSetInput = e => {
        const {name, value} = e.target
        let v = value
        if (name == `phone`) {
            v = v.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        }
        // console.log(name)
        // console.log(v)
        setSearch(v)
    }


    const handleOnSearch = () => {
        let sea = {[searchType]: search, ignore: `me`}
        if (search == "") {
            notificationErrorCustomMessage(t('addreceiver.alert-tel-email'))
            return
        }
        if (searchType === `phone`) {
            if (isNaN(search)) {
                notificationErrorCustomMessage(t('addreceiver.alert-number-only'))
                return
            }
            const zero = search.charAt(0)
            // alert(zero)
            if (zero !== `0`) {
                sea.phone = `0${sea.phone}`
            }
            if (search === profile?.phone) {
                notificationErrorCustomMessage(t('addreceiver.alert-number-me-only'))
                return
            }
        }
        if (searchType === `email`) {
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(search))) {
                notificationErrorCustomMessage(t('addreceiver.alert-email-wrong'))
                return
            }
            if (search === profile?.email) {
                notificationErrorCustomMessage(t('addreceiver.alert-another-email'))
                return
            }
        }
        // console.log(sea)
        dispatch(fetchAsyncUsers(sea))
        setClickSearch(true)
    }
    const handleOnResetSearch = () => {
        setSearchResult(null)
        setClickSearch(false)
        setCountResult(-1)
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI / {t('addreceiver.navi-receiver')} /</span> {t('addreceiver.page-nav-title')}
                </h4>
                {addStep == 0 && (
                    <div className="modal-dialog modal-lg modal-simple modal-edit-user">
                        <div className="modal-content p-3 p-md-5">
                            <div className="modal-body">

                                <div className="text-center mb-4">
                                    <h3>{t('addreceiver.header-text')}</h3>
                                    <p>{t('addreceiver.bottom-header-text')}</p>
                                </div>
                                <div className="row">
                                    {countResult == 0 && clickSearch && (
                                        <div className="col-12">
                                            <div className="alert alert-dark alert-dismissible" role="alert">
                                                <h6 className="alert-heading mb-1">
                                                    <RiUserSearchFill className="icon_alet_header"/> {t('addreceiver.text-search-result')} 0 {t('addreceiver.text-search-result-item')}
                                                </h6>
                                                <span>{t('addreceiver.text-not-found-data')}</span>
                                            </div>
                                        </div>
                                    )}

                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <div className="form-check form-check-inline mt-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="searchType"
                                                    onClick={e => handleSetSearchType(e)}
                                                    value="phone" checked={searchType == 'phone'}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio1">{t('addreceiver.text-phone-number-1')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="searchType"
                                                    value="email"
                                                    onClick={e => handleSetSearchType(e)}
                                                    checked={searchType == 'email'}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio2">{t('addreceiver.text-e-mail')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {searchType == 'phone' && (
                                            <>
                                                <label className="form-label" htmlFor="modalEditUserPhone">{t('addreceiver.text-phone-number-2')}</label>
                                                <div className={`input-group input-group-merge ${clickSearch === true ? `disabled` : ``}`}>
                                                    <span className={`input-group-text ${clickSearch === true ? `disabled` : ``}`}>+66</span>
                                                    <input
                                                        disabled={clickSearch === true}
                                                        value={search}
                                                        onChange={e => handleSetInput(e)}
                                                        type="text"
                                                        id="modalEditUserPhone"
                                                        name="phone"
                                                        className="form-control phone-number-mask"
                                                        placeholder={t('addreceiver.text-inbox-search-1')}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {searchType == 'email' && (
                                            <>
                                                <label className="form-label" htmlFor="modalEditUserPhone">Email</label>
                                                <input
                                                    disabled={clickSearch === true}
                                                    value={search}
                                                    type="text"

                                                    onChange={e => handleSetInput(e)}
                                                    id="modalEditUserPhone"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder={t('addreceiver.text-inbox-search-2')}
                                                />
                                            </>
                                        )}

                                    </div>
                                </div>
                                {searchResult == null ? (
                                    <>
                                        {!clickSearch && (
                                            <div className="text-center mt-4">
                                                <button type="button" onClick={e => handleOnSearch()} className="btn btn-primary me-1 me-sm-3">{t('addreceiver.text-search-button')}</button>
                                            </div>
                                        )}

                                        {clickSearch && (
                                            <ReceiverAddManual search={search} searchType={searchType} resetSearchResult={handleOnResetSearch}/>
                                        )}
                                    </>
                                ) : (
                                    <ReceiverAddBySearch resetSearchResult={handleOnResetSearch} searchResult={searchResult}/>
                                )}


                            </div>
                        </div>
                    </div>
                )}


            </div>
        </>
    )
}

export default Faq
