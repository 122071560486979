import React, {useEffect, useState} from "react";
import MemoNotifications from "./MemoNotifications";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import MemoStep from "./MemoStep";
import {
    memoWriteState,
    resetMemo,
    setLeaveMemoPage,
    setMemoWritter
} from "../../../../features/reducer/memo/memoWriterSlice";
import MemoReceiver from "./MemoReceiver";
import MemoWidget from "./MemoWidget";
import MemoSetting from "./MemoSetting";
import MemoPreview from "./MemoPreview";
import MemoOverview from "./MemoOverview";
import {useParams} from "react-router";
import {getNewAsyncMemo} from "../../../../features/reducer/memo/memoWriterThunk";
import {getProfile} from "../../../../features/reducer/user/userSlice";
import {activeMenu} from "../../../../features/reducer/menu/menuSlice";
import {rmOpenModalContent, setOpenModalContent} from "../../../../features/reducer/utility";
import {usePrompt} from "../../../../common/functions/promptBlocker";
import parse from "html-react-parser";
import logo from "../../../../common/assets/img/mali/icon_app.png";
import NonMemberBoxModal from "../../dashboard/NonMemberBoxModal";
import localStorageService from "../../../../services/localStorageService";


const initBeforeUnLoad = (showExitPrompt, count = 0) => {

    window.onbeforeunload = (event) => {
        if (showExitPrompt && count > 0) {
            //alert(!profile?.isExpired)
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = '';
            }
            return '';
        }
    };
};

const Memo = () => {
    const dispatch = useDispatch()

    const {t} = useTranslation();
    const {id, receiver} = useParams();
    const profile = useSelector(getProfile)
    const memo = useSelector(memoWriteState)
    const [showExitPrompt, setShowExitPrompt] = useState(true);
    // const [usePromptCheck, setUsePromptCheck] = useState(false);

    // if(localStorage.getExpired()) {
    usePrompt(t('writememo.save-not-save'), memo.leave);
    // }


    useEffect(() => {
        //alert(memo.receivers?.length)
       // if (typeof profile?.isExpired !== `undefined` && !profile?.isExpired) {
            //alert(1)
            initBeforeUnLoad(showExitPrompt, memo.receivers?.length);
       // }
        if (memo.receivers?.length > 0) {
            dispatch(setLeaveMemoPage(true))
        }
    }, [showExitPrompt, memo.receivers]);


    useEffect(() => {
        dispatch(activeMenu({menu: `writeMemo`}))
        dispatch(rmOpenModalContent({modal: false}))
        dispatch(resetMemo())
    }, []);


    useEffect(() => {
        if (typeof id !== `undefined`) {
            const memoId = id
            dispatch(getNewAsyncMemo(memoId))
        }
    }, [dispatch, id])


    useEffect(() => {
        if (typeof profile !== `undefined` && typeof id === `undefined`) {
            dispatch(setMemoWritter(profile))
        }
    }, [profile, id]);


    /*
    useEffect(() => {
        if (typeof profile !== `undefined` && typeof id === `undefined`) {
            dispatch(setMemoWritter(profile))

            if (profile?.isExpired) {
                dispatch(setOpenModalContent({
                    title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/><span className="font_topic_modal">' + t('writememo.topic-notice-you') + '</span> '),
                    size: `md`, //sm lg xl
                    content:
                        <NonMemberBoxModal guide="xxxxx"/>, //component or html tag
                }))
            }

        }
    }, [profile, id]);*/


    const MemoSection = () => {
        switch (memo.step) {
            case `receiver` :
                return <MemoReceiver/>
            case `write` :
                return <MemoWidget/>
            case `setting` :
                return <MemoSetting/>
            case `preview` :
                return <MemoPreview/>
            case `complete` :
                return <MemoOverview/>
        }

    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI /</span> {t('writememo.page-nav-title')}
                </h4>
                <MemoNotifications/>
                <div id="wizard-create-deal" className="bs-stepper vertical mt-2">
                    <MemoStep/>
                    <div className="bs-stepper-content">
                        <div id="wizard-create-deal-form">
                            {MemoSection()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Memo
