import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {useTranslation} from "react-i18next";
import img_personal_laptop from "../../../common/assets/img/explan/personal_laptop.jpg";
import img_girl_think from "../../../common/assets/img/explan/girl_think_good.png";
import mom_write from "../../../common/assets/img/explan/mom_write.jpg";
import {AiOutlineUserAdd, AiOutlineCheck} from "react-icons/ai";
import logo from "../../../common/assets/img/mali/icon_app.png";
import {CgLock, CgProfile} from "react-icons/cg";
import {RiCheckboxBlankLine} from "react-icons/ri";
import {FaInfo, FaPaperPlane} from "react-icons/fa";
import {BsFillPersonFill, BsBookmarkStar, BsJournalBookmark, BsPencil} from "react-icons/bs";
import imgProfileCot from "../../../common/assets/img/mali/profile_temp/cot.jpg";
import {setOpenModalContent} from "../../../features/reducer/utility";
import MemoriesGuideModal from "./MemoriesGuideModal";
import parse from "html-react-parser";
import {getMemoGuides, getMyMemoGuides} from "../../../features/reducer/memo/memoSlice";
import {getMemoGuideAsync, getMyMemoGuideAsync} from "../../../features/reducer/memo/memoThunk";
import MemoTypeTag from "../../utility/MemoTypeTag";
import {getMyContacts} from "../../../features/reducer/contact/contactSlice";
import {fetchAsyncContacts} from "../../../features/reducer/contact/contactThunk";
import ImageUserProfile from "../../utility/ImageUserProfile";
import {Link} from "react-router-dom";
import {IoIosPaperPlane} from "react-icons/io";
import WelcomeBoxModal from "./WelcomeBoxModal";
import Tour from "../Tour";
import localStorageService from "../../../services/localStorageService";


const Dashboard = () => {
    const {t, i18n} = useTranslation();


    const localStorage = localStorageService.getService()
    const dispatch = useDispatch()
    const [lang, setLang] = useState(i18n.language)
    //const guides = useSelector(getMemoGuides)
    const guides = useSelector(getMyMemoGuides)
    const myContacts = useSelector(getMyContacts)
    const [AfterLogin, FlagAfterLogin] = useState(localStorage.getWelcome())
    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 30
    })
    const [showTour, setShowTour] = useState(false)

    const handleSetTour = (open) => {
        setShowTour(open)
    }

    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language]);

    useEffect(() => {
        dispatch(activeMenu({menu: `dashboard`}))
        dispatch(getMyMemoGuideAsync())
        dispatch(fetchAsyncContacts(filter))


        /* When first login*/
        if (AfterLogin != 1) {
            dispatch(setOpenModalContent({
                title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/><span className="font_topic_modal">MALI DIGITAL TESTAMENT</span> '),
                size: `md`, //sm lg xl
                content:
                    <WelcomeBoxModal guide="xxxxx"/>, //component or html tag
            }))

            localStorage.setWelcome(1)

        }
        /* When first login*/


    }, [dispatch])


    return (
        <>

            <div className="container-xxl flex-grow-1 container-p-y">
                {showTour && <Tour/>}

                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI /</span> {t('dashboard.page-nav-title-1')}
                </h4>
                <div className="row g-4 margin-top-10-del">
                   
                      {/*<div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="card box_dashboard_add_receiver">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-center align-items-end mt-3 mt-sm-0">
                                        <img
                                            src={img_personal_laptop}
                                            className="img-fluid"
                                            alt="Image"
                                            width="160"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div className="card-body text-center text-sm-end ps-sm-0">
                                        <Link to="/receiver/add">
                                            <button
                                                data-bs-target="#addRoleModal"
                                                data-bs-toggle="modal"
                                                className="btn btn-primary add-new-role text-nowrap mb-3"
                                            ><AiOutlineUserAdd className="icon_btn_dashboard"/>
                                                {t('dashboard.receiver-add-receiver')}
                                            </button>
                                        </Link>
                                        <p className="mb-0">{t('dashboard.receiver-add-receiver-deteil')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="card box_dashboard_add_receiver">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-center align-items-end mt-3 mt-sm-0">
                                        <img
                                            src={mom_write}
                                            className="img-fluid"
                                            alt="Image"
                                            width="160"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div className="card-body text-center text-sm-end ps-sm-0">
                                        <Link to="/memo">
                                            <button
                                                data-bs-target="#addRoleModal"
                                                data-bs-toggle="modal"
                                                className="btn btn-primary add-new-role text-nowrap mb-3"
                                            ><BsPencil className="icon_btn_dashboard"/>
                                                {t('dashboard.write-memo-button')}
                                            </button>
                                        </Link>
                                        <p className="mb-0">{t('dashboard.detail-of-writememo')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="card box_dashboard_add_receiver">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-center align-items-end mt-3 mt-sm-0">
                                        <img
                                            src={img_girl_think}
                                            className="img-fluid"
                                            alt="Image"
                                            width="160"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div className="card-body text-center text-sm-end ps-sm-0">
                                        <button onClick={() => handleSetTour(true)}
                                                data-bs-target="#addRoleModal"
                                                data-bs-toggle="modal"
                                                className="btn btn-primary add-new-role text-nowrap mb-3"
                                        ><BsJournalBookmark className="icon_btn_dashboard"/>
                                            {t('dashboard.how-to-use-button')}
                                        </button>
                                        <p className="mb-0">{t('dashboard.detail-of-htu')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="card box_dashboard_add_receiver">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-center align-items-end mt-3 mt-sm-0">
                                        <img
                                            src="https://qr-official.line.me/gs/M_448rxyua_BW.png?oat_content=qr"
                                            className="img-fluid"
                                            alt="Image"
                                            width="160"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div className="card-body text-center text-sm-end ps-sm-0">
                                        <a href="https://lin.ee/0pTjkkk" target="_blank"
                                                data-bs-target="#addRoleModal"
                                                data-bs-toggle="modal"
                                                className="btn btn-primary add-new-role text-nowrap mb-3"
                                        ><BsJournalBookmark className="icon_btn_dashboard"/>
                                            ADD LINE เพื่อแจ้งเตือน
                                        </a>
                                        <p className="mb-0">เราจะคอยเตือนคุณผ่านทาง LINE ในทุกๆเดือน เพื่อให้คุณสามารถเข้ามา CHECK-IN กับ MALI อยู่สม่ำเสมอว่าคุณยังสบายดี :)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                  
                    {myContacts?.data?.length > 0 ? myContacts.data.map(item => (
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card h-100">
                                <div className="row h-100 align-items-center">
                                    <div className="col-sm-5">
                                        <div className="d-flex justify-content-center align-items-center mt-3 mt-sm-0">
                                            <ImageUserProfile size={`lg`} user={item}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="card-body text-center text-sm-start ps-sm-0">
                                            <h4 className="mb-1 text-uppercase">{item?.firstName} {item?.lastName}</h4>
                                            <h6 className="fw-normal text-uppercase">{item?.nickName}</h6>
                                            <small className="fw-normal padding-r-10 ">{t('dashboard.receiver-text-amount-total')} {item?.memoCount} {t('dashboard.receiver-text-memo')}</small>
                                            <br></br>
                                            <Link to={`/receiver/${item.code}/edit`} data-tip={t('dashboard.text-edit-receiver')}>
                                                <small> {t('dashboard.receiver-edit')}</small>
                                            </Link>
                                            <a className="padding-l-10  padding-r-10 dash">|</a>
                                            <Link to={`/memo?receiver=${item?.code}`} data-tip={t('dashboard.text-write-memo')}>
                                                <small>{t('dashboard.receiver-write-memo')} </small></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : ``}

                </div>


                <div className="col-xl-12 margin-b-t-20">
                    <div className="card">
                        <div className="card-header align-items-center">
                            <h5 className="card-title mb-0">Memories Guide</h5>
                            <p className="padding-top-5 fw-normal">{t('dashboard.memories-guide-text-detail-1')}</p>
                        </div>
                        <div className="table-responsive">
                            <table className="datatables-users table">
                                <thead>
                                <tr>
                                    <th width={100}>{t('dashboard.bar-type')}</th>
                                    <th>{t('dashboard.bar-title')}</th>
                                    <th width={200}>{t('dashboard.bar-status')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {guides?.length > 0 ? guides.map(v => (
                                    <tr>
                                        <td className="sorting_1 table_date">
                                            {/*<div className="badge rounded-pill bg-label-i-wish tags" data-tip="I-Wish">I-Wish</div>*/}
                                            <MemoTypeTag size={`sm`} name={v?.type}/>
                                        </td>
                                        <td>
                                            <span>
                                                {lang == `th` ? v?.title : v?.titleEN}
                                                &nbsp;(<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({
                                                title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),
                                                size: `md`, //sm lg xl
                                                content:
                                                    <MemoriesGuideModal guide={v}/>, //component or html tag
                                            }))} >{t('etc.text-see-more')}</a>)</span>
                                        </td>
                                        <td>
                                            {v.logguide.length > 0 ?
                                                <span className="badge bg-mali" data-tip=""><AiOutlineCheck className="icon_table_size"/> {t('etc.text-write-success')}
                                                </span>
                                                :
                                                <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> {t('dashboard.text-title-not-written')}
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={10}>...</td>
                                    </tr>
                                )}

                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-i-wish tags" data-tip="I-Wish">I-Wish</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>ลูกหนี้ของคุณมีหรือไม่ และมีใครบ้าง (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> ยังไม่ได้เขียน</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-i-wish tags" data-tip="I-Wish">I-Wish</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>เจ้าหนี้ของคุณมีหรือไม่ และมีใครบ้าง (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> ยังไม่ได้เขียน</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}


                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-memo tags" data-tip="Memo">Memo</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>งานฌาปนกิจคุณต้องการให้จัดรูปแบบไหน มีอะไรที่อยากให้ทำบ้างในงาน (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> ยังไม่ได้เขียน</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-memo tags" data-tip="Memo">Memo</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>คุณมีรหัสผ่าน / อีเมลบริการต่างๆ ที่ต้องการส่งต่อ (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> ยังไม่ได้เขียน</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-memo tags" data-tip="Memo">Memo</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>ข้อมูล Digital Content Creator ต่างๆของคุณ เช่น Google Adsenes / Photo Stock / Bloger (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> ยังไม่ได้เขียน</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-password tags" data-tip="Password">Password</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>สัตว์เลี้ยงต่างๆของคุณ ต้องการให้ใครดูแลต่อ (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-gray_none_check" data-tip=""><RiCheckboxBlankLine className="icon_table_size"/> ยังไม่ได้เขียน</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-i-wish tags" data-tip="I-Wish">I-Wish</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>ของใช้ในบ้านของ คุณต้องการให้จัดการมันยังไง (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-mali" data-tip=""><AiOutlineCheck className="icon_table_size"/> เขียนแล้ว</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-memo tags" data-tip="Memo">Memo</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>งานของคุณ มีสิ่งไหนบ้างที่คุณจำเป็นต้องบอกต่อ เพื่อไม่ให้กระทบต่อเพื่อนร่วมงานหรือองค์กรของคุณ (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-mali" data-tip=""><AiOutlineCheck className="icon_table_size"/> เขียนแล้ว</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-memo tags" data-tip="Memo">Memo</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>กิจการของคุณ มีสิ่งไหนบ้างที่ควรให้จัดการหรือประสานต่อหลังจากคุณจากไป (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-mali" data-tip=""><AiOutlineCheck className="icon_table_size"/> เขียนแล้ว</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td className="sorting_1 table_date">*/}
                                {/*        <div className="badge rounded-pill bg-label-password tags" data-tip="Password">Password</div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span>การจัดการทรัพย์สินต่างๆของคุณในวันที่จากไปแล้ว (<a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({*/}
                                {/*            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>Memories Guide'),*/}
                                {/*            size: `md`, //sm lg xl*/}
                                {/*            content:*/}
                                {/*                <MemoriesGuideModal memo_type={"iwish"} title={"สินทรัพย์ต่างๆของคุณต้องการส่งต่อให้ใครอะไรบ้าง"} detail={"Digital Assets ในรูปแบบต่างๆ ไม่ว่าจะเป็น Cryptocurrency NFT Land หรือแม้กระทั้ง Item ต่างๆที่จะเกิดขึ้นบนโลก Metaverse ในอนาคตอันใกล้"}/>, //component or html tag*/}
                                {/*        }))} data-tip="ประเภท MEMO คืออะไร?">ดูเพิ่มเติม</a>)</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge bg-mali" data-tip=""><AiOutlineCheck className="icon_table_size"/> เขียนแล้ว</span>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}


                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>


                <div className="col-md-12 col-lg-4 mb-0 hide">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title m-0 me-2">{t('dashboard.text-alert-noti-your')}</h5>
                        </div>
                        <div className="card-body">

                            <div className="row border_bottom padding-top-5">
                                <div className="d-flex py-2">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar">
                                            <img src={logo} alt="" className="w-px-40 h-auto rounded-circle"/>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-truncate d-flex align-items-center notice">
                                            <div className="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2">
                                                <FaInfo className="icon"/>
                                            </div>
                                            Notice
                                        </h6>
                                        <p className="mb-0">คุณได้ทำการเขียน MEMO ใหม่ครั้งแรก เราหวังว่าคุณจะเขียนเพิ่มอีกเรื่อยๆนะ</p>
                                        <small className="text-muted">1 ชั่วโมงที่ผ่านมา</small>
                                    </div>
                                </div>
                            </div>


                            <div className="row border_bottom padding-top-5">
                                <div className="d-flex py-2">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar">
                                            <img src={imgProfileCot} alt="" className="w-px-40 h-auto rounded-circle"/>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-truncate d-flex align-items-center memo">
                                            <div className="badge badge-center rounded-pill bg-label-primary w-px-30 h-px-30 me-2">
                                                <FaPaperPlane className="icon"/>
                                            </div>
                                            Memo
                                        </h6>
                                        <p className="mb-0">PAWARIS WONGKURSORN ได้เขียน MEMO ถึงคุณ 1 ฉบับ</p>
                                        <small className="text-muted">3 ชั่วโมงที่ผ่านมา</small>
                                    </div>
                                </div>
                            </div>

                            <div className="row border_bottom padding-top-5">
                                <div className="d-flex py-2">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar">
                                            <img src={logo} alt="" className="w-px-40 h-auto rounded-circle"/>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-truncate d-flex align-items-center security">
                                            <div className="badge badge-center rounded-pill bg-label-danger w-px-30 h-px-30 me-2">
                                                <CgLock className="icon"/>
                                            </div>
                                            Security
                                        </h6>
                                        <p className="mb-0">มีการล๊อคอินเข้าสู่ระบบจากสถานที่อื่น โปรดตรวจสอบว่าเป็นคุณหรือไม่</p>
                                        <small className="text-muted">4 ชั่วโมงที่ผ่านมา</small>
                                    </div>
                                </div>
                            </div>

                            <div className="row border_bottom padding-top-5">
                                <div className="d-flex py-2">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar">
                                            <img src={logo} alt="" className="w-px-40 h-auto rounded-circle"/>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-truncate d-flex align-items-center receiver">
                                            <div className="badge badge-center rounded-pill bg-label-success w-px-30 h-px-30 me-2">
                                                <BsFillPersonFill className="icon"/>
                                            </div>
                                            Receiver
                                        </h6>
                                        <p className="mb-0">ยินดีด้วย! มีผู้รับเจตนาของคุณได้ลงทะเบียนการใช้งานกับมะลิเรียบร้อย โปรดตรวจสอบรายละเอียดเพื่อยืนยันว่าใช่บุคคลที่คุณต้องการส่งข้อมูลถึงหรือไม่</p>
                                        <small className="text-muted">2 วันที่ผ่านมา</small>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="text-center">
                                    <div className="py-0">
                                        <br></br>
                                        ดูข้อความแจ้งเตือนทั้งหมด
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </div>


        </>
    )
}

export default Dashboard
