import React, {useState} from "react";
import Script from "react-load-script";
import {useDispatch, useSelector} from "react-redux";
import {getSelectPackages} from "../../features/reducer/subscribe/subscribeSlice";
import {addSubscriptionOmiseAsync} from "../../features/reducer/subscribe/subscribeThunk";
import {useTranslation} from "react-i18next";

let OmiseCard
const OmisePayment = () => {
    const dispatch = useDispatch();
    const myPackage = useSelector(getSelectPackages)
    const {t} = useTranslation();

    const handleLoadScript = () => {
        OmiseCard = window.OmiseCard
        OmiseCard.configure({
            publicKey: process.env.REACT_APP_OMISE_PUBLIC_KEY,
            currency: 'THB',
        });
    }

    const creditCardConfigure = () => {


        document.body.classList.add('omise-open');
        const amount = (myPackage?.packagePrice ?? 0) * 100
        OmiseCard.open({
            amount: amount,
            currency: "THB",
            defaultPaymentMethod: "credit_card",
            otherPaymentMethods: ["credit_card", "truemoney", "internet_banking", "internet_banking_bay", "internet_banking_bbl","internet_banking_ktb","internet_banking_scb", "alipay"],
            onCreateTokenSuccess: (nonce) => {
                dispatch(addSubscriptionOmiseAsync({
                    code : myPackage?.packageCode,
                    amount: amount,
                    token: nonce
                }))
                document.body.classList.remove('omise-open');
            },
            onFormClosed: () => {
                document.body.classList.remove('omise-open');
            }
        })
    }

    const handleClick = (e) => {
        e.preventDefault();
        creditCardConfigure();
    }


    return (
        <>
            <Script
                url="https://cdn.omise.co/omise.js"
                onLoad={handleLoadScript}
            />
            <button type="button" onClick={(e) => handleClick(e)} className="btn btn-primary me-2">{t('creditcard.pay-pay-from-creditcard')}</button>
        </>
    )
}

export default OmisePayment
