import {createSlice} from "@reduxjs/toolkit";

const menuSlice = createSlice({
    name: `menu`,
    initialState: {
        active: ``
    },
    reducers: {
        activeMenu: (state, {payload}) => {
            const {menu} = payload
            return {...state, active: menu}
        },
    }
})


export const currentActiveMenu = (state) => state.menu.active

export const {activeMenu} = menuSlice.actions


export default menuSlice.reducer
