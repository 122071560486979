import {createSlice} from "@reduxjs/toolkit";
import {
    deleteAsyncUser,
    fetchAsyncUsers,
    getAsyncProfile,
    getAsyncUser,
    loginAsyncUser,
    otpVerifyAsyncRequest
} from "./userThunk";
import localStorageService from "../../../services/localStorageService";

const localStorage = localStorageService.getService()
const initialState = {
    users: {
        data: [],
    },
    user: null,
    profile: null,
    tooManyLogin: false,
    isAuthentications: false,
    submited: false,
    redirectUrl: null,
    userRegister: {
        username: ``, password: ``, confirmPassword: ``,
        firstName: ``, lastName: ``, facebookUrl: ``, lineId: ``,
        phone: ``, code: ``, otp: ``, step: 1,
    },
}


const userSlice = createSlice({
    name: `user`,
    initialState: initialState,
    reducers: {
        setisAuthenticated: (state, {payload}) => {
            return {...state, isAuthentications: payload}
        },
        setRedirect: (state, {payload}) => {
            return {...state, redirectUrl: payload}
        },
        setUser: (state, {payload}) => {
            return {...state, user: payload}
        },
        removeUser: (state, {payload}) => {
            const id = payload
            let users = JSON.parse(JSON.stringify(state.users));
            users.data = users.data.filter(prop => prop.username !== id)
            return {
                ...state,
                users: users
            }
        },
        setUserRegister: (state, {payload}) => {
            //console.log(payload)
            return {...state, userRegister: payload}
        },
        setSMSCode: (state, {payload}) => {
            // console.log(payload)
            return {
                ...state, userRegister: {
                    ...state.userRegister, code: payload
                }
            }
        },
        setReisterStep: (state, {payload}) => {
            return {
                ...state, userRegister: {
                    ...state.userRegister, step: payload
                }
            }
        },
    },
    extraReducers: {

        [loginAsyncUser.pending]: (state) => {
            return {...state, submited: true}
        },
        [loginAsyncUser.rejected]: (state, {payload}) => {
            if (payload === `TOO_MANY_LOGIN`) {
                return {
                    ...state,
                    tooManyLogin: true,
                    isAuthentications: false,
                    submited: false
                }
            }

            return {...state, submited: false, isAuthentications: false}
        },
        [loginAsyncUser.fulfilled]: (state, {payload}) => {
            localStorage.setToken(payload)
            return {
                ...state,
                tooManyLogin: false,
                isAuthentications: true,
                submited: false
            }
        },
        [otpVerifyAsyncRequest.fulfilled]: (state, {payload}) => {
            localStorage.setToken(payload)
            return {
                ...state,
                tooManyLogin: false,
                isAuthentications: true,
                submited: false
            }
        },


        //USERS
        [fetchAsyncUsers.fulfilled]: (state, {payload}) => {
            return {...state, users: payload, redirectUrl: null}
        },

        [getAsyncProfile.fulfilled]: (state, {payload}) => {
            localStorage.setExpire(payload.isExpired ? 1 : 0)
            let trackRef = {}
            if (typeof payload?.trackRef !== `undefined` && payload?.trackRef !== null && payload?.trackRef.length > 0) {
                payload.trackRef.map(ref => {
                    trackRef[ref.param] = ref.value
                })
            }

            let profile = {
                ...payload, trackRef: trackRef
            }
            console.log(profile)
            return {
                ...state,
                profile: payload,
                redirectUrl: null
            }
        },

        [getAsyncUser.fulfilled]: (state, {payload}) => {
            return {...state, user: payload, redirectUrl: null}
        },

        [deleteAsyncUser.fulfilled]: (state, {payload}) => {
            const id = payload
            return {
                ...state,
                users: {
                    ...state.users,
                    data: state.users.data.filter(prop => prop.username !== id),
                },
                redirectUrl: null
            }
        },

    },
})


export const getUsers = (state) => state.users.users
export const getUser = (state) => state.users.user
export const getProfile = (state) => state.users.profile
export const tooManyLogin = (state) => state.users.tooManyLogin
export const isAuthentications = (state) => state.users.isAuthentications
export const userSubmited = (state) => state.users.submited
export const userRegisterData = (state) => state.users.userRegister
export const userState = (state) => state.users

export const {
    removeUser,
    setisAuthenticated,
    setRedirect,
    setUser,
    setUserRegister,
    setSMSCode,
    setReisterStep
} = userSlice.actions
export default userSlice.reducer
