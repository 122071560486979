import React from "react";
import {useTranslation} from "react-i18next";

const MemoTypeTag = ({name, size = `lg`,classNameuse}) => {

    const {t} = useTranslation();

    switch (name) {
        case 'memo' :
            return (
                <span className={"badge rounded-pill bg-label-friends "+classNameuse} data-tip={t('memotype.tag-memo')}>{size === `lg` ? t('memotype.tag-memo') : `Memo`}</span>
            )
            break
        case 'iwish' :
            return (
                <span className={"badge rounded-pill bg-label-info "+classNameuse} data-tip={t('memotype.tag-i-wish')}>{size === `lg` ? t('memotype.tag-i-wish') : `I-Wish`}</span>
            )
            break
        case 'account' :
            return (
                <span className={"badge rounded-pill label_business "+classNameuse}  data-tip={t('memotype.tag-password')}>{size === `lg` ? t('memotype.tag-password') : `Account`}</span>
            )
            break
        default:
            return (
                <span className={"badge bg-secondary rounded-pill "+classNameuse}  data-tip={t('memotype.tag-unknown')}>{t('memotype.tag-unknown')}</span>
            )
    }
}

export default MemoTypeTag
