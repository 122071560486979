import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import logo from "../../../common/assets/img/mali/icon_app.png";
import icon_gif_typing from  "../../../common/assets/img/icon_gif/icon_gif_typing.gif";
import {useTranslation} from "react-i18next";
import img_girl_think from "../../../common/assets/img/explan/girl_think_good.png";
import {VscActivateBreakpoints} from "react-icons/vsc";
import {BiNotepad} from "react-icons/bi";
import parse from "html-react-parser";

const Procedure = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: `procedure`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <><div className="container-xxl container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">{t('procedure.page-nav-title-1')} /</span> {t('procedure.page-nav-title-2')}
            </h4>
            <div className="row invoice-edit">
                <div className="col-12 col-lg-12 mb-4 mb-lg-0">
                    <div className="card border h-100 margin-bottom-10">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center me-3">
                                <img src={logo} alt="Avatar" className="rounded-circle me-3" width="54"/>
                                <div className="card-title mb-0">
                                    <h5 className="mb-0">DEATHtrigger</h5>
                                    <small className="text-muted">HOW TO WORK</small>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">

                            <div className="col-md-12 col-lg-12">
                                <div className=" box_dashboard_add_receiver">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <div className="d-flex justify-content-end align-items-end mt-3 mt-sm-0">
                                                <img
                                                    src={icon_gif_typing}
                                                    className="img-fluid"
                                                    alt="Image"
                                                    width="400"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <div className="card-body text-center text-sm-start ps-sm-0">

                                                <p className="p_read"><h4 className="text-indent-5"> {parse(t('procedure.text-detail-1'))}</h4>
                                                    <br></br>
                                                    <VscActivateBreakpoints></VscActivateBreakpoints> {parse(t('procedure.text-detail-2'))}
                                                    <br></br>
                                                    <br></br>
                                                    <VscActivateBreakpoints></VscActivateBreakpoints> {parse(t('procedure.text-detail-3'))}
                                                    <br></br>
                                                    <br></br>
                                                    <VscActivateBreakpoints></VscActivateBreakpoints> {parse(t('procedure.text-detail-4'))}
                                                    <br></br>
                                                    <br></br>
                                                    <BiNotepad></BiNotepad> {parse(t('procedure.text-detail-6'))}

                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    {parse(t('procedure.text-detail-7'))}
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <b>ศึกษาวิธีการทำงานของ <a target="blank" href="https://ma-li.io/index.php#mali_deathtrigger"> DEATHtrigger ได้ ที่นี้</a></b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default Procedure
