import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {Col, Row, Tab} from "react-bootstrap";
import footerLogo from '../../../common/assets/img/mali/logo_small.png'
import {getSelectPackages, subscribeState} from "../../../features/reducer/subscribe/subscribeSlice";
import {Link,useNavigate} from "react-router-dom";
import {useOmise} from "use-omise";
import {setProgressDialog} from "../../../features/reducer/utility";
import OmisePayment from "../../utility/OmisePayment";
import {useTranslation} from "react-i18next";


const PaymentCard = () => {
    const dispatch = useDispatch()
    const subscribe = useSelector(subscribeState)
    const myPackage = useSelector(getSelectPackages)
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {loading, createTokenPromise} = useOmise({
        publicKey: process.env.REACT_APP_OMISE_PUBLIC_KEY,
    });
    const [openCreditCardForm, setOpenCreditCardForm] = useState(false)

    useEffect(() => {
        dispatch(activeMenu({menu: `price`}))
    }, [dispatch])

    useEffect(() => {
        if (loading) {
            dispatch(setProgressDialog(true))
        } else {
            dispatch(setProgressDialog(false))
        }
    }, [dispatch, loading])

    useEffect(() => {
        if (typeof myPackage === `undefined` || myPackage === null) {
            navigate(`/price`)
        }
    }, [myPackage])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handlePayment = async () => {

        try {
            const token = await createTokenPromise('card', {
                name: "Example card holder",
                number: "4242424242424242",
                security_code: "111",
                expiration_month: "06",
                expiration_year: "2025"
            });
            console.log(token)
            // Send the token to your server to create a charge
        } catch (error) {
            // Handle error on the UI
        }
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="faq-header d-flex flex-column justify-content-center align-items-center margin_bottom_25px">
                    <h3 className="zindex-1 text-center">{t('payment.credit-topic')}</h3>

                    <p className="zindex-1 text-center text-body px-3 mb-0">
                        {t('payment.credit-topic-detail')}
                    </p>
                </div>


                <Tab.Container id="left-tabs-example" defaultActiveKey="payment">
                    <Row>
                        <Col sm={9}>
                            <div className="bg_white  mb-4">
                                <h5 className="card-header">{t('payment.credit-topic-detail-2')}
                                </h5>

                                <hr className="my-0"/>
                                <div className="card-body">
                                    <form id="formAccountSettings" method="POST" onSubmit="return false">
                                        <div className="row">

                                            <div className="row invoice-preview">
                                                <div className="col-12 col-md-12 col-xl-12 mb-4 mb-md-0">
                                                    <div className=" invoice-preview-card">
                                                        <div className="card-body">
                                                            <div
                                                                className="d-flex flex-column flex-sm-row flex-md-column flex-xl-row justify-content-between p-0 p-sm-3"
                                                            >
                                                                <div className="mb-4 mb-xl-0">
                                                                    <div className="svg-illustration gap-2 d-flex mb-3">

                                                                        <span className="app-brand-text h3 fw-bold mb-0">
                                                                            <img height="50" src={footerLogo}/></span>
                                                                    </div>

                                                                    <p className="mb-1">
                                                                        <b>MALI DIGITAL TESTAMENT CO.,LTD</b></p>
                                                                    <p className="mb-1">{t('payment.credit-address-1')}</p>
                                                                    <p className="mb-1">{t('payment.credit-address-2')}</p>
                                                                    <p className="mb-0">+(66) 84 790 9941</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <hr className="my-0"/>
                                                        <div className="table-responsive">
                                                            <table className="table border-top m-0">
                                                                <thead>
                                                                <tr>
                                                                    <th>{t('payment.credit-item')}</th>
                                                                    <th>{t('payment.credit-item-detail')}</th>
                                                                    <th>{t('payment.credit-item-price')}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>MALI MEMBERSHIP</td>
                                                                    <td>{t('payment.credit-bill-detail')}</td>
                                                                    <td className="text-end align-top py-5 px-1">659.00 ฿</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-end  py-5 px-2">
                                                                        <p className="mb-2">
                                                                            <span className="fw-semibold me-1"></span>
                                                                            <span></span>
                                                                        </p>
                                                                    </td>
                                                                    <td className="text-end py-5 px-2">
                                                                        <p className="mb-2">{t('payment.credit-bill-price')}:</p>
                                                                        <p className="mb-2">{t('payment.credit-bill-discount')}:</p>
                                                                        <p className="mb-0">{t('payment.credit-bill-amount')}:</p>
                                                                    </td>
                                                                    <td className="text-end py-5 px-2">
                                                                        <p className="fw-semibold mb-2">659.00 ฿</p>
                                                                        <p className="fw-semibold mb-2">0 ฿</p>
                                                                        <p className="fw-semibold mb-0">659.00 ฿</p>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <span className="fw-semibold">Note:</span>
                                                                    <span
                                                                    > {t('payment.credit-detail-hint')}</span
                                                                    >
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 padding-top-20 padding-bottom-5">
                                                                {/*<button type="button" onClick={() => setOpenCreditCardForm(true)} className="btn btn-primary me-2">ชำระค่าบริการ</button>*/}
                                                                <OmisePayment/>
                                                                <Link to="/price">
                                                                    <button type="button" className="btn btn-label-secondary">{t('payment.credit-btn-cancel')}</button>
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}

export default PaymentCard
