import forgotImg from "../../common/assets/img/explan/stand_typing.png";
import {Link, useNavigate} from "react-router-dom";
import {BiChevronLeft} from "react-icons/bi";
import imgLogoSmall from "../../common/assets/img/mali/logo_small.png";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setisAuthenticated, setReisterStep, setSMSCode, userRegisterData} from "../../features/reducer/user/userSlice";
import {AutoTabProvider} from "react-auto-tab";
import {notificationErrorCustomMessage} from "../../common/config/message";
import {otpAsyncRequest, otpVerifyAsyncRequest} from "../../features/reducer/user/userThunk";
import {redirect, setRedirect} from "../../features/reducer/utility";
import {useTranslation} from "react-i18next";

const Otp = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const data = useSelector(userRegisterData)
    const isRedirect = useSelector(redirect)
    const {t} = useTranslation();
    const [count, setCount] = useState(60)
    const [otp, setOtp] = useState({
        num1: ``,
        num2: ``,
        num3: ``,
        num4: ``,
        num5: ``,
        num6: ``,
    })

    useEffect(() => {
        if (typeof data?.username === `undefined` || data?.username === `` || data?.phone === `` || data?.code === ``) {
            navigate(`/register`)
        }
    }, [data]);


    useEffect(() => {
        if (count === 0) {
            dispatch(setReisterStep(3))
            dispatch(setSMSCode(``))
            navigate(`/register`)
            return
        }
        if (!count) return;
        const intervalId = setInterval(() => {
            let left = count - 1
            setCount(left)
        }, 1000);

        return () => clearInterval(intervalId);

    }, [count])

    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            dispatch(setisAuthenticated(true))
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [isRedirect])


    const handleOtp = (e, pos) => {
        const {value, name} = e.target
        setOtp({
            ...otp,
            [name]: value,
        })
        if (pos == 6) {
            const o = {...otp, num6: value}
            handleSubmit(o)
        }
    }
    const handleSubmit = (otpNumber = null) => {
        let otpCode = "";
        for (let i = 1; i <= 6; i++) {
            if (otpNumber !== null) {
                otpCode += otpNumber[`num${i}`] ?? ''
            } else {
                otpCode += otp[`num${i}`] ?? ''
            }
        }
        // console.log(data)
        // console.log(otpCode)
        if (otpCode.length !== 6) {
            notificationErrorCustomMessage(t('otppage.text-noti-otp-input'))
            return
        }
        const dt = {
            ...data,
            otp: otpCode
        }
        // console.log(dt)
        dispatch(otpVerifyAsyncRequest(dt))
    }

    const handleResend = e => {
        e.preventDefault()
        dispatch(otpAsyncRequest({...data, callback: setCount}))
    }

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
                        <div className="flex-row text-center mx-auto">
                            <img src={forgotImg} alt="Auth Cover Bg color" width="400" className="img-fluid authentication-cover-img"/>
                            <div className="mx-auto">
                                <h3>{t('register.header-title-left')}</h3>
                                <p>
                                    {t('register.bottom-title-left')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="authentication-bg d-flex col-12 col-lg-5 col-xl-4 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="/" className="app-brand-link gap-2 mb-2">
                                    <img width="40%" src={imgLogoSmall}/>
                                </a>
                            </div>
                            <h4 className="mb-2">{t('register.header-title-right')} 💬</h4>
                            <p className="text-start mb-4">
                                {t('register.text-bottom-4')}
                                <span className="d-block fw-bold mt-2">******{data?.phone?.substring(6, 10)}
                                    <small className="text-muted mb-3 d-block">{t('register.text-detail-ref')} {data?.code ?? '-'} {t('register.text-otp-expire-in')} {count} {t('register.text-second')}</small></span>
                            </p>
                            <p className="fw-semibold mb-0">{t('register.text-detail')}</p>
                            <form id="twoStepsForm" action="index.html" method="POST" className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                <div className="mb-3 fv-plugins-icon-container">

                                    <div className="auth-input-wrapper numeral-mask-wrapper d-flex justify-content-sm-between align-items-center">
                                        <AutoTabProvider className="d-flex align-items-center">
                                            <input type="text" className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1" name="num1" tabbable={true} onChange={e => handleOtp(e, 1)} maxLength={1} autoFocus=""/>
                                            <input type="text" className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1" name="num2" tabbable={true} onChange={e => handleOtp(e, 2)} maxLength={1}/>
                                            <input type="text" className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1" name="num3" tabbable={true} onChange={e => handleOtp(e, 3)} maxLength={1}/>
                                            <input type="text" className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1" name="num4" tabbable={true} onChange={e => handleOtp(e, 4)} maxLength={1}/>
                                            <input type="text" className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1" name="num5" tabbable={true} onChange={e => handleOtp(e, 5)} maxLength={1}/>
                                            <input type="text" className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1" name="num6" tabbable={true} onChange={e => handleOtp(e, 6)} maxLength={1}/>
                                        </AutoTabProvider>
                                    </div>

                                    <input type="hidden" name="otp"/>
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                                <button type="button" className="btn btn-primary d-grid w-100 mb-3" onClick={() => handleSubmit()}>{t('register.text-confirm-button')}</button>
                                <div className="text-center">
                                    {t('register.text-did-otp')}
                                    <a href="#" onClick={e => handleResend(e)}> {t('register.text-resend')} </a>
                                </div>
                                <div></div>
                                <input type="hidden"/>
                            </form>
                            <div className="text-center">
                                <Link to="/login" className="d-flex justify-content-center align-items-center">
ั
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Otp
