import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import writememo_banner_light from "../../../common/assets/img/cover/cover_my_memo.gif";
import {Link} from "react-router-dom";
import {IoIosPaperPlane} from "react-icons/io";
import ReactTooltip from "react-tooltip";
import {RiDeleteBinLine, RiEditBoxLine, RiSlideshow4Line} from "react-icons/ri";
import {deleteAsyncMemo, fetchAsyncMyMemo} from "../../../features/reducer/memo/memoThunk";
import {memoState} from "../../../features/reducer/memo/memoSlice";
import moment from "moment";
import MemoTypeTag from "../../utility/MemoTypeTag";
import ImageUserProfile from "../../utility/ImageUserProfile";
import MemoStatusTag from "../../utility/MemoStatusTag";
import ReactPaginate from "react-paginate";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";

const MyMemo = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const mState = useSelector(memoState)
    const [search, setSearch] = useState({
        page: 1,
        pageSize: 10,
    })

    useEffect(() => {
        dispatch(activeMenu({menu: `myMemo`}))
        dispatch(fetchAsyncMyMemo(search))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handlePageClick = (event) => {
        const page = event.selected + 1
        const s = {
            ...search,
            page: page
        }
        setSearch(s)
        dispatch(fetchAsyncMyMemo(s))
    }

    const ClickConfirmAlert = (e, id) => {
        e.preventDefault()
        confirmAlert({
            title: 'ยืนยัน', message: 'คุณต้องการลบ MEMO ใช่หรือไม่?', buttons: [{
                label: 'ใช่', onClick: () => {
                    dispatch(deleteAsyncMemo({id, search}))
                    // navigate("/memo/view")
                }
            }, {
                label: 'ไม่'
            }]
        });
    };

    return (<>
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">MALI /</span> My Memo
            </h4>
            <div className="card">
                <div className="card-header border-bottom">
                    <div className="col-12">
                        <div className="img-fluid rounded-2 w-100">
                            <center>

                                <br></br>
                                <br></br>
                                <h4 className="fw-bold">{t('mymemo.title-topic-list-receiver')}</h4>
                                <img className="image_gif_cover" src={writememo_banner_light}/>
                            </center>
                        </div>
                    </div>
                    <div className="option_button margin_bottom_25px_del">
                        <Link to="/memo" className="menu-link">
                            <button type="button" className="btn btn-primary">
                                <IoIosPaperPlane className="icon" data-tip="จำนวน MEMO ที่เขียนถึง"/>&nbsp; {t('mymemo.write-memo-button')}
                            </button>
                        </Link>
                    </div>
                </div>


                <div className="card-datatable table-responsive">
                    <table className="datatables-users table border-top">
                        <thead>
                        <tr>
                            <th width={250} className="text-center">{t('mymemo.bar-title-date')}</th>
                            <th>Title</th>
                            <th className="text-center" width={150}>{t('mymemo.bar-title-status')}</th>
                            <th width={150}>{t('mymemo.bar-title-manage')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mState?.myMemoList?.data?.length ? mState?.myMemoList?.data.map(item => (
                            <tr key={item.id}>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>

                                    <div className="DateMonth">{moment(item.updatedAt).format(`MM YYYY`)}</div>
                                    <div className="boxDate">
                                        <span className="DateDay">{moment(item.updatedAt).format(`DD`)}</span>
                                        <span className="DateDayText text-muted">{moment(item.updatedAt).format(`dddd`)}</span>
                                    </div>
                                    <br/>
                                    <MemoTypeTag name={item?.type}/>
                                </td>
                                <td>
                                    <span>{item?.title}</span>
                                    <div className="d-flex flex-column">
                                        <small className="text-muted d-flex align-items-center flex-wrap">
                                            <span className="mr-2">To</span>
                                            {item?.memoReceiver && item?.memoReceiver?.length > 0 && item?.memoReceiver.map(contact => (
                                                <span className="mr-1">{contact?.contact?.nickName}, </span>
                                            ))}
                                        </small>
                                    </div>
                                    <ul className="table_list_avatar">
                                        {item?.memoReceiver && item?.memoReceiver?.length > 0 && item?.memoReceiver.map(contact => (
                                            <li className="mr-1">
                                                <ImageUserProfile size={`sm`} user={contact?.contact}/>
                                            </li>
                                        ))}

                                    </ul>
                                </td>
                                <td>
                                    <MemoStatusTag name={item?.memoStatus}/>
                                </td>
                                <td>
                                    <Link to={`/memo/${item?.code}`} data-tip="แก้ไข MEMO"><RiEditBoxLine className="manage_icon icon_table_size"/></Link>
                                    <Link to={`/memo/${item?.code}/view`} data-tip="ดูข้อมูล Memo">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <a href='#' onClick={e => ClickConfirmAlert(e, item.code)}>
                                        <RiDeleteBinLine className="manage_icon icon_table_size"/>
                                    </a>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td className="text-center" colSpan={10}>{t('mymemo.status-of-bar')}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div className="paginate justify-content-between">
            <div className="total-items mr-3">
                ทั้งหมด {mState?.myMemoList?.total} รายการ
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel="หน้าถัดไป"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={mState?.myMemoList?.totalPage}
                previousLabel="หน้าที่แล้ว"
                renderOnZeroPageCount={null}
            />
        </div>
    </>)
}

export default MyMemo
