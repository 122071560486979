import {useParams} from 'react-router';
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncContact} from "../../../features/reducer/contact/contactThunk";
import ReceiverAddByManual from "./ReceiverAddManual";
import {getContact} from "../../../features/reducer/contact/contactSlice";
import {setRedirect} from "../../../features/reducer/utility";
import imgProfile from "../../../common/assets/img/mali/profile_temp/img.jpg";
import {AiOutlineUpload} from "react-icons/ai";
import {BiReset} from "react-icons/bi";
import {useTranslation} from "react-i18next";

const ReceiverEdit = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const user = useSelector(getContact)
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(getAsyncContact(id))
    }, [id])


    const handleReset = () => {
        dispatch(setRedirect(`/receiver/list`))
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI / {t('addreceiver.navi-receiver')} /</span> {t('addreceiver.navi-edit-receiver')}
                </h4>
                <div className="modal-dialog modal-lg modal-simple modal-edit-user">
                    <div className="modal-content p-3 p-md-5">
                        <div className="modal-body">
                            <div className="text-center mb-4">
                                <h3>{t('addreceiver.navi-edit-receiver')}</h3>
                                <p>{t('addreceiver.text-edit-receiver-detail')}</p>
                            </div>

                            <ReceiverAddByManual resetSearchResult={handleReset} gUser={user}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReceiverEdit
