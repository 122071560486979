const localStorageService = (() => {
    let service

    function getService() {
        if (!service) {
            service = this
            return service
        }

        return service
    }

    function setToken(token) {
        localStorage.setItem(`access_token`, token.accessToken)
        localStorage.setItem(`refresh_token`, token.refreshToken)
        localStorage.setItem(`authentication`, "true")
    }

    function setExpire(status) {
        localStorage.setItem(`expired`, status)
    }

    function setMe(data) {
        localStorage.setItem(`me`, JSON.stringify(data))
    }

    function setLanguage(lang) {
        localStorage.setItem(`lang`, JSON.stringify(lang))
    }

    function setTour() {
        localStorage.setItem(`tour`, 1)
    }

    function setWelcome(val) {
        localStorage.setItem(`welcome`, val)
    }

    function getAccessToken() {
        return localStorage.getItem(`access_token`)
    }

    function getRefreshToken() {
        return localStorage.getItem(`refresh_token`)
    }

    function getTour() {
        return localStorage.getItem(`tour`)
    }

    function getWelcome() {
        return localStorage.getItem(`welcome`)
    }

    function getExpired() {
        return localStorage.getItem(`expired`)
    }


    function isAuthentication() {
        return localStorage.getItem(`authentication`) === "true"
    }

    function getMe() {
        const me = localStorage.getItem(`me`)
        if (typeof me != `undefined`)
            return JSON.parse(me)

        return null
    }

    function getLanguage() {
        const lang = localStorage.getItem(`i18nextLng`)
        if (lang === `` || lang === null)
            return `th`

        return lang
    }

    function forgetToken() {
        localStorage.removeItem(`access_token`)
        localStorage.removeItem(`refresh_token`)
        localStorage.removeItem(`me`)
        localStorage.removeItem(`authentication`)
    }

    return {
        getMe: getMe,
        setMe: setMe,
        getService: getService,
        setToken: setToken,
        setExpire: setExpire,
        getAccessToken: getAccessToken,
        getRefreshToken: getRefreshToken,
        setLanguage: setLanguage,
        getLanguage: getLanguage,
        forgetToken: forgetToken,
        isAuthentication: isAuthentication,
        setTour: setTour,
        getTour: getTour,
        setWelcome: setWelcome,
        getWelcome: getWelcome,
        getExpired: getExpired,
    }


})()

export default localStorageService
