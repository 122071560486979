import {NotificationManager} from "react-light-notifications";
import {Store} from 'react-notifications-component';
import {APP_DEBUG} from "./app";

const message = {
    TOKEN_MISSING: "เข้าใช้งานไม่ถูกต้อง",
    UNAUTHORIZED: "ไม่มีสิทธิ์เข้าใช้งาน",
    INTERNAL_ERROR: "เกิดข้อผิดพลาดบางอย่าง",
    BAD_REQUEST: "กรุณากรอกข้อมูลให้ครบ",
    UNPROCESSED: "ไม่สามารถประมวลผลข้อมูลได้",
    INVALID_REFRESH_TOKEN: "Refresh token ไม่ถูกต้อง",

    SIGN_TOKEN_ERROR: "เข้ารหัสผิดพลาด",
    SIGN_TOKEN_EMPTY: "SIGN_TOKEN_EMPTY",
    INVALID_ROLE: "INVALID_ROLE",
    TOO_MANY_LOGIN: "เข้าสู่ระบบผิดพลาดหลายครั้ง",
    LOGIN_FAILS: "อีเมลหรือรหัสผ่านของคุณไม่ถูกต้อง",
    PERMISSION_DENIED: "PERMISSION_DENIED",
    USER_NOT_FOUND: "ไม่พบผู้ใช้งาน",
    USER_EXIST: "ชื่อเข้าใช้งานหรือ อีเมล์ มีในระบบแล้ว",
    PWD_IS_SHORT: "รหัสผ่าน 8 หลักขึ้นไป",
    PWD_NOT_MATCH: "รหัสผ่านไม่ตรงกัน",
    PWD_INCORRECT: "รหัสผ่านไม่ถูกต้อง",
    KEY_NOT_FOUND: "ลิ้งสำหรับเปลี่ยนรหัสผ่านหมดอายุแล้ว",

    CONTACT_EXIST: "มีรายชื่อผู้รับในรายการแล้ว",
    PHONE_EXIST: "หมายเลขโทรศัพทถูกใช้งานแล้ว",
    OTP_DELAY: "กรุณารอ 30 วินาทีเพื่อขอ OTP ใหม่อีกครั้ง",

    REDEEM_NOT_FOUND: "รหัส Code ไม่ถูกต้อง",
    REDEEM_DELAY: "กรุณารอ 10 วินาทีเพื่อกดรหัสใหม่อีกครั้ง",

    MEMO_RECEIVER_NOT_FOUND: "กรุณาเลือกรายชื่อผู้รับ",
    USER_EXPIRED: "สถานะสมาชิกของคุณหมดอายุการใช้งานแล้ว",
}


export const notificationErrorMessage = (err) => {
    const data = err?.response?.data
    if (typeof data != `undefined` && data != `` && data != null) {
        let msg = message[data.message]
        if (typeof msg == `undefined`)
            msg = `ไม่สามารถทำรายการได้ ลองใหม่อีกครั้ง`

        // NotificationManager.error({
        //     title: "แจ้งเตือน",
        //     message: msg,
        // });
        notificationErrorCustomMessage(msg, `info`)
    } else {
        // NotificationManager.error({
        //     title: "แจ้งเตือน",
        //     message: "เกิดข้อผิดพลาดบางอย่าง",
        // });
        notificationErrorCustomMessage(`เกิดข้อผิดพลาดบางอย่าง`, `warning`)
    }

    if (process.env.REACT_APP_DEBUG) {
        console.log(err)
    }
}

export const notificationSuccessMessage = (message) => {
    // NotificationManager.success({
    //     title: "แจ้งเตือน",
    //     message: message,
    // });
    notificationErrorCustomMessage(message, `success`)
}

export const notificationErrorCustomMessage = (message, type = `error`) => {

    let st = type
    if (st == `error`) {
        st = `danger`
    }
    Store.addNotification({
        title: "แจ้งเตือน!",
        message: message,
        type: st,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceInRight"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            // onScreen: true
        }
    });

    // switch (type) {
    //     case `info` :
    //         NotificationManager.info({
    //             title: "แจ้งเตือน",
    //             message: message,
    //         });
    //         break
    //     case `warning(` :
    //         NotificationManager.warning({
    //             title: "แจ้งเตือน",
    //             message: message,
    //         });
    //         break
    //     default:
    //         NotificationManager.error({
    //             title: "แจ้งเตือน",
    //             message: message,
    //         });
    // }

}
