import {useDispatch, useSelector} from "react-redux";
import {memoWriteState, updateMemoWidgetByIndex} from "../../../../../features/reducer/memo/memoWriterSlice";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const Account = ({index}) => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [widget, setWidget] = useState()
    const [data, setData] = useState({})
    const {t} = useTranslation();

    useEffect(() => {
        if (typeof memo.widgets[index] !== `undefined`) {
            const w = memo.widgets[index]
            setWidget(memo.widgets[index])
            setData({
                title: w?.title ?? '',
                url: w?.url ?? '',
                username: w?.username ?? '',
                password: w?.password ?? '',
            })
        }
    }, [memo.widgets[index]])

    const handleInput = (e) => {
        const {name, value} = e.target
        setData({...data, [name]: value})

        let mWidget = {...widget, [name]: value}
        dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
    }

    const handleUpDateWigetData = (e) => {
        const {name, value} = e.target
        let mWidget = {...widget, [name]: value}
        dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
    }

    return (
        <>
            <div className={"widget_password active"}>
                <div className="card-body">
                    <div className="form-floating">

                        <div className="boxgroup">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                name="title"
                                value={data?.title ?? ''}
                                onChange={e => handleInput(e)}
                                // onBlur={e => handleUpDateWigetData(e)}
                                aria-describedby="floatingInput1"
                            />

                        </div>
                        <div className="boxgroup">
                            <input
                                type="text"
                                className="form-control"
                                name="url"
                                value={data?.url ?? ''}
                                onChange={e => handleInput(e)}
                                // onBlur={e => handleUpDateWigetData(e)}
                                placeholder="URL"
                                aria-describedby="floatingInput2"
                            />

                        </div>

                        <div className="boxgroup">
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={data?.username ?? ''}
                                onChange={e => handleInput(e)}
                                // onBlur={e => handleUpDateWigetData(e)}
                                placeholder="Username / Email"
                                aria-describedby="floatingInput3"
                            />

                        </div>

                        <div className="boxgroup">
                            <input
                                type="text"
                                className="form-control"
                                name="password"
                                value={data?.password ?? ''}
                                onChange={e => handleInput(e)}
                                // onBlur={e => handleUpDateWigetData(e)}
                                placeholder="Password"
                                aria-describedby="floatingInput4"
                            />
                        </div>

                        <div id="floatingInputHelp" className="form-text">
                            *{t('writememo.form-hint-title')}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Account
