import React, {useEffect, useState} from "react";
import {RiTeamFill} from "react-icons/ri";
import {FaFeather} from "react-icons/fa";
import {FiCheck, FiEye, FiSettings} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {memoWriteState, setLeaveMemoPage, setMemoStep} from "../../../../features/reducer/memo/memoWriterSlice";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";
import {AiOutlineSave} from "react-icons/ai";
import {confirmAlert} from "react-confirm-alert";
import {updateMemoAsync} from "../../../../features/reducer/memo/memoWriterThunk";
import {notificationErrorCustomMessage} from "../../../../common/config/message";
import {getProfile} from "../../../../features/reducer/user/userSlice";

const initMenuState = [
    {
        key: `receiver`,
        no: 1,
        title: ('writememo.step-receiver'),
        subTitle: ('writememo.step-receiver-sub'),
        icon: <RiTeamFill/>
    }, {
        key: `setting`,
        no: 2,
        title: ('writememo.step-setting-memo'),
        subTitle: ('writememo.step-setting-memo-sub'),
        icon: <FiSettings/>
    }, {
        key: `write`,
        no: 3,
        title: ('writememo.step-write-memo'),
        subTitle: ('writememo.step-write-memo-sub'),
        icon: <FaFeather/>
    }, {
        key: `preview`,
        no: 4,
        title: ('writememo.step-preview-memo'),
        subTitle: ('writememo.step-preview-memo-sub'),
        icon: <FiEye/>
    }, {
        key: `complete`,
        no: 5,
        title: ('writememo.step-save-memo'),
        subTitle: ('writememo.step-save-memo-sub'),
        icon: <FiCheck/>
    },
]

const MemoStep = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const memo = useSelector(memoWriteState)
    const [menu, setMenu] = useState(initMenuState)
    const {hash} = useLocation();
    const {id} = useParams();
    const profile = useSelector(getProfile)

    const handleStep = key => {
        // dispatch(setMemoStep(key))
    }


    function handleSaveDraft() {

        let receivers = [...memo.receivers]
        if (receivers.length < 1) {
            return notificationErrorCustomMessage(t('writememo.receiver-alert-choose-receiver'), `info`)
        }

        if (memo?.widgets?.length < 1) {
            notificationErrorCustomMessage(t('writememo.step-alert-choose-widget'))
            return
        }
        let title = memo?.description?.title
        if (title === ``) {
            title = `untitle`
        }
        confirmAlert({
            title: t('writememo.step-alert-save-draft-confirm'), message: t('writememo.step-alert-save-draft-msg'), buttons: [{
                label: t('writememo.step-alert-save-draft-msg-yes'), onClick: () => {
                    dispatch(setLeaveMemoPage(false))
                    const dataSet = {
                        memo: {
                            ...memo,
                            description: {...memo.description, memoStatus: `draft`, title: title}
                        }
                    }
                    dispatch(updateMemoAsync(dataSet))
                }
            }, {label: t('writememo.step-alert-save-draft-msg-no')}
            ]
        });
    }

    return (
        <>
            <div className="bs-stepper-header">
                {menu.map((item, index) => (
                    <div key={item.key}>
                        <div className={"step " + (memo.step == item.key ? "active" : "")}>
                            <button type="button" className="step-trigger" onClick={() => handleStep(item.key)}>
                                <span className="bs-stepper-circle">
                                    {item.icon}
                                </span>
                                <span className="bs-stepper-label">
                                    <span className="bs-stepper-title">{item.no}. {t(item.title)}</span>
                                    <span className="bs-stepper-subtitle">{t(item.subTitle)}</span>
                                </span>
                            </button>
                        </div>
                        {index < menu.length - 1 && <div className="line"></div>}
                    </div>
                ))}



                    <div className="d-flex col-12 justify-content-center button_writememo_save_margin">
                        <button className="btn btn-danger btn-next" onClick={() => handleSaveDraft()}>
                            <AiOutlineSave className="memo_write_icon"/> {t('writememo.btn-save-draft')}
                        </button>
                    </div>



            </div>
        </>
    )
}

export default MemoStep
