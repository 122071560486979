import {MdOutlineFileUpload} from "react-icons/md";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {memoWriteState, updateMemoWidgetByIndex} from "../../../../../features/reducer/memo/memoWriterSlice";
import {notificationErrorCustomMessage} from "../../../../../common/config/message";
import {useTranslation} from "react-i18next";

const Media = ({index, typeName = `วีดีโอ`, extentions = ``, size = ``}) => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [widget, setWidget] = useState()
    const {t} = useTranslation();

    useEffect(() => {
        if (typeof memo.widgets[index] !== `undefined`) {
            setWidget(memo.widgets[index])
        }
    }, [memo.widgets])

    const getFileAllowInput = () => {
        switch (widget?.majorType) {
            case `image`:
                return `image/*`
            case `video`:
                return `video/mp4,video/x-m4v,video/*`
            case `audio`:
                return `audio/*`
        }
    }

    const handleImageChange = (event) => {
        let mWidget = {...widget}
        let files = event.target.files;
        if (typeof files[0]?.size == 'undefined') {
            mWidget = {...mWidget, fileName: ``, content: ``, media: null}
            dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
            return false
        }
        if (files[0].size / 1024 > 1024 * size) {
            notificationErrorCustomMessage(t('writememo.form-alert-file-size'), `info`)
            return false
        }
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            mWidget = {...mWidget, fileName: files[0].name, content: e.target.result, media: files[0]}
            dispatch(updateMemoWidgetByIndex({widget: {...mWidget}, index: index}))
        }
    }

    return (
        <>
            <div className="widget_image active">
                <div className="card-body">
                    <div className="form-floating">
                        <div className="box_image_upload box-upload align-items-center text-center">
                            {widget?.fileName == `` ? (
                                <>
                                    <span className="box_icon" data-tip={t('writememo.form-file-upload')}><MdOutlineFileUpload className="icon"/></span>
                                    <div>เลือกอัพโหลด {typeName}</div>
                                    <input type="file" accept={getFileAllowInput()} onChange={e => handleImageChange(e)}/>
                                </>
                            ) : (
                                <div className="image-preview">
                                    {widget?.majorType == `image` && (
                                        <img src={widget?.content} className="w-50" alt=""/>
                                    )}
                                    {widget?.majorType == `video` && (
                                        <video controls>
                                            <source type="video/mp4" src={widget?.content}/>
                                        </video>
                                    )}
                                    {widget?.majorType == `audio` && (
                                        <audio controls>
                                            <source src={widget?.content}/>
                                        </audio>
                                    )}
                                    <div className="mt-2 text-center">
                                        {widget?.fileName}
                                    </div>
                                </div>
                            )}

                        </div>
                        <div id="floatingInputHelp" className="form-text">
                            {t('writememo.form-hint-file-maximum')} {size} MB.
                        </div>
                        <div id="floatingInputHelp" className="form-text">
                            {t('writememo.form-hint-file-ext')} {extentions}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media
