import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import banner_cover2 from "../../../common/assets/img/cover/cover2.jpg";
import imgProfile from '../../../common/assets/img/mali/profile_temp/knot.jpg'
import {RiEditLine} from "react-icons/ri";
import {getProfile} from "../../../features/reducer/user/userSlice";
import ImageUserProfile from "../../utility/ImageUserProfile";
import {useTranslation} from "react-i18next"

const CustomerProfile = () => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y customer_profile_page">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">{t('customerprofile.page-nav-title-1')} /</span> {t('customerprofile.page-nav-title-2')}
                </h4>

                <div className="row">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="user-profile-header-banner">
                                <img src={banner_cover2} alt="Cover" className="rounded-top"/>
                            </div>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4 border_bottom_solid margin_bottom10p">
                                <div className="mt-n2 flex-shrink-0 mx-auto mx-sm-0">
                                    <div className="d-block h-auto ms-0 ms-sm-4 avatar-image-border">
                                        <ImageUserProfile size={`xl`} user={profile}/>
                                    </div>
                                    {/*<img*/}
                                    {/*    src={imgProfile}*/}
                                    {/*    alt="user image"*/}
                                    {/*    className="rounded-circle user-profile-img d-block h-auto ms-0 ms-sm-4 "*/}
                                    {/*/>*/}
                                </div>
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div
                                        className="gap-4 d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-center align-items-sm-start align-items-md-end mx-4"
                                    >
                                        <div className="user-profile-info">
                                            <h4 className="text-uppercase">{profile?.firstName} {profile?.lastName}</h4>
                                            <h5 className="text-uppercase">{profile?.nickName}</h5>
                                        </div>
                                        <Link to="/settings/account" className="menu-link">
                                            <button type="button" className="btn btn-primary  w-100">
                                                <RiEditLine className="icon" data-tip="แก้ไข MEMO"/>&nbsp; {t('customerprofile.text-edit-profile-button')}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className=" h-100 margin-bottom-10 ">
                                <div className="card-body">
                                    <div className=" card-action">
                                        <div className="card-header align-items-center">
                                            <h5 className="card-action-title mb-0">{t('customerprofile.header-title-left')}</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 col-xl-7">
                                                    <dl className="row mb-0">
                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-name')}</dt>
                                                        <dd className="col-sm-8">{profile?.firstName} {profile?.lastName}</dd>

                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-nickname')}</dt>
                                                        <dd className="col-sm-8">{profile?.nickName ? profile?.nickName : t('etc.text-unknow')}</dd>

                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-id-card-number')}</dt>
                                                        <dd className="col-sm-8">{profile?.cardNumber ? profile?.cardNumber : t('etc.text-unknow')}</dd>


                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-address')}</dt>
                                                        <dd className="col-sm-8">
                                                            {profile?.address ? profile?.address : t('etc.text-unknow')}
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="col-12 col-xl-5">
                                                    <dl className="row mb-0">
                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-phone-number')}</dt>
                                                        <dd className="col-sm-8">+66 {profile?.phone ? profile?.phone : t('etc.text-unknow')}</dd>

                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-email')}</dt>
                                                        <dd className="col-sm-8">{profile?.email ? profile?.email : t('etc.text-unknow')}</dd>

                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-facebook-profile')}</dt>
                                                        <dd className="col-sm-8">{profile?.facebookUrl ? profile?.facebookUrl : t('etc.text-unknow')}</dd>

                                                        <dt className="col-sm-4 mb-3">{t('customerprofile.text-title-line-id')}</dt>
                                                        <dd className="col-sm-8">{profile?.lineId ? profile?.lineId : t('etc.text-unknow')}</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body border-top">
                                        <div className="d-flex flex-column flex-grow-1">
                                            <div className="col-md-12 mb-1">
                                                <div className="mb-4">
                                                    <h6 className="fw-semibold mb-2">{t('customerprofile.text-title-about-you')}</h6>
                                                    <p>{profile?.note ? profile?.note : t('etc.text-unknow')}</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default CustomerProfile
