import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProfile, isAuthentications, setisAuthenticated} from "../../features/reducer/user/userSlice";
import localStorageService from "../../services/localStorageService";
import {Outlet, useNavigate} from "react-router-dom";
import Sidebar from "./Sidebar";
import {getAsyncProfile} from "../../features/reducer/user/userThunk";
import Navbar from "./Navbar";

import '../../common/assets/css/main.css'
// import '../../common/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css'
import footerLogo from '../../common/assets/img/mali/logo_small.png'
import {myModal, popupProgress, progressDialog, redirect, setRedirect} from "../../features/reducer/utility";
import {Box, CircularProgress, LinearProgress, Typography} from "@mui/material";
import MyModal from "../utility/MyModal";
import Tour from "./Tour";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Admin() {
    let navigate = useNavigate();
    const localStorage = localStorageService.getService()
    const dispatch = useDispatch()
    const isauthenticated = useSelector(isAuthentications)
    const showProgressPopup = useSelector(popupProgress)
    const myModalApp = useSelector(myModal)
    const profile = useSelector(getProfile)
    const progressBar = useSelector(progressDialog)
    const {t} = useTranslation();

    const [logined, setLogined] = useState(localStorage.isAuthentication())
    const [tourView, setTourView] = useState(localStorage.getTour())
    const [open, setOpen] = useState(true)
    const isRedirect = useSelector(redirect)

    useEffect(() => {
        if (!isauthenticated)
            dispatch(setisAuthenticated(logined))

        if (profile == null)
            dispatch(getAsyncProfile())

        // console.log(localStorage.getTour())
        setTourView(localStorage.getTour() * 1)

    }, [dispatch])

    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [dispatch, isRedirect])


    useEffect(() => {
        if (!logined)
            navigate('/login')

        if (window.innerWidth < 992)
            setOpen(false)

    }, []);

    const handleOpenSideMenu = () => {

    }

    const root = document.getElementsByTagName(`html`)[0]
    const [isClickHide, setIsClickHide] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const handleSideMenuToggle = (e) => {
        e.preventDefault()
        let c = !isOpen
        setIsOpen(c)
        root.classList.toggle('layout-menu-collapsed', c);
        setIsClickHide(c)
        if (!c) {
            root.classList.toggle('layout-menu-hover', false);
        }
    }

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {
        setIsOpen(false)
        setIsClickHide(false)
        root.classList.toggle('layout-menu-collapsed', false);
        root.classList.toggle('layout-menu-hover', false);

    }, [url]);


    return (
        <>


            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    {tourView !== 1 && <Tour/>}

                    <Sidebar handleSideMenuToggle={handleSideMenuToggle} isClickHide={isClickHide}/>
                    <div className={`layout-page ${open ? `sidemenu-active` : ``}`}>
                        <Navbar handleSideMenuToggle={handleSideMenuToggle} isClickHide={isClickHide}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <Outlet/>
                            </div>
                            <footer className="content-footer footer bg-footer-theme">
                                <div className="container-fluid d-flex flex-wrap justify-content-center py-2 flex-md-row flex-column">
                                    <div className="d-flex align-items-center justify-content-center ">
                                        ©2022
                                        , DIGITAL TESTAMENT PROVIDER BY
                                        <a href="https://ma-li.io" target="_blank">
                                            <img className="logo_footer" height="18" src={footerLogo}/></a>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>

            </div>


            <div className={`progressBarPopup ${showProgressPopup?.show && `active`}`}>
                <div className="inner">
                    <div className="progressIcon">
                        <CircularProgress color="inherit"/>
                    </div>
                    <div>{showProgressPopup?.text ?? t('global.text-wait-pregress')}</div>
                </div>
            </div>

            <MyModal title={myModalApp?.title} size={myModalApp?.size}>{myModalApp?.content}</MyModal>

            {progressBar && (
                <div className="progress-loading">
                    <div className="progress-inner">
                        <Spinner animation="border" variant="primary"/>
                        <div className="ml-2">{t('global.text-wait-pregress')}</div>
                    </div>
                </div>
            )}

        </>
    );
}

export default Admin;
