import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {notificationSuccessMessage} from "../../../common/config/message";
import {sendContactEmailApi, sendReportEmailApi} from "../../../common/api/emailApi";
import {resetContact} from "./emailSlice";

export const sendContactAsyncEmail = createAsyncThunk(
    'email/sendContactAsyncEmail',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, sendContactEmailApi(data))
            if (response.status === 200) {
                notificationSuccessMessage(`ข้อมูลของคุณถูกส่งไปยังทีมงานแล้ว`)
                baseThunk.dispatch(resetContact())
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const sendReportAsyncEmail = createAsyncThunk(
    'email/sendReportAsyncEmail',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, sendReportEmailApi(data))
            if (response.status === 200) {
                notificationSuccessMessage(`ข้อมูลของคุณถูกส่งไปยังทีมงานแล้ว`)
                baseThunk.dispatch(resetContact())
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)
