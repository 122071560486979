import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import logo from '../../../common/assets/img/mali/icon_app.png'
import {RiCheckboxCircleFill, RiDeleteBin6Line, RiEditLine, RiSlideshow4Line} from "react-icons/ri";
import {CgLock} from "react-icons/cg";

const NotificationViews = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="container-xxl container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">User Profile / Notification /</span> View
                </h4>

                <div className="row invoice-edit">
                    <div className="col-12 col-lg-9 mb-4 mb-lg-0">
                        <div className="card border h-100 margin-bottom-10">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center me-3">
                                    <td className="noti_type_icon">
                                        <div className="text-truncate d-flex align-items-center security">
                                            <div className="notitype_logo badge badge-center rounded-pill bg-label-danger me-2">
                                                <CgLock className="icon"/>
                                            </div>
                                        </div>
                                    </td>
                                    <div className="card-title mb-0">
                                        <h5 className="mb-0">SECURITY</h5>
                                        <small className="text-muted">การแจ้งเตือนด้านความปลอดภัย</small>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex flex-column flex-grow-1">
                                    <div className="col-md-8 mb-1">
                                        <div className="mb-4">
                                            <h6 className="fw-semibold mb-2">แจ้งเตือนจาก :</h6>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={logo} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <div className="col-md-8 mb-1">
                                        <div className="mb-4">
                                            <h6 className="fw-semibold mb-2">เรื่อง :</h6>
                                            <p>มีการล๊อคอินเข้าสู่ระบบจากสถานที่อื่น โปรดตรวจสอบว่าเป็นคุณหรือไม่?</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-grow-1">
                                    <div className="col-md-8 mb-1">
                                        <div className="mb-4">
                                            <h6 className="fw-semibold mb-2">รายละเอียด :</h6>
                                            <p>เมื่อ 10, July 2021 20:07 มีการเข้าใช้งาน Chrome ผ่าน Windows HP Spectre 360 เลข IP 202.12.31.111 จาก	Switzerland โปรดตรวจสอบพฤติกรรมการใช้งานว่าใช่คุณหรือไม่? คุณสามารถจัดการการเข้าถึง Device ต่างๆ ได้ที่ (<Link to="/settings/security">คลิก</Link>)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="invoice-actions col-12 col-lg-3">
                        <div className="card mb-4">
                            <div className="card-body">
                                <Link to="/memo/edit" className="menu-link">
                                    <button type="button" className="btn btn-label-danger suspend-user  w-100">
                                        <RiDeleteBin6Line className="icon" data-tip="แก้ไข MEMO"/>&nbsp; ลบรายการแจ้งเตือนนี้
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default NotificationViews
