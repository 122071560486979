import imgProfile from '../../common/assets/img/mali/profile_temp/knot.jpg'
import imgProfileCot from "../../common/assets/img/mali/profile_temp/cot.jpg";
import {
    BiBell,
    BiCog,
    BiCreditCard, BiDotsVerticalRounded,
    BiEnvelopeOpen,
    BiMenu,
    BiPowerOff,
    BiSearchAlt,
    BiUser,
    BiX
} from "react-icons/bi";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import FlagIcon from "./utility/FlagIcon";
import localStorageService from "../../services/localStorageService";
import {DropdownButton, Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {IoIosClose} from "react-icons/io";
import {FaInfo, FaPaperPlane} from "react-icons/fa";
import logo from "../../common/assets/img/mali/icon_app.png";
import {CgLock} from "react-icons/cg";
import {BsFillPersonFill} from "react-icons/bs";
import {AiOutlineNotification,AiOutlineCreditCard,AiOutlineMenu} from "react-icons/ai";
import {useSelector} from "react-redux";
import {getProfile} from "../../features/reducer/user/userSlice";
import ImageUserBlank from "../utility/ImageUserBlank";

const Navbar = ({handleSideMenuToggle, isClickHide}) => {
    // const localStorage = localStorageService.getService()
    const {i18n,t} = useTranslation()
    const [openMenu, setOpenMenu] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [account, setAccount] = useState(false)
    const profile = useSelector(getProfile)
    const [lang, setLang] = useState(i18n.language)
    const menuRef = useRef(null);
    const menuBtnRef = useRef(null);

    const handleOpenMenu = () => {
        setOpenMenu(openMenu ? false : true)
    }

    const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target) && !menuBtnRef.current.contains(e.target)) {
            setOpenMenu(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);
    useEffect(() => {
        setAccount(profile)
    }, [profile]);

    useEffect(() => {
        if (typeof i18n.language != `undefined`) {
            setLang(i18n.language)
        }
    }, [i18n.language]);


    const changeLanguageHandle = (e, lang) => {
        // alert(i18n.language)
        // alert(lang)
        e.preventDefault()
        // // let lang = i18n.language
        // if (lang == `th`) {
        //     lang = `en`
        // } else {
        //     lang = `th`
        // }
        setLang(lang)
        i18n.changeLanguage(lang)

    }

    return (
        <>
            <div className="alert alert-solid-primary alert-dismissible " role="alert">
               <center>
                   <AiOutlineNotification className=" me-2 icon_size_18"/>
                   {t('nav.title-nav-bar')}
               </center>
            </div>
                <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">


                    <div className="container-fluid">
                        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                            <a className="nav-item nav-link px-0 me-xl-4" onClick={e => handleSideMenuToggle(e)} href="#">
                                {/*<i className="bx bx-menu bx-sm"></i>*/}
                                <AiOutlineMenu className="fs-4 align-middle"/>
                            </a>
                        </div>

                        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                            <div className="navbar-nav align-items-center">
                                {/* <div className="nav-item navbar-search-wrapper mb-0">
                                    <a className="nav-item nav-link search-toggler px-0" onClick={() => setOpenSearch(true)} href="#">
                                        {/*<i className="bx bx-search-alt bx-sm"></i>*/}
                                {/*  <BiSearchAlt className="bx-sm"/>
                                        <span className="d-none d-md-inline-block text-muted">ค้นหา (Ctrl+/)</span>
                                    </a>
                                </div>*/}
                            </div>
                            <ul className="navbar-nav flex-row align-items-center ms-auto">
                                {/*<li className="nav-item dropdown-language dropdown me-2 me-xl-0">*/}
                                {/*    <a className="nav-link dropdown-toggle hide-arrow" onClick={e => changeLanguageHandle(e)} href="#" data-bs-toggle="dropdown">*/}
                                {/*        {lang == `th` &&*/}
                                {/*            <FlagIcon className="flag-icon-squared rounded-circle me-1 fs-3" code="us" size="3x"/>}*/}
                                {/*        {lang == `en` &&*/}
                                {/*            <FlagIcon className="flag-icon-squared rounded-circle me-1 fs-3" code="th" size="3x"/>}*/}
                                {/*    </a>*/}
                                {/*    /!*<ul className="dropdown-menu dropdown-menu-end">*!/*/}
                                {/*    /!*    <li>*!/*/}
                                {/*    /!*        <a className="dropdown-item selected" href="#" data-language="th">*!/*/}
                                {/*    /!*            <FlagIcon code="us" size="3x"/>*!/*/}
                                {/*    /!*            <span className="align-middle">Thailand</span>*!/*/}
                                {/*    /!*        </a>*!/*/}
                                {/*    /!*    </li>*!/*/}
                                {/*    /!*    <li>*!/*/}
                                {/*    /!*        <a className="dropdown-item" href="#" data-language="en">*!/*/}
                                {/*    /!*            <i className="flag-icon flag-icon-us flag-icon-squared fs-4 rounded-circle me-1"></i>*!/*/}
                                {/*    /!*            <span className="align-middle">English</span>*!/*/}
                                {/*    /!*        </a>*!/*/}
                                {/*    /!*    </li>*!/*/}
                                {/*    /!*</ul>*!/*/}
                                {/*</li>*/}
                                <li className="nav-item dropdown-language navbar-dropdown dropdown me-3 me-xl-0 language">
                                    <Dropdown align="end">
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                            {(lang == `en` || lang == `en-GB` || lang == `en-US`) &&
                                                <FlagIcon className="flag-icon-squared rounded-circle me-1 fs-3" code="us" size="3x"/>}
                                            {lang == `th` &&
                                                <FlagIcon className="flag-icon-squared rounded-circle me-1 fs-3" code="th" size="3x"/>}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1" onClick={e => changeLanguageHandle(e, 'en')} active={lang == `en` || lang == `en-GB` || lang == `en-US`}>
                                                <FlagIcon className="flag-icon-squared rounded-circle me-1 fs-3" code="us" size="3x"/> English
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" onClick={e => changeLanguageHandle(e, 'th')} active={lang == `th`}>
                                                <FlagIcon className="flag-icon-squared rounded-circle me-1 fs-3" code="th" size="3x"/> ไทย
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1 noti_box hide">
                                    <Dropdown align="end">
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                            <BiBell className="bx-sm"/>
                                            <span className="badge rounded-pill badge-notifications bg-danger">5</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link to="/notification/list">
                                                <Dropdown.Item href="#/action-1" className="border-bottom">
                                                    <div className="dropdown-header d-flex align-items-center py-0">
                                                        <h5 className="text-body me-auto mb-0">การแจ้งเตือน</h5>
                                                        <a href="#" className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Mark all as read" aria-label="Mark all as read">
                                                            {/*<i className="bx fs-4 bx-envelope-open"></i>*/}
                                                            <BiEnvelopeOpen className="fs-4"/>
                                                        </a>
                                                    </div>
                                                </Dropdown.Item>
                                            </Link>
                                            <Link to="/notification/view">
                                                <Dropdown.Item href="#/action-2" className="border_bottom">
                                                    <div className="d-flex py-2">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={logo} alt="" className="w-px-40 h-auto rounded-circle"/>
                                                                {/*<div className={`avatar-image normal`}>*/}
                                                                {/*    <div className={`img-card-placeholder img-card-s1`}>*/}
                                                                {/*        <div className="image" style={{backgroundImage: `url(${account?.imageUrl})`}}></div>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="text-truncate d-flex align-items-center notice">
                                                                <div className="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2">
                                                                    <FaInfo className="icon"/>
                                                                </div>
                                                                Notice
                                                            </h6>
                                                            <p className="mb-0">คุณได้ทำการเขียน MEMO ใหม่ครั้งแรก เราหวังว่าคุณจะเขียนเพิ่มอีกเรื่อยๆนะ</p>
                                                            <small className="text-muted">1 ชั่วโมงที่ผ่านมา</small>
                                                        </div>
                                                        <div className="dropdown-notifications-actions flex-shrink-0">
                                                            <a href="#" className="dropdown-notifications-archive"><IoIosClose className="icon_noti"/></a>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Link>
                                            <Link to="/notification/view">
                                                <Dropdown.Item href="#/action-2" className="border_bottom">
                                                    <div className="d-flex py-2">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={imgProfileCot} alt="" className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="text-truncate d-flex align-items-center memo">
                                                                <div className="badge badge-center rounded-pill bg-label-primary w-px-30 h-px-30 me-2">
                                                                    <FaPaperPlane className="icon"/>
                                                                </div>
                                                                Memo
                                                            </h6>
                                                            <p className="mb-0">PAWARIS WONGKURSORN ได้เขียน MEMO ถึงคุณ 1 ฉบับ</p>
                                                            <small className="text-muted">3 ชั่วโมงที่ผ่านมา</small>
                                                        </div>
                                                        <div className="dropdown-notifications-actions flex-shrink-0">
                                                            <a href="#" className="dropdown-notifications-archive"><IoIosClose className="icon_noti"/></a>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Link>
                                            <Link to="/notification/view">
                                                <Dropdown.Item href="#/action-2" className="border_bottom">
                                                    <div className="d-flex py-2">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={logo} alt="" className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="text-truncate d-flex align-items-center security">
                                                                <div className="badge badge-center rounded-pill bg-label-danger w-px-30 h-px-30 me-2">
                                                                    <CgLock className="icon"/>
                                                                </div>
                                                                Security
                                                            </h6>
                                                            <p className="mb-0">มีการล๊อคอินเข้าสู่ระบบจากสถานที่อื่น โปรดตรวจสอบว่าเป็นคุณหรือไม่</p>
                                                            <small className="text-muted">4 ชั่วโมงที่ผ่านมา</small>
                                                        </div>
                                                        <div className="dropdown-notifications-actions flex-shrink-0">
                                                            <a href="#" className="dropdown-notifications-archive"><IoIosClose className="icon_noti"/></a>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Link>
                                            <Link to="/notification/view">
                                                <Dropdown.Item href="#/action-2" className="border_bottom">
                                                    <div className="d-flex py-2">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={logo} alt="" className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="text-truncate d-flex align-items-center receiver">
                                                                <div className="badge badge-center rounded-pill bg-label-success w-px-30 h-px-30 me-2">
                                                                    <BsFillPersonFill className="icon"/>
                                                                </div>
                                                                Receiver
                                                            </h6>
                                                            <p className="mb-0">ยินดีด้วย! มีผู้รับเจตนาของคุณได้ลงทะเบียนการใช้งานกับมะลิเรียบร้อย โปรดตรวจสอบรายละเอียดเพื่อยืนยันว่าใช่บุคคลที่คุณต้องการส่งข้อมูลถึงหรือไม่</p>
                                                            <small className="text-muted">2 วันที่ผ่านมา</small>
                                                        </div>
                                                        <div className="dropdown-notifications-actions flex-shrink-0">
                                                            <a href="#" className="dropdown-notifications-archive"><IoIosClose className="icon_noti"/></a>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Link>


                                            <Link to="/notification/list">
                                                <Dropdown.Item href="#/action-2" className="border-top text-center">
                                                    <div className="py-0">
                                                        ดูข้อความแจ้งเตือนทั้งหมด
                                                    </div>
                                                </Dropdown.Item>
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3">
                                    <Dropdown align="end">
                                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                            {/*<div className="avatar avatar-online">*/}
                                            {/*    <img src={imgProfile} alt="" className="rounded-circle"/>*/}
                                            {/*</div>*/}
                                            <div className={`TourStep1 avatar-image`}>
                                                <div className="img-card-placeholder img-card-s1 img-circle">
                                                    <ImageUserBlank user={account}></ImageUserBlank>
                                                    {/*<div className="image" style={{backgroundImage: `url(${account?.imageUrl})`}}></div>*/}
                                                </div>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="javascript:void(0);" className="border-bottom">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className={`avatar-image`}>
                                                            <div className="img-card-placeholder img-card-s1 img-circle">
                                                                <ImageUserBlank user={account}></ImageUserBlank>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <span className="fw-semibold d-block lh-1 text-uppercase">{account?.firstName} {account?.lastName}</span>
                                                        <small className="text-uppercase">{account?.nickName ?  account?.nickName : t('nav.menu-text-bottom-name')}</small>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>

                                            <Link to="/customer/profile">
                                                <Dropdown.Item href="/customer/">
                                                    <BiUser className={"me-2"}/>
                                                    <span className="align-middle">{t('nav.menu-text-personal-information')}</span>
                                                </Dropdown.Item>
                                            </Link>
                                            <Link to="/settings/account">
                                                <Dropdown.Item href="/settings/">
                                                    <BiCog className={"me-2"}/>
                                                    <span className="align-middle">{t('nav.menu-text-settings')}</span>
                                                </Dropdown.Item>
                                            </Link>

                                            {/* <Link to="/settings/bill">
                                                <Dropdown.Item href="/settings/">
                                                    <AiOutlineCreditCard className={"me-2"}/>
                                                    <span className="align-middle">{t('nav.menu-text-membership')}</span>
                                                </Dropdown.Item>
                                            </Link>
                                            */}
                                            {/*<Dropdown.Item href="#/action-2">
                                                <span className="d-flex align-items-center align-middle">
                                                    <BiCreditCard className={"me-2 flex-shrink-0"}/>
                                                    <span className="flex-grow-1 align-middle">Subscription</span>
                                                    <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                                                </span>
                                            </Dropdown.Item>*/}
                                            <Dropdown.Item href="/logout" className="border-top">
                                                <BiPowerOff className={"me-2"}/>
                                                {/*<i className="bx bx-power-off me-2"></i>*/}
                                                <span className="align-middle">{t('nav.menu-text-log-out')}</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                        {openSearch && <div className="navbar-search-wrapper search-input-wrapper ">
                            <input
                                type="text"
                                className="form-control search-input container-fluid border-0"
                                placeholder="Search..."
                                aria-label="Search..."
                            />
                            {/*<i className="bx bx-x bx-sm search-toggler cursor-pointer"></i>*/}
                            <BiX className="bx-sm search-toggler cursor-pointer" onClick={() => setOpenSearch(false)}/>
                        </div>
                        }

                    </div>
                </nav>
        </>
    )
}

export default Navbar
