import cover_step2 from "../../../../common/assets/img/cover/cover_write_memo_step2.gif";
import {GrFormNext, GrFormPrevious} from "react-icons/gr";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addDefaultMemoWidget, memoWriteState, setMemoDescription, setMemoStep
} from "../../../../features/reducer/memo/memoWriterSlice";
import Widget from "./widget/Widget";
import {MdAddCircleOutline} from "react-icons/md";
import {notificationErrorCustomMessage} from "../../../../common/config/message";
import {useTranslation} from "react-i18next";

const MemoWidget = () => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [data, setData] = useState({})
    const [items, setItems] = useState([])
    const {t} = useTranslation();


    useEffect(() => {
        const desc = memo.description
        setData({
            title: desc?.title,
        })
    }, [memo.description])

    useEffect(() => {
        setItems(memo.widgets)
    }, [memo.widgets])

    const getReceiversName = () => {
        let name = []
        if (memo.receivers.length > 0) {
            memo.receivers.map(item => {
                if (typeof item?.nickName !== `undefined`) name.push(item?.nickName)
            })
        }
        return name.join(`, `)
    }

    const handleStep = key => {
        if (memo?.widgets?.length < 1) {
            notificationErrorCustomMessage(t('writememo.step-alert-choose-widget'))
            return
        }
        let error = false
        memo?.widgets.map(item => {
            if (item.majorType == `password`) {
                if (item.title == `` || item.url == `` || item.username == `` || item.password == ``) {
                    error = true
                }
            } else {
                if (item.content == ``) {
                    error = true
                }
            }
        })
        if (error) {
            notificationErrorCustomMessage(t('writememo.widget-choose-widget-add-more'), `info`)
            return
        }
        dispatch(setMemoStep(key))
    }

    const handleInput = e => {
        const {name, value} = e.target
        setData({...data, [name]: value})
        dispatch(setMemoDescription({name: name, value: value}))
    }

    const hadleAddWidget = () => {
        dispatch(addDefaultMemoWidget())
    }


    return (<>
        <div id="memo-write" className={"content active"}>
            <div className="row g-3">
                <div className="d-flex col-12 justify-content-between">
                    <button className="btn btn-primary btn-prev" onClick={() => handleStep('setting')}>
                        <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                    </button>
                    <button className="btn btn-primary btn-next" onClick={() => handleStep('preview')}>
                        {t('writememo.next-button-page2')} <GrFormNext className="memo_write_icon"/>
                    </button>
                </div>
                <div className="col-12">
                    <div className="img-fluid rounded-2 py-5 w-100 d-flex align-items-center  flex-column justify-content-center">
                        <h4 className="fw-bold">3. {t('writememo.widget-title-h1')}</h4>
                        <h4 className="fw-bold">{t('writememo.widget-title-to')}
                            {getReceiversName()}
                        </h4>
                        <img className="image_gif_cover" src={cover_step2}/>
                    </div>
                </div>
                <div className="col-sm-12 margin-bottom-10 margin-top-20">
                    <input
                        type="text"
                        name="title"
                        className="form-control box_input_title"
                        value={data?.title}
                        placeholder="ระบุชื่อเรื่อง MEMO"
                        onChange={e => handleInput(e)}
                    />
                </div>
                <div className="col-lg-12">
                    <div className="widget-item">
                        {items.length > 0 ? items.map((widget, index) => (
                            <div key={`widget-${index}`} className="boxwyswyg border">
                                <Widget type={widget?.majorType} index={index} widget={widget}/>
                            </div>)) : (<div className="alert alert-info text-center">{t('writememo.widget-please-add-widget')}</div>)}
                    </div>

                    <div className="text-center">
                        <button type="button" onClick={() => hadleAddWidget()} className="btn btn-label-github">
                            <MdAddCircleOutline/> {t('writememo.widget-text-new-widget')}
                        </button>
                    </div>
                </div>

                <div className="d-flex col-12 justify-content-between">
                    <button className="btn btn-primary btn-prev" onClick={() => handleStep('setting')}>
                        <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                    </button>
                    <button className="btn btn-primary btn-next" onClick={() => handleStep('preview')}>
                        {t('writememo.next-button-page2')} <GrFormNext className="memo_write_icon"/>
                    </button>
                </div>
            </div>
        </div>
    </>)
}

export default MemoWidget
