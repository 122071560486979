import imgProfile from '../../../common/assets/img/mali/profile_temp/cot.jpg'
import writememo_banner_light from "../../../common/assets/img/cover/cover_my_receiver.gif";

import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {IoIosPaperPlane, IoMdPersonAdd} from "react-icons/io";
import {CgProfile} from "react-icons/cg";
import {RiDeleteBinLine, RiEditBoxLine} from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import {getMyContacts} from "../../../features/reducer/contact/contactSlice";
import {deleteAsyncContact, fetchAsyncContacts} from "../../../features/reducer/contact/contactThunk";
import ImageUserProfile from "../../utility/ImageUserProfile";
import ReceiverTag from "../../utility/ReceiverTag";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";

const ReceiverMemo = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const myContacts = useSelector(getMyContacts)
    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 20
    })

    useEffect(() => {
        dispatch(activeMenu({menu: `receiverMemo`}))
        handleSearch()
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        console.log(myContacts)
    }, [myContacts])

    const handleSearch = () => {
        dispatch(fetchAsyncContacts(filter))
    }

    const ConfirmDeleteContact = (e, id) => {
        e.preventDefault()
        confirmAlert({
            title: t('addreceiver.text-confirm-page'), message: t('addreceiver.text-are-your-delete'), buttons: [{
                label: t('addreceiver.text-yes'), onClick: () => {
                    dispatch(deleteAsyncContact({
                        id: id,
                        handleCallback: handleSearch
                    }))
                }
            }, {
                label: t('addreceiver.text-no')
            }]
        });
    };

    return (<>
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">MALI /</span> {t('addreceiver.navi-receiver')}
            </h4>
            <div className="card">
                <div className="card-header border-bottom">
                    <div className="col-12">
                        <div className="img-fluid rounded-2 w-100">
                            <center>

                                <br></br>
                                <br></br>
                                <h4 className="fw-bold">{t('receiver.title-topic-list-receiver')}</h4>
                                <img className="image_gif_cover" src={writememo_banner_light}/>
                            </center>
                            <div className="option_button margin_bottom_25px_del">
                                <Link to="/receiver/add" className="menu-link">
                                    <button type="button" className="btn btn-primary " data-tip={t('addreceiver.text-add-new-receiver')}>
                                        <IoMdPersonAdd className="icon"/>&nbsp; {t('receiver.text-title-receiver')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-datatable table-responsive">
                    <table className="datatables-users table border-top">
                        <thead>
                        <tr>
                            <th>{t('receiver.text-title-receiver')}</th>
                            <th width={200}>{t('receiver.text-title-type')}</th>
                            <th width={150}>{t('receiver.text-title-memo')}</th>
                            <th width={150}>{t('receiver.text-title-status')}</th>
                            <th width={200}>{t('receiver.text-title-manage')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {myContacts?.data?.length > 0 ? myContacts.data.map(item => (
                            <tr key={item.id}>
                                <td className="sorting_1">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="d-flex justify-content-start align-items-center">
                                        {/*<div className="avatar-wrapper">*/}
                                        {/*    <div className="avatar avatar-md me-3 ">*/}
                                        {/*        <img src={imgProfile} alt="Avatar" className="rounded-circle border_profile"/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="mr-3">
                                            <ImageUserProfile user={item}/>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <a href="javascript:void(0);" className="text-body text-truncate"><span className="fw-semibold">{item?.firstName} {item?.lastName}</span></a><small className="text-muted">{item?.nickName}</small>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <ReceiverTag name={item.type}/>
                                    {/*<span className="badge rounded-pill bg-label-friends" data-tip="กลุ่มเพื่อน">Friends</span>*/}
                                </td>
                                <td>
                                    <IoIosPaperPlane className="plane_icon icon_table_size" data-tip="จำนวน MEMO ที่เขียนถึง"/> {item?.memoCount}
                                </td>
                                <td>
                                    {!item.verifyUserId ?
                                        <span className="badge bg-label-secondary" data-tip={t('receiver.text-unconfirm-human')}>{t('receiver.text-unconfirm-human')}</span> :
                                        <span className="badge bg-success" data-tip={t('receiver.text-confirm-human')}>{t('receiver.text-confirm-human')}</span>}

                                </td>
                                <td>
                                    <Link to={`/memo?receiver=${item.code}`} data-tip={t('receiver.text-write-memo')}><IoIosPaperPlane className="manage_icon icon_table_size"/></Link>
                                    <Link to={`/receiver/${item.code}/edit`} data-tip={t('receiver.text-edit-receiver')}><RiEditBoxLine className="manage_icon icon_table_size"/></Link>
                                    <Link to={`/receiver/${item.code}`} data-tip={t('receiver.text-view-profile')}>
                                        <CgProfile className="manage_icon icon_table_size"/></Link>
                                    <a href='#' onClick={e => ConfirmDeleteContact(e, item?.code)} data-tip={t('receiver.text-del')}><RiDeleteBinLine className="manage_icon icon_table_size"/></a>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={5} className="text-center">{t('receiver.text-detail-no-contact')}</td>
                            </tr>
                        )}


                        </tbody>


                    </table>
                </div>
            </div>
        </div>
    </>)
}

export default ReceiverMemo
