import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import logo from "../../../common/assets/img/mali/icon_app.png";
import {IoIosLock} from "react-icons/io";
import {FaBitcoin} from "react-icons/fa";
import {BsFillSafeFill} from "react-icons/bs";
import {RiLockPasswordFill,RiFilePaperFill} from "react-icons/ri";
import {MdAccessTimeFilled,MdSpeakerNotes} from "react-icons/md";
import {useTranslation} from "react-i18next";

const Service = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: `service`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="container-xxl container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">{t('ourservice.page-nav-title-2')} /</span> {t('ourservice.page-nav-title-1')}
                </h4>
                <div className="row invoice-edit">
                    <div className="col-12 col-lg-12 mb-4 mb-lg-0">
                        <div className="card border h-100 margin-bottom-10">
                            <div className="card-body">
                                <div className="help-center-popular-articles py-5">
                                    <div className="container-xl">
                                        <h2 className="text-center mb-4">{t('ourservice.text-bottom-our-service')}</h2>
                                        <h5 className="text-center">
                                            บริการของเรา
                                        </h5>
                                        <div className="gap-2 d-flex flex-wrap justify-content-center align-items-center pt-2 pb-1 mt-5 mb-4">

                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10 mx-auto">
                                                <div className="row mb-3">
                                                    <div className="col-md-4 mb-4 mb-md-0">
                                                        <div className="card shadow-none border">
                                                            <div className="card-body text-center">


                                                                <FaBitcoin  className="service_icon_size"></FaBitcoin>

                                                                <h5>{t('ourservice.text-title-digital-assets')}</h5>
                                                                <p className="service_p">{t('ourservice.detail-of-digital-assets')}</p>
                                                                {/*<a className="btn btn-label-primary" href="./pages-help-center-article.html">Read More</a>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mb-4 mb-md-0">
                                                        <div className="card shadow-none border">
                                                            <div className="card-body text-center">
                                                                <BsFillSafeFill  className="service_icon_size"></BsFillSafeFill>
                                                                <h5>{t('ourservice.text-title-Important-documents')}</h5>
                                                                <p className="service_p">{t('ourservice.detail-of-Important-documents')}</p>
                                                                {/*<a className="btn btn-label-primary" href="./pages-help-center-article.html">Read More</a>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <div className="card shadow-none border">
                                                            <div className="card-body text-center">
                                                                <RiLockPasswordFill  className="service_icon_size"></RiLockPasswordFill>
                                                                <h5>{t('ourservice.text-title-password')}</h5>
                                                                <p className="service_p">{t('ourservice.detail-of-password')}

                                                                </p>
                                                                {/*<a className="btn btn-label-primary" href="./pages-help-center-article.html">Read More</a>*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 mb-4 mb-md-0">
                                                        <div className="card shadow-none border">
                                                            <div className="card-body text-center">
                                                                <RiFilePaperFill  className="service_icon_size"></RiFilePaperFill>
                                                                <h5>{t('ourservice.text-title-confidential-Information')}</h5>
                                                                <p className="service_p">{t('ourservice.detail-confidential-Information')}</p>
                                                                {/*<a className="btn btn-label-primary" href="./pages-help-center-article.html">Read More</a>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mb-4 mb-md-0">
                                                        <div className="card shadow-none border">
                                                            <div className="card-body text-center">
                                                                <MdAccessTimeFilled  className="service_icon_size"></MdAccessTimeFilled>
                                                                <h5>{t('ourservice.text-title-pass-memories')}</h5>
                                                                <p className="service_p">{t('ourservice.detail-of-pass-memories')}</p>
                                                                {/*<a className="btn btn-label-primary" href="./pages-help-center-article.html">Read More</a>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <div className="card shadow-none border">
                                                            <div className="card-body text-center">
                                                                <MdSpeakerNotes  className="service_icon_size"></MdSpeakerNotes>
                                                                <h5>{t('ourservice.text-title-tell-intention')}</h5>
                                                                <p className="service_p">{t('ourservice.detail-of-tell-intention')}</p>
                                                                {/*<a className="btn btn-label-primary" href="./pages-help-center-article.html">Read More</a>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service
