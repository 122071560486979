import React from "react";
import {BsClockHistory,BsFillShieldLockFill} from "react-icons/bs";
import {GiNotebook} from "react-icons/gi";
import parse from "html-react-parser";

const MemoriesGuideModal = ({content,icon}) => {
    return (
        <>
            <center>

                {icon === 'clock' &&
                 <BsClockHistory className="content_modal_icon"></BsClockHistory>
                }

                {icon === 'notebook' &&
                    <GiNotebook className="content_modal_icon"></GiNotebook>
                }

                {icon === 'lock' &&
                    <BsFillShieldLockFill className="content_modal_icon"></BsFillShieldLockFill>
                }
            </center>
            <div className="card-body text-center">
                <p className="content_modal">{parse(content)}</p>
            </div>

        </>
    )
}

export default MemoriesGuideModal
