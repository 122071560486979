import httpClient, {httpObjQueryString} from "../config/httpClient";

export const otpApi = (phone) => {
    return httpClient.post(`/user/otpRequest`, {
        phone: phone,
    })
}


export const otpVerifyApi = (data) => {

    return httpClient.post(`/user/otpVerifyWithRegister`, {...data, type: `register`})
}

export const registerUserApi = (data) => {
    return httpClient.post(`/user/register`, data)
}


export const checkUsernameApi = (username) => {
    return httpClient.get(`/user/check/${username}`)
}


export const resetPasswordLinkApi = (email) => {
    return httpClient.post(`/user/password/reset`, {email: email})
}
export const resetPasswordNewApi = (data) => {
    return httpClient.post(`/user/password/new`, data)
}
