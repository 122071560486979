import React, {useEffect, useState} from "react";
import {FaBitcoin} from "react-icons/fa";
import {Link} from "react-router-dom";
import {IoIosPaperPlane} from "react-icons/io";
import MemoTypeTag from "../../utility/MemoTypeTag";
import {useTranslation} from "react-i18next";

const MemoriesGuideModal = ({guide}) => {


    const {t,i18n} = useTranslation();
    const [lang, setLang] = useState(i18n.language)

    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language]);


    return (
        <>
            <div className="card-body text-center">
                <div className="mb-4"><MemoTypeTag name={guide?.type}/></div>
                <h5> {lang == `th` ? guide?.title : guide?.titleEN}</h5>
                <p className="service_p">{lang == `th` ? guide?.detail : guide?.detailEN}</p>
                <div className="option_button">
                    <Link to={`/memo?guide=${guide.code}`} className="menu-link">
                        <button type="button" className="btn btn-primary">
                            <IoIosPaperPlane className="icon" data-tip="จำนวน MEMO ที่เขียนถึง"/>&nbsp; {t('memoguide.text-write-this-topic')}
                        </button>
                    </Link>
                </div>
            </div>

        </>
    )
}

export default MemoriesGuideModal
