import {BrowserRouter as Router, Route, Routes,} from "react-router-dom";
import React, {useEffect} from "react";
import Login from "./components/authentication/Login";
import Admin from "./components/admin/Admin";

// import 'bootstrap/dist/css/bootstrap.min.css';
/*CSS*/
import "react-light-notifications/lib/main.css";

import './common/assets/css/bootstrap-addon.css'
import './common/assets/vendor/css/rtl/core.css'
import './common/assets/vendor/css/rtl/theme-default.css'
import "react-datepicker/dist/react-datepicker.css";
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import './App.css';


/*Components*/
import Register from "./components/authentication/Register";
import ForgotPassword from "./components/authentication/ForgotPassword";
import ConfirmPassword from "./components/authentication/ConfirmPassword";
import Otp from "./components/authentication/Otp";
import Dashboard from "./components/admin/dashboard/Dashboard";
import MyMemo from "./components/admin/memo/MyMemo";
import ReceiverMemo from "./components/admin/receiver/ReceiverMemo";
import ReceiverAdd from "./components/admin/receiver/ReceiverAdd";
import ReceiverView from "./components/admin/receiver/ReceiverView";
import CustomerProfile from "./components/admin/customer/CustomerProfile";
import SettingsAccount from "./components/admin/settings/SettingsAccount";
import SettingsSecurity from "./components/admin/settings/SettingsSecurity";
import SettingsBill from "./components/admin/settings/SettingsBill";
import SettingsNotification from "./components/admin/settings/SettingsNotification";
import Mali from "./components/admin/mali/Mali";
import ServiceCharge from "./components/admin/price/ServiceCharge";
import Faq from "./components/admin/manual/Faq";
import Readme from "./components/admin/manual/Readme";
import Procedure from "./components/admin/manual/Procedure";
import WhatMali from "./components/admin/about/WhatMali";
import Service from "./components/admin/about/Service";
import Policy from "./components/admin/about/Policy";
import Rule from "./components/admin/about/Rule";
import Contact from "./components/admin/contact/Contact";
import ReportIssue from "./components/admin/contact/ReportIssue";
import './services/i18next'
import NotificationList from "./components/admin/notification/NotificationList";
import NotificationViews from "./components/admin/notification/NotificationViews";
import ReceiverEdit from "./components/admin/receiver/ReceiverEdit";
import Logout from "./components/authentication/Logout";
import Payment from "./components/admin/price/Payment";
import PaymentBank from "./components/admin/price/PaymentBank";
import PaymentCard from "./components/admin/price/PaymentCard";
import Memo from "./components/admin/memo/new/Memo";
import MemoView from "./components/admin/memo/new/MemoView";


function App() {

    useEffect(() => {
        document.title = "MALI - DIGITAL TESTAMENT"
    }, [])

    return (
        <Router>
            <Routes>
                <Route exact={true} path="/login" element={<Login/>}/>
                <Route exact={true} path="/register" element={<Register/>}/>
                <Route exact={true} path="/forgotpassword" element={<ForgotPassword/>}/>
                <Route exact={true} path="/confirmpassword" element={<ConfirmPassword/>}/>
                <Route exact={true} path="/otp" element={<Otp/>}/>
                <Route exact={true} path="/logout" element={<Logout/>}/>
                <Route path="/" element={<Admin/>}>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>

                    {/*Memo*/}
                    {/*<Route path="/memo" element={<WriteMemo/>}/>*/}
                    {/*<Route path="/memo/:id" element={<WriteMemo/>}/>*/}
                    <Route path="/memo/me" element={<MyMemo/>}/>
                    {/*<Route path="/memo/:id/edit" element={<WriteMemo/>}/>*/}
                    {/*<Route path="/memo/:id/view" element={<MyMemoView/>}/>*/}

                    <Route path="/memo" onLeave={``} element={<Memo/>}/>
                    <Route path="/memo/:id" element={<Memo/>}/>
                    <Route path="/memo/:id/view" element={<MemoView/>}/>

                    {/*Inheritor*/}
                    <Route path="/receiver/list" element={<ReceiverMemo/>}/>
                    <Route path="/receiver/add" element={<ReceiverAdd/>}/>
                    <Route path="/receiver/:id" element={<ReceiverView/>}/>
                    <Route path="/receiver/:id/edit" element={<ReceiverEdit/>}/>


                    {/*Customer*/}
                    <Route path="/customer/profile" element={<CustomerProfile/>}/>

                    {/*For You*/}
                    <Route path="/mali" element={<Mali/>}/>

                    {/*Price*/}
                    <Route path="/price" element={<ServiceCharge/>}/>
                    <Route path="/payment" element={<Payment/>}/>
                    <Route path="/payment/bank" element={<PaymentBank/>}/>
                    <Route path="/payment/card" element={<PaymentCard/>}/>

                    {/*Manual*/}
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/readme" element={<Readme/>}/>
                    <Route path="/procedure" element={<Procedure/>}/>


                    {/*About*/}
                    <Route path="/aboutus" element={<WhatMali/>}/>
                    <Route path="/service" element={<Service/>}/>
                    <Route path="/policy" element={<Policy/>}/>
                    <Route path="/rule" element={<Rule/>}/>


                    {/*Contact*/}
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/report-issue" element={<ReportIssue/>}/>


                    {/*Settings*/}
                    <Route path="/settings/account" element={<SettingsAccount/>}/>
                    <Route path="/settings/security" element={<SettingsSecurity/>}/>
                    <Route path="/settings/bill" element={<SettingsBill/>}/>
                    <Route path="/settings/notification" element={<SettingsNotification/>}/>

                    {/*Notification*/}
                    <Route path="/notification/list" element={<NotificationList/>}/>
                    <Route path="/notification/view" element={<NotificationViews/>}/>

                </Route>
            </Routes>
        </Router>
    );
}

export default App;
