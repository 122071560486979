import {useEffect, useState} from "react";

const ImageUserProfile = ({user, size = '', className = ``, style = `img-circle`}) => {
    const [image, setImage] = useState("")
    const [exist, setExist] = useState(false)
    useEffect(() => {
        const url = userImageSrc(user)
        // console.log(user)
        if(url !== ``) {
            setImage(url)
            imageExists(url)
        }
        // checkName()
    }, [user])

    const imageExists = url => {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            setExist(true);
        };
        img.onerror = function (e) {
            setExist(false);
        };
    }
    const firstCharName = (name) => {
        let str = name
        if(typeof str !== `undefined` && str !== ``) {
            const regExp = /[^a-zก-ฮ]/g;
            let letters = str.toLowerCase().replace(regExp, '');
            if (letters.length > 0) {
                return letters.charAt(0).toUpperCase()
            }
        }
    }
    return (
        <div className={`avatar-image ${size} ${className}`}>
            <div className={`img-card-placeholder img-card-s1 ${style}`}>
                <div className="image" style={{backgroundImage: `url(${image})`}}>
                    {!exist && (
                        <span>{firstCharName(user?.firstName)}</span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ImageUserProfile

export const userImageSrc = (user) => {
    const imageUrl = user?.imageUrl
    if (typeof imageUrl !== `undefined` && user?.imageUrl !== ``) {
        if (imageUrl?.includes(process.env.REACT_APP_API_BASE)) {
            return user?.imageUrl
        } else {
            return `${process.env.REACT_APP_API_BASE}/media/${user?.imageUrl}`
        }
    }
    // if (user?.imageUrl !== ``) {
    //     return user?.imageUrl
    // }
    // return `${process.env.REACT_APP_API_BASE}/media/${user?.imageUrl}`


    if (user?.media && typeof user?.media?.name !== `undefined` && user?.media?.name !== ``) {
        return `${process.env.REACT_APP_API_BASE}/images/${user?.media?.name}`
    }

    return ``
}
