import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {
    addSubscribeApi,
    addSubscribeOmiseApi,
    addSubscribeReddemCodeApi,
    fetchPackageApi
} from "../../../common/api/subscribeApi";
import {notificationSuccessMessage} from "../../../common/config/message";
import {setRedirect} from "../utility";
import {getAsyncProfile} from "../user/userThunk";

export const addSubscription = createAsyncThunk(`subscribe/addSubscription`, async (data, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, addSubscribeApi(data))
        notificationSuccessMessage(`แจ้งชำระเงินแล้ว กรุณารอทีมงานตรวจสอบข้อมูลภายใน 5 นาที`)
        // baseThunk.dispatch(setSelectPackage(null))
        baseThunk.dispatch(setRedirect(`/price`))
        return response.data
    } catch (e) {
        console.log(e)
    }
})
export const addSubscriptionRedeemAsync = createAsyncThunk(`subscribe/addSubscriptionRedeemAsync`, async (data, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, addSubscribeReddemCodeApi(data))
        if (response.status === 200) {
            baseThunk.dispatch(getAsyncProfile())
            return response.data
        }
    } catch (e) {
        console.log(e)
    }
})
export const addSubscriptionOmiseAsync = createAsyncThunk(`subscribe/addSubscriptionOmiseAsync`, async (data, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, addSubscribeOmiseApi(data))
        if (response.status === 200) {
            baseThunk.dispatch(getAsyncProfile())
            baseThunk.dispatch(setRedirect(`/settings/bill`))
            return response.data
        }
    } catch (e) {
        console.log(e)
    }
})


export const fetchPackageAsync = createAsyncThunk(`subscribe/fetchPackageAsync`, async (data, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, fetchPackageApi())
        return response.data
    } catch (e) {
        console.log(e)
    }
})

