import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import banner_cover2 from "../../../common/assets/img/cover/cover2.jpg";
import imgProfile from '../../../common/assets/img/mali/profile_temp/knot.jpg'
import {CgLock} from "react-icons/cg";
import {BsPerson} from "react-icons/bs";
import {RiFilePaper2Line, RiNotification4Line} from "react-icons/ri";
import {IoLogoWindows} from "react-icons/io";
import {MdOutlinePhoneIphone} from "react-icons/md"
import {DiAndroid} from "react-icons/di";
import {FaApple} from "react-icons/fa";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {changePasswordAsyncUser, updateAsyncUser} from "../../../features/reducer/user/userThunk";
import {getProfile} from "../../../features/reducer/user/userSlice";
import {useTranslation} from "react-i18next";

const SettingsSecurity = () => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const {t} = useTranslation();

    const [data, setData] = useState({
        password: ``, confirmPassword: ``, newPassword: ``,
    })

    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleInput = (e) => {
        const {name, value} = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const handleChagePassword = () => {
        const required = ['password', 'confirmPassword', 'newPassword'];
        let errors = false
        required.map(v => {
            if (typeof data[v] === `undefined` || data[v] === ``) {
                errors = true
            }
        })
        if (errors) {
            notificationErrorCustomMessage(`กรุณากรอกข้อมูลให้ครบ (*)`)
            return
        }

        if (data.newPassword.length < 8) {
            notificationErrorCustomMessage(`ขั้นต่ำ 8 ตัวอักษรหรือมากกว่า`)
            return
        }
        // console.log(dt)
        dispatch(changePasswordAsyncUser({
            ...data,
            username: profile?.username
        }))
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y customer_profile_page">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">{t('settingsecurity.page-nav-title-1')} /</span> {t('settingsecurity.page-nav-title-2')}
                </h4>


                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-pills flex-column flex-md-row mb-3">
                            <li className="nav-item">
                                <Link to="/settings/account" className="nav-link"><BsPerson className="icon_size_18"/> {t('settingsecurity.menu-header-user-account')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/settings/security" className="nav-link active"><CgLock className="icon_white icon_size_18"/> {t('settingsecurity.menu-header-security')}</Link>
                            </li>
                            <li className="nav-item hide">
                                <Link to="/settings/bill" className="nav-link"><RiFilePaper2Line className="icon_size_18"/> {t('settingsecurity.menu-header-billing')}</Link>
                            </li>
                            <li className="nav-item hide">
                                <Link to="/settings/notification" className="nav-link"><RiNotification4Line className="icon_size_18"/> การแจ้งเตือน</Link>
                            </li>
                        </ul>


                        <div className="card mb-4">
                            <h5 className="card-header">{t('settingsecurity.text-title-change-password')}</h5>
                            <div className="card-body">
                                <form id="formAccountSettings" method="POST" onSubmit="return false">
                                    <div className="row">
                                        <div className="form-password-toggle col-md-6 mb-3">
                                            <label className="form-label" htmlFor="currentPassword">{t('settingsecurity.text-title-current-password')}</label>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name="password"
                                                    onChange={e => handleInput(e)}
                                                    id="currentPassword"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-password-toggle col-md-6 mb-3">
                                            <label className="form-label" htmlFor="newPassword">{t('settingsecurity.text-title-new-password')}</label>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    id="newPassword"
                                                    name="newPassword"
                                                    onChange={e => handleInput(e)}
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                            </div>
                                        </div>

                                        <div className="form-password-toggle col-md-6 mb-3">
                                            <label className="form-label" htmlFor="confirmPassword">{t('settingsecurity.text-title-confirm-password')}</label>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name="confirmPassword"
                                                    onChange={e => handleInput(e)}
                                                    id="confirmPassword"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <p className="fw-semibold mt-2">{t('settingsecurity.text-title-password-instructions')}</p>
                                            <ul className="ps-3 mb-0">
                                                <li className="mb-1">{t('settingsecurity.text-detail-1')}</li>
                                                <li className="mb-1">{t('settingsecurity.text-detail-2')}</li>
                                                <li>{t('settingsecurity.text-detail-3')}</li>
                                            </ul>
                                        </div>
                                        <div className="col-12 mt-1">
                                            <button type="button" onClick={() => handleChagePassword()} className="btn btn-primary me-2">{t('settingsecurity.text-save-button')}</button>
                                            <button type="reset" className="btn btn-label-secondary">{t('settingsecurity.text-cancel-button')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="card mb-4 hide">
                            <h5 className="card-header">การยืนยันเข้าใช้งานแบบ 2 ขั้นตอน (2FA)</h5>
                            <div className="card-body">
                                <p className="fw-semibold mb-3">การยืนยันเข้าใช้งานแบบ 2 ขั้นตอน (2FA) ของคุณไม่ได้เปิดการใช้งาน</p>
                                <p className="w-75">
                                    2FA จะช่วยเพิ่มความปลอดภัยอีกชั้นสำหรับบัญชีของคุณ โดยจะมีการขอข้อมูลในการเข้าระบบมากกว่าการใส่รหัสเผื่อล๊อคอิน
                                    <a href="javascript:void(0);"> (เรียนรู้เพิ่มเติม)</a>
                                </p>
                                <button className="btn btn-primary mt-2" data-bs-toggle="modal" data-bs-target="#enableOTP">
                                    เปิดใช้งาน 2FA
                                </button>
                            </div>
                        </div>

                        <div className="modal fade" id="enableOTP" tabIndex="-1" aria-hidden="true">
                            <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                                <div className="modal-content p-3 p-md-5">
                                    <div className="modal-body">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        <div className="text-center mb-4">
                                            <h3 className="mb-4">Enable One Time Password</h3>
                                        </div>
                                        <h6>Verify Your Mobile Number for SMS</h6>
                                        <p>
                                            Enter your mobile phone number with country code and we will send you a verification code.
                                        </p>
                                        <form id="enableOTPForm" className="row g-3 mt-3" onSubmit="return false">
                                            <div className="col-12">
                                                <label className="form-label" htmlFor="modalEnableOTPPhone">Phone Number</label>
                                                <div className="input-group input-group-merge">
                                                    <span className="input-group-text">+1</span>
                                                    <input
                                                        type="text"
                                                        id="modalEnableOTPPhone"
                                                        name="modalEnableOTPPhone"
                                                        className="form-control phone-number-otp-mask"
                                                        placeholder="202 555 0111"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary me-1 me-sm-3">Submit</button>
                                                <button
                                                    type="reset"
                                                    className="btn btn-label-secondary"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="hide card">
                            <h5 className="card-header">อุปกรณ์ที่เข้าใช้งานล่าสุด</h5>
                            <div className="table-responsive">
                                <table className="table border-top">
                                    <thead>
                                    <tr>
                                        <th className="text-truncate">แอพพลิเคชั่น</th>
                                        <th className="text-truncate">อุปกรณ์</th>
                                        <th className="text-truncate">สถานที่</th>
                                        <th className="text-truncate">กิจกรรมล่าสุด</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="text-truncate">
                                            <IoLogoWindows className="bx bxl-windows text-info me-3"/>
                                            <span className="fw-semibold">Chrome ผ่าน Windows</span>
                                        </td>
                                        <td className="text-truncate">HP Spectre 360</td>
                                        <td className="text-truncate">Switzerland</td>
                                        <td className="text-truncate">10, July 2021 20:07</td>
                                    </tr>
                                    <tr>
                                        <td className="text-truncate">
                                            <MdOutlinePhoneIphone className="bx bx-mobile-alt text-danger me-3"/>
                                            <span className="fw-semibold">Chrome ผ่าน iPhone</span>
                                        </td>
                                        <td className="text-truncate">iPhone 12x</td>
                                        <td className="text-truncate">Australia</td>
                                        <td className="text-truncate">13, July 2021 10:10</td>
                                    </tr>
                                    <tr>
                                        <td className="text-truncate">
                                            <DiAndroid className="bx bxl-android text-success me-3"/>
                                            <span className="fw-semibold">Chrome ผ่าน Android</span>
                                        </td>
                                        <td className="text-truncate">Oneplus 9 Pro</td>
                                        <td className="text-truncate">Dubai</td>
                                        <td className="text-truncate">14, July 2021 15:15</td>
                                    </tr>
                                    <tr>
                                        <td className="text-truncate">
                                            <FaApple className="bx bxl-apple me-3"/>
                                            <span className="fw-semibold">Chrome ผ่าน MacOS</span>
                                        </td>
                                        <td className="text-truncate">Apple iMac</td>
                                        <td className="text-truncate">India</td>
                                        <td className="text-truncate">16, July 2021 16:17</td>
                                    </tr>
                                    <tr>
                                        <td className="text-truncate">
                                            <IoLogoWindows className="bx bxl-windows text-info me-3"/>
                                            <span className="fw-semibold">Chrome ผ่าน Windows</span>
                                        </td>
                                        <td className="text-truncate">HP Spectre 360</td>
                                        <td className="text-truncate">Switzerland</td>
                                        <td className="text-truncate">20, July 2021 21:01</td>
                                    </tr>
                                    <tr>
                                        <td className="text-truncate">
                                            <DiAndroid className="bx bxl-android text-success me-3"/>
                                            <span className="fw-semibold">Chrome ผ่าน Android</span>
                                        </td>
                                        <td className="text-truncate">Oneplus 9 Pro</td>
                                        <td className="text-truncate">Dubai</td>
                                        <td className="text-truncate">21, July 2021 12:22</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsSecurity
