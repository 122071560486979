import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import Tour, {handleStart} from "../Tour";
import logo from "../../../common/assets/img/mali/icon_app.png";
import img_girl_think from "../../../common/assets/img/explan/girl_think_good.png";
import {BsJournalBookmark} from "react-icons/bs";
import {useTranslation} from "react-i18next";

const Readme = () => {
    const dispatch = useDispatch()
    const [showTour, setShowTour] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: `readme`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handleSetTour = (open) => {
        setShowTour(open)
    }


    return (<>
        <div className="container-xxl container-p-y">
            {showTour && <Tour/>}
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">{t('howtouse.page-nav-title-1')} /</span> {t('howtouse.page-nav-title-2')}
            </h4>
            <div className="row invoice-edit">
                <div className="col-12 col-lg-12 mb-4 mb-lg-0">
                    <div className="card border h-100 margin-bottom-10">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center me-3">
                                <img src={logo} alt="Avatar" className="rounded-circle me-3" width="54"/>
                                <div className="card-title mb-0">
                                    <h5 className="mb-0">{t('howtouse.text-bottom-howto-use')}</h5>
                                    <small className="text-muted">HOW TO USE</small>
                                </div>
                            </div>
                        </div>
                        <div className="card-body align-content-center">

                            <div className="col-md-12 col-lg-12">
                                <div className=" box_dashboard_add_receiver">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <div className="d-flex justify-content-end align-items-end mt-3 mt-sm-0">
                                                <img
                                                    src={img_girl_think}
                                                    className="img-fluid"
                                                    alt="Image"
                                                    width="250"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <div className="card-body text-center text-sm-start ps-sm-0">
                                                <button onClick={() => handleSetTour(true)}
                                                        data-bs-target="#addRoleModal"
                                                        data-bs-toggle="modal"
                                                        className="btn btn-primary add-new-role text-nowrap mb-3"
                                                ><BsJournalBookmark className="icon_btn_dashboard"/>
                                                    {t('howtouse.text-button-howto-use')}
                                                </button>
                                                <p className="p_read">{t('howtouse.detail-of-htu')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Readme
