import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    contact: {
        subject: ``,
        detail: ``,
        firstName: ``,
        lastName: ``,
        email: ``,
        phone: ``,
        file: null,
    },
}

const emailSlice = createSlice({
    name: `email`,
    initialState: initialState,
    reducers: {
        setContact: (state, {payload}) => {
            return {
                ...state, contact: {
                    ...state.contact,
                    [payload.name]: payload.value
                },
            }
        },
        resetContact: (state, {payload}) => {
            return {...state, contact: initialState.contact}
        },
    },
})

export const getContactData = (state) => state.email.contact
export const {setContact, resetContact} = emailSlice.actions
export default emailSlice.reducer
