import '../../common/assets/vendor/css/pages/page-auth.css'
import '../../common/assets/vendor/libs/bs-stepper/bs-stepper.css'
import createAccount from '../../common/assets/img/explan/sit_typing.png'
import {BiChevronLeft, BiChevronRight, BiDetail, BiHomeAlt, BiUser} from "react-icons/bi";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import logo from '../../common/assets/img/mali/icon_app.png'
import {notificationErrorCustomMessage} from "../../common/config/message";
import {useDispatch, useSelector} from "react-redux";
import {setReisterStep, setUserRegister, userRegisterData} from "../../features/reducer/user/userSlice";
import {checkUsernameAsyncUser, otpAsyncRequest} from "../../features/reducer/user/userThunk";
import {redirect, setRedirect} from "../../features/reducer/utility";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useCookies} from 'react-cookie'

const Register = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const isRedirect = useSelector(redirect)
    const [step, setStep] = useState(1)
    const dataRegister = useSelector(userRegisterData)
    const {t} = useTranslation();
    const [errors, setErrors] = useState({
        username: {error: false}, password: {error: false}, confirmPassword: {error: false},
    })
    const queryParams = new URLSearchParams(window.location.search)
    const [cookies, setCookie, removeCookie] = useCookies(['refInterview']);

    const [data, setData] = useState({
        username: ``,
        password: ``,
        confirmPassword: ``,
        firstName: ``,
        lastName: ``,
        facebookUrl: ``,
        lineId: ``,
        friendTel: ``,
        friendName: ``,
        phone: ``
    })

    const handleInput = e => {
        const {value, name,} = e.target
        // alert(name)
        let v = value
        if (name == `phone` && isNaN(value)) {
            v = v.replace(/[^0-9]/g, '')
        }
        setErrors({
            ...errors, [name]: {error: false},
        })
        setData({
            ...data, [name]: v,
        })
    }
    const handleSubmit = event => {
        event.preventDefault()
        return false
    }

    useEffect(() => {
        // alert(11)
        if (typeof dataRegister?.username !== `undefined` && dataRegister?.username !== ``) {
            setData(dataRegister)
        }
    }, [dataRegister])

    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [dispatch, isRedirect])

    useEffect(() => {
        if (queryParams.get('ref') === `interview`) {
            let expires = new Date()
            expires.setTime(expires.getTime() + 3600 * 1000 * 3)
            setCookie(`refInterview`, queryParams.get('ref'), {path: '/', expires})
        }

        const ref = queryParams.get('ref')
        if (typeof ref !== `undefined` && ref !== `` && ref !== null) {
            localStorage.setItem(`ref`, ref)
        }

        const ads = queryParams.get('ads')
        if (typeof ads !== `undefined` && ads !== `` && ads !== null) {
            localStorage.setItem(`ads`, ads)
        }

    }, [queryParams])


    const handleNext = () => {
        console.log(dataRegister?.step)
        let err = {...errors}
        let hasError = false
        switch (dataRegister?.step) {
            case 1:
                if (data?.username == ``) {
                    err.username = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }
                if (data?.password == ``) {
                    err.password = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }

                if (data?.confirmPassword == ``) {
                    err.confirmPassword = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }
                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data?.username))) {
                    err.username = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }
                if (data?.password?.length < 6) {
                    err.password = {
                        error: true, message: t('register.alert-password-upper-six')
                    }
                }
                if (data?.password !== data?.confirmPassword) {
                    err.password = {
                        error: true, message: t('register.text-alert-1')
                    }
                    err.confirmPassword = {
                        error: true, message: t('register.text-alert-2')
                    }
                }


                Object.keys(err).map(key => {
                    if (err[key].error) {
                        hasError = true
                    }
                })

                setErrors(err)
                if (hasError) return


                dispatch(checkUsernameAsyncUser(data?.username))
                return
            case 2:
                if (data?.firstName === ``) {
                    err.firstName = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }
                if (data?.lastName === ``) {
                    err.lastName = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }
                if (data?.friendName === ``) {
                    err.friendName = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }

                if (data?.friendTel === ``) {
                    err.friendTel = {
                        error: true, message: t('etc.alert-please-input-all')
                    }
                }

                Object.keys(err).map(key => {
                    if (err[key].error) {
                        hasError = true
                    }
                })
                setErrors(err)
                if (hasError) return

                break
            case 3:
                if (data?.phone === `` || isNaN(data?.phone) || data?.phone?.length !== 10) {
                    err.phone = {
                        error: true, message: t('register.alert-number-tel-incorrect')
                    }
                }
                Object.keys(err).map(key => {
                    if (err[key].error) {
                        hasError = true
                    }
                })
                setErrors(err)
                if (hasError) return


                dispatch(setUserRegister({...data, step: 3}))
                dispatch(otpAsyncRequest(data))
                return
                break

        }
        dispatch(setReisterStep(dataRegister?.step + 1))
    }

    const handlePrevious = () => {
        setErrors({})
        dispatch(setReisterStep(dataRegister?.step - 1))
    }

    return (<>
        <div className="authentication-wrapper authentication-cover">
            <div className="authentication-inner row m-0">
                <div className="d-none d-lg-flex col-lg-4 justify-content-end align-items-center p-5 pe-0">
                    <div className="w-px-600">
                        <img src={createAccount} className="img-fluid scaleX-n1-rtl" alt="multi-steps" width="600"/>
                    </div>
                </div>

                <div className="authentication-bg d-flex col-lg-8 p-3 p-sm-5">
                    <div className="w-px-700 d-flex flex-column mx-auto">
                        <div className="w-px-700 app-brand border-bottom mx-3 mb-4">
                            <a href="/" className="app-brand-link gap-2 mb-2">
                                <span className="app-brand-logo main topic_icon_size">
                                    <img width="100%" src={logo}/>
                                </span>
                                <span className="app-brand-text main menu-text fw-bold ms-2 font-color-topic">{t('register.header-title-top')}</span>
                            </a>
                        </div>
                        <div className="my-xxx">
                            <div id="multiStepsValidation" className="bs-stepper shadow-none linear">
                                <div className="bs-stepper-header">
                                    <div className={"step " + (step === 1 ? "active" : "")} data-target="#accountDetailsValidation">
                                        <button type="button" className="step-trigger" aria-selected={step == 1} disabled={step != 1}>
                                            <span className="bs-stepper-circle">
                                                <BiHomeAlt/>
                                            </span>
                                            <span className="bs-stepper-label">
                                                <span className="bs-stepper-title">{t('register.header-of-section-1')}</span>
                                                <span className="bs-stepper-subtitle">{t('register.detail-of-section-1')}</span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="line"></div>
                                    <div className={"step " + (step == 2 ? "active" : "")} data-target="#personalInfoValidation">
                                        <button type="button" className="step-trigger" aria-selected={step == 2} disabled={step != 2}>
                                            <span className="bs-stepper-circle">
                                                <BiUser/>
                                            </span>
                                            <span className="bs-stepper-label">
                                                <span className="bs-stepper-title">{t('register.header-of-section-2')}</span>
                                                <span className="bs-stepper-subtitle">{t('register.detail-of-section-2')}</span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="line"></div>
                                    <div className={"step " + (step == 3 ? "active" : "")} data-target="#billingLinksValidation">
                                        <button type="button" className="step-trigger" aria-selected={step == 3} disabled={step != 3}>
                                            <span className="bs-stepper-circle">
                                                <BiDetail/>
                                            </span>
                                            <span className="bs-stepper-label">
                                                <span className="bs-stepper-title">{t('register.header-of-section-3')}</span>
                                                <span className="bs-stepper-subtitle">{t('register.detail-of-section-3')}</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="bs-stepper-content pt-4">
                                    <form id="multiStepsForm" onSubmit={handleSubmit}>
                                        <div id="accountDetailsValidation" className={"content  fv-plugins-bootstrap5 fv-plugins-framework " + (dataRegister?.step == 1 ? "active dstepper-block" : "")}>
                                            <div className="content-header mb-3">
                                                <h4>{t('register.text-title-1')}</h4>
                                                <span>{t('register.text-bottom-1')}</span>
                                            </div>
                                            <div className="row g-3">

                                                <div className={`col-sm-6 fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid ${errors?.username?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="multiStepsEmail">{t('register.title-topbox-1')}
                                                        <span className="text-danger">*</span></label>
                                                    <input type="email" name="username" className="form-control" onChange={e => handleInput(e)} value={data?.username} autoComplete="new-password" placeholder="mali@email.com" aria-label="john.doe"/>
                                                    <div className="fv-plugins-message-container invalid-feedback">
                                                        {errors?.username?.error && errors?.username?.message}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6"></div>
                                                <div className={`form-password-toggle col-sm-6 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid ${errors?.password?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="multiStepsPass">{t('register.title-topbox-2')}
                                                        <span className="text-danger">*</span></label>
                                                    <div className="input-group input-group-merge has-validation">
                                                        <input type="password" name="password" className="form-control" onChange={e => handleInput(e)} value={data?.password} autoComplete="new-password" placeholder="············" aria-describedby="multiStepsPass2"/>
                                                    </div>
                                                    <div className="fv-plugins-message-container invalid-feedback"> {errors?.password?.error && errors?.password?.message}</div>
                                                </div>
                                                <div className={`form-password-toggle col-sm-6 fv-plugins-icon-container ${errors?.confirmPassword?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="multiStepsConfirmPass">{t('register.title-topbox-3')}
                                                        <span className="text-danger">*</span></label>
                                                    <div className="input-group input-group-merge has-validation">
                                                        <input type="password" name="confirmPassword" className="form-control" onChange={e => handleInput(e)} value={data?.confirmPassword} autoComplete="new-password" placeholder="············" aria-describedby="multiStepsConfirmPass2"/>
                                                    </div>
                                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.confirmPassword?.error && errors?.confirmPassword?.message}</div>
                                                </div>

                                                <div className="d-flex col-12 justify-content-between mt-4">
                                                    <Link to="/login" className="btn btn-label-secondary btn-prev">
                                                        <BiChevronLeft className="bx-sm ms-sm-n2"/>
                                                        <span className="d-none d-sm-inline-block">{t('register.text-back-button-1')}</span>
                                                    </Link>
                                                    <button type="submit" className="btn btn-primary btn-next" onClick={() => handleNext()}>
                                                        <span className="d-none d-sm-inline-block me-0 me-sm-1">{t('register.text-next-button-1')}</span>
                                                        {/*<i className="bx bx-chevron-right bx-sm me-sm-n2"></i>*/}
                                                        <BiChevronRight className="bx-sm me-sm-n2"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>
                                        <div id="personalInfoValidation" className={"content  fv-plugins-bootstrap5 fv-plugins-framework " + (dataRegister?.step == 2 ? "active dstepper-block" : "")}>
                                            <div className="content-header mb-3">
                                                <h4>{t('register.text-title-2')}</h4>
                                                <span>{t('register.text-bottom-2')}</span>
                                            </div>
                                            <div className="row g-3 fv-plugins-icon-container">
                                                <div className={`col-sm-6 fv-plugins-icon-container ${errors?.firstName?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="multiStepsFirstName">{t('register.title-topbox-4')}
                                                        <span className="text-danger">*</span></label>
                                                    <input type="text" id="multiStepsFirstName" name="firstName" onChange={e => handleInput(e)} value={data?.firstName} className="form-control" placeholder={t('register.text-inbox-4')}/>
                                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.firstName?.error && errors?.firstName?.message}</div>
                                                </div>
                                                <div className={`col-sm-6 ${errors?.lastName?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="multiStepsLastName">{t('register.title-topbox-5')}
                                                        <span className="text-danger">*</span></label>
                                                    <input type="text" id="multiStepsLastName" name="lastName" onChange={e => handleInput(e)} value={data?.lastName} className="form-control" placeholder={t('register.text-inbox-5')}/>
                                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.lastName?.error && errors?.lastName?.message}</div>
                                                </div>
                                                {/*<div className="col-md-12">
                                                        <label className="form-label" htmlFor="multiStepsFacebookSocial">{t('register.title-topbox-6')}</label>
                                                        <input type="text" id="multiStepsFacebookSocial" name="facebookUrl" onChange={e => handleInput(e)} value={data?.facebookUrl} className="form-control" placeholder={t('register.text-inbox-6')}/>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="form-label" htmlFor="multiStepsLine">{t('register.title-topbox-7')}</label>
                                                        <input type="text" id="multiStepsLine" name="lineId" onChange={e => handleInput(e)} value={data?.lineId} className="form-control" placeholder={t('register.text-inbox-7')}/>
                                                    </div>*/}

                                                <div className={`col-sm-12 ${errors?.friendName?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="friendName">{t('register.text-input-friend-contact')}
                                                        <span className="text-danger">*</span></label>
                                                    <input type="text" id="friendName" name="friendName" onChange={e => handleInput(e)} value={data?.friendName} placeholder={t('register.text-input-friend-contact-placeholder')} className="form-control"/>
                                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.friendName?.error && errors?.friendName?.message}</div>
                                                </div>

                                                <div className={`col-sm-12 ${errors?.friendTel?.error && `has-error`}`}>
                                                    <label className="form-label" htmlFor="friendTel">{t('register.text-input-friend-contact-tel')}
                                                        <span className="text-danger">*</span></label>
                                                    <input type="text" id="friendTel" name="friendTel" onChange={e => handleInput(e)} value={data?.friendTel} className="form-control"/>
                                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.friendTel?.error && errors?.friendTel?.message}</div>
                                                </div>
                                                <div className="d-flex col-12 justify-content-between mt-4">
                                                    <button type="button" className="btn btn-label-secondary  btn-prev" onClick={() => handlePrevious()}>
                                                        <BiChevronLeft className="bx-sm ms-sm-n2"/>
                                                        <span className="d-none d-sm-inline-block">{t('register.text-back-button-2')}</span>
                                                    </button>
                                                    <button type="button" className="btn btn-primary btn-next" onClick={() => handleNext()}>
                                                        <span className="d-none d-sm-inline-block me-0 me-sm-1">{t('register.text-next-button-2')}</span>
                                                        <BiChevronRight className="bx-sm me-sm-n2"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>
                                        <div id="billingLinksValidation" className={"content  fv-plugins-bootstrap5 fv-plugins-framework " + (dataRegister?.step == 3 ? "active dstepper-block" : "")}>
                                            <div className="content-header mb-3">
                                                <h4>{t('register.text-title-3')}</h4>
                                                <span>{t('register.text-bottom-2')}</span>
                                            </div>
                                            <div className="row g-3">
                                                <div className={`col-md-12 ${errors?.phone?.error && `has-error`}`}>
                                                    <label htmlFor="tel" className="form-label">{t('register.title-topbox-8')}
                                                        <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" id="tel" name="phone" onChange={e => handleInput(e)} value={data?.phone} placeholder={t('register.text-inbox-8')}/>
                                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.phone?.error && errors?.phone?.message}</div>
                                                </div>
                                            </div>

                                            <div className="d-flex col-12 justify-content-between mt-4">
                                                <button type="button" className="btn btn-label-secondary  btn-prev" onClick={() => handlePrevious()}>
                                                    <BiChevronLeft className="bx-sm ms-sm-n2"/>
                                                    <span className="d-none d-sm-inline-block">{t('register.text-back-button-3')}</span>
                                                </button>
                                                <button type="button" className="btn btn-primary btn-next" onClick={() => handleNext()}>
                                                    <span className="d-none d-sm-inline-block">{t('register.text-next-button-3')}</span>
                                                    <BiChevronRight className="bx-sm ms-sm-n2"/>
                                                </button>
                                                {/*<Link to="/otp" className="btn btn-primary btn-next">*/}
                                                {/*    <span className="d-none d-sm-inline-block me-0 me-sm-1">ส่ง OTP</span>*/}
                                                {/*</Link>*/}
                                            </div>
                                            <div></div>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Register
