import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {CgLock} from "react-icons/cg";
import {BsPerson} from "react-icons/bs";
import {RiFilePaper2Line, RiNotification4Line} from "react-icons/ri";
import {confirmAlert} from "react-confirm-alert";
import {setOpenModalContent} from "../../../features/reducer/utility";
import parse from "html-react-parser";
import logo from "../../../common/assets/img/mali/icon_app.png";
import RedeemBoxModal from "./RedeemBoxModal";
import {addSubscriptionRedeemAsync} from "../../../features/reducer/subscribe/subscribeThunk";
import {setSubmitAction, subscribeState} from "../../../features/reducer/subscribe/subscribeSlice";
import {getProfile} from "../../../features/reducer/user/userSlice";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import {useTranslation} from "react-i18next";


const SettingsBill = () => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const subscribe = useSelector(subscribeState)
    const [code, setCode] = useState()
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        if (subscribe.submitAction === 5) {
            // alert(55)
            dispatch(setOpenModalContent({
                title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>' + t('mainsetting.bill-congrate-text')),
                size: `md`, //sm lg xl
                content:
                    <RedeemBoxModal guide="xxxxx"/>, //component or html tag
            }))
            dispatch(setSubmitAction(0))
        }
    }, [subscribe])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const ClickConfirmAlert = () => {
        confirmAlert({
            title: t('mainsetting.bill-cancel-subscript-sure'),
            message: t('mainsetting.bill-cancel-subscript-sure-detail'),
            buttons: [{
                label: t('mainsetting.bill-ok'), onClick: () => {
                    alert(t('mainsetting.bill-cancel-subscript-ok'))
                }
            }, {
                label: t('mainsetting.bill-cancel')
            }]
        });
    };

    const handleRedeemCode = () => {
        dispatch(addSubscriptionRedeemAsync({code: code}))
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y customer_profile_page">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">{t('mainsetting.nav-setting')} /</span> {t('mainsetting.nav-bill')}
                </h4>


                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-pills flex-column flex-md-row mb-3">
                            <li className="nav-item">
                                <Link to="/settings/account" className="nav-link"><BsPerson className="icon_size_18"/> {t('mainsetting.menu-account')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/settings/security" className="nav-link"><CgLock className="icon_size_18"/> {t('mainsetting.menu-security')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/settings/bill" className="nav-link active"><RiFilePaper2Line className="icon_white icon_size_18"/> {t('mainsetting.menu-bill')}</Link>
                            </li>
                            <li className="nav-item hide">
                                <Link to="/settings/notification" className="nav-link"><RiNotification4Line className="icon_size_18"/> {t('mainsetting.menu-noti')}</Link>
                            </li>
                        </ul>
                        {typeof profile?.expiredAt !== `undefined` && profile?.expiredAt !== null && profile?.isExpired === false && (
                            <div className="card mb-4">
                                <h5 className="card-header">{t('mainsetting.topic-bill-call')}</h5>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 mb-1">
                                            <div className="mb-4">
                                                <h6 className="fw-semibold mb-2">{t('mainsetting.topic-member-success')}</h6>
                                                <p>{t('mainsetting.topic-member-plan-is')} <span className="badge bg-label-primary package_font_badge">{profile?.currentPackage?.packageName}</span>
                                                    <span className="me-2">
                                                        <CurrencyFormat prefix={'฿'} displayType={'text'} value={profile?.currentPackage?.packagePrice ?? 0}/>
                                                        {/*<CurrencyFormat value="0" displayType={'text'} thousandSeparator={true} prefix={'฿'}/>*/}
                                                        /{t('mainsetting.topic-year')}</span>
                                                </p>
                                            </div>
                                            <div className="mb-4">
                                                <h6 className="fw-semibold mb-2">{t('mainsetting.topic-next-bill')} {moment(profile?.expiredAt).format(`YYYY-MM-DD`)}</h6>
                                                <p>{t('mainsetting.topic-alert-next-bill')}</p>
                                            </div>
                                            {/* <div className="mb-3">
                                                <h6 className="fw-semibold mb-2">
                                                    <span className="me-2">MALI MEMBERSHIP <CurrencyFormat value={profile?.currentPackage?.packagePrice ?? 0} displayType={'text'} thousandSeparator={true} prefix={'฿'}/>/ปี</span>

                                                </h6>
                                              <p>คุณให้เราดูแลข้อมูลมาแล้วมากกว่า 250 วัน</p>
                                            </div>*/}
                                        </div>
                                        <div className="col-md-6 mb-1">
                                            {/*<div className="alert alert-warning mb-4" role="alert">
                                            <h6 className="alert-heading mb-1">We need your attention!</h6>
                                            <span>Your plan requires update</span>
                                        </div>*/}
                                            <div className="plan-statistics">
                                                <div className="d-flex justify-content-between">
                                                    <span className="fw-semibold mb-2">{t('mainsetting.topic-day-countdown')}</span>
                                                    {/*<span className="fw-semibold mb-2">220 จาก 365 วัน</span>*/}
                                                </div>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        aria-valuenow="20"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                                <p className="mt-1 mb-0">{moment(profile?.expiredAt).diff(moment(), 'days')} {t('mainsetting.topic-date-expire')}</p>
                                            </div>
                                        </div>
                                        {/*<div className="col-12">
                                            <button onClick={() => ClickConfirmAlert()} className="btn btn-label-secondary cancel-subscription mt-2">Cancel Subscription</button>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        )}

                        {typeof profile?.expiredAt === `undefined` || profile?.expiredAt === null || profile?.isExpired === true && (
                            <div className="card mb-4">
                                <h5 className="card-header">{t('mainsetting.topic-bill-call-2')}</h5>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 mb-1">
                                            <div className="mb-4">
                                                <h6 className="fw-semibold mb-2">{t('mainsetting.topic-member-ship')}</h6>
                                                <p>{t('mainsetting.topic-member-you-not')} <span className="badge bg-label-secondary">MALI MEMBERSHIP</span>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="col-md-6 mb-1">
                                            {/*<div className="alert alert-warning mb-4" role="alert">
                                            <h6 className="alert-heading mb-1">We need your attention!</h6>
                                            <span>Your plan requires update</span>
                                        </div>*/}
                                        </div>
                                        <div className="col-12">
                                            <Link to="/price">
                                                <button
                                                    className="btn btn-primary mt-2 me-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#pricingModal"
                                                >
                                                    {t('mainsetting.topic-regis-membership')}
                                                </button>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="card mb-4">
                            <h5 className="card-header">REDEEM CODE</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row g-3">
                                            <div className="col-10">
                                                <label className="form-label w-100" htmlFor="paymentCard">{t('mainsetting.topic-redeem-input')}</label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        id="paymentCard"
                                                        name="paymentCard"
                                                        className="form-control credit-card-mask"
                                                        type="text"
                                                        value={code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                        placeholder={t('mainsetting.topic-redeem-input-example')}
                                                        aria-describedby="paymentCard2"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 mt-3" id={'redeem'}>
                                                <button onClick={() => handleRedeemCode()} type="submit" className="btn btn-primary me-1 me-sm-3">
                                                    {t('mainsetting.bill-ok')}
                                                </button>
                                                {/*<button type="button" onClick={() => handleRedeemCode()} className="btn btn-label-secondary">redeem</button>*/}
                                                <button type="reset" className="btn btn-label-secondary">{t('mainsetting.bill-cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card mb-4">
                            <h5 className="card-header">Billing Address</h5>
                            <div className="card-body">
                                <form id="formAccountSettings" onSubmit="return false">
                                    <div className="row">
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="companyName" className="form-label">Company Name</label>
                                            <input
                                                type="text"
                                                id="companyName"
                                                name="companyName"
                                                className="form-control"
                                                placeholder="PIXINVENT"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="billingEmail" className="form-label">Billing Email</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="billingEmail"
                                                name="billingEmail"
                                                placeholder="john.doe@example.com"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="taxId" className="form-label">Tax ID</label>
                                            <input
                                                type="text"
                                                id="taxId"
                                                name="taxId"
                                                className="form-control"
                                                placeholder="Enter Tax ID"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="vatNumber" className="form-label">VAT Number</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="vatNumber"
                                                name="vatNumber"
                                                placeholder="Enter VAT Number"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="mobileNumber" className="form-label">Mobile</label>
                                            <div className="input-group input-group-merge">
                                                <span className="input-group-text">US (+1)</span>
                                                <input
                                                    className="form-control mobile-number"
                                                    type="text"
                                                    id="mobileNumber"
                                                    name="mobileNumber"
                                                    placeholder="202 555 0111"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="country" className="form-label">Country</label>
                                            <select id="country" className="form-select select2" name="country">
                                                <option selected>USA</option>
                                                <option>Canada</option>
                                                <option>UK</option>
                                                <option>Germany</option>
                                                <option>France</option>
                                            </select>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label htmlFor="billingAddress" className="form-label">Billing Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="billingAddress"
                                                name="billingAddress"
                                                placeholder="Billing Address"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="state" className="form-label">State</label>
                                            <input className="form-control" type="text" id="state" name="state" placeholder="California"/>
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <label htmlFor="zipCode" className="form-label">Zip Code</label>
                                            <input
                                                type="text"
                                                className="form-control zip-code"
                                                id="zipCode"
                                                name="zipCode"
                                                placeholder="231465"
                                                maxLength="6"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <button type="submit" className="btn btn-primary me-2">Save changes</button>
                                        <button type="reset" className="btn btn-label-secondary">Discard</button>
                                    </div>
                                </form>
                            </div>
                        </div>*/}


                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsBill
