import CryptoJS from 'crypto-js';
import crypto from 'crypto-browserify'
import {Buffer} from 'buffer'

const privateKey = process.env.REACT_APP_PRIVATE_KEY
const ALGORITHM = 'aes-256-cbc';
const CIPHER_KEY = process.env.REACT_APP_PRIVATE_KEY;  // Same key used in Golang
const BLOCK_SIZE = 16;

export const encryptJson = (data) => {
    if (typeof data !== 'undefined') {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), privateKey).toString();
        return ciphertext
    }
}
export const decryptString = (cipherText) => {
    const contents = Buffer.from(cipherText, 'hex');
    const iv = contents.slice(0, BLOCK_SIZE);
    const textBytes = contents.slice(BLOCK_SIZE);

    const decipher = crypto.createDecipheriv(ALGORITHM, CIPHER_KEY, iv);
    let decrypted = decipher.update(textBytes, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted
}

export const decryptJson = (cipherText) => {

    // decrypted = decryptString(cipherText)
    return JSON.parse(decryptString(cipherText));

    // console.log(data)
    // if (typeof data !== 'undefined') {
    //
    //     console.log(data)
    //     var decodedString = atob(data);
    //     console.log(decodedString)
    //     const bytes = CryptoJS.AES.decrypt(decodedString, privateKey);
    //     console.log(bytes.toString())
    //     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // }
}
