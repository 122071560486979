import React from "react";
import bg_image from "../../../common/assets/img/cover/cover_write_memo_step5.gif";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../features/reducer/utility";
import {getProfile} from "../../../features/reducer/user/userSlice";
import moment from "moment";
import {useTranslation} from "react-i18next";

const RedeemBoxModal = ({guide}) => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const {t} = useTranslation();


    return (
        <>
            <div className="card-body text-center">
                <img className="image_redeem_cover" src={bg_image}/>
                <h5>{t('mainsetting.redeem-congret-welcome')}</h5>
                <div className="mb-4">{t('mainsetting.redeem-countdown-date-membership')} {moment(profile?.expiredAt).format(`YYYY-MM-DD`)}</div>
                <div className="option_button">
                    <button type="button" onClick={() => dispatch(setModal(false))} className="btn btn-primary">{t('mainsetting.redeem-modal-confirm')}</button>
                </div>
            </div>
        </>
    )
}

export default RedeemBoxModal
