import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import banner_faq from "../../../common/assets/img/illustrations/boy-working-light.png";
import {Accordion, Col, Nav, Row, Tab} from "react-bootstrap";
import {RiSearchLine,RiQuestionMark} from "react-icons/ri"
import {BsTelephone} from "react-icons/bs"
import {VscMail} from "react-icons/vsc"
import {useTranslation} from "react-i18next";
import faq_icon_sayhi from  "../../../common/assets/img/icon_gif/faq_icon_sayhi.gif";

const Faq = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: `faq`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">{t('faq.page-nav-title-1')} /</span> {t('faq.page-nav-title-2')}
                </h4>
                <div className="faq-header d-flex flex-column justify-content-center align-items-center margin_bottom_25px">
                    <h3 className="zindex-1 text-center">{t('faq.text-title-mali-hello')}</h3>
                    {/*} <div className="input-wrapper input-group input-group-merge zindex-1 my-3">
                        <span className="input-group-text" id="basic-addon1">
                            <RiSearchLine className="bx bx-search-alt bx-xs text-muted"></RiSearchLine>
                        </span>
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={t('faq.text-search-questions')}
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    <p className="zindex-1 text-center text-body px-3 mb-0">
                        หรือเลือกตามหมวดหมู่ที่คุณต้องการได้จากหัวข้อด้านล่างนี้
                    </p>{*/}
                </div>


                <Tab.Container id="left-tabs-example" defaultActiveKey="payment">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="payment">{t('faq.text-faq-green-backgound')}</Nav.Link>
                                </Nav.Item>
                                {/*}
                                <Nav.Item>
                                    <Nav.Link eventKey="delivery">ข้อมูล Memo</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="cancellation">ผู้รับเจตนา (Receiver)</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="orders">ระบบตรวจสอบการคงอยู่ (DeathTrigger)</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="product">Product & Services</Nav.Link>
                                </Nav.Item>{*/}
                            </Nav>
                            <div className="d-none d-md-block">
                                <div className="mt-5">
                                    <img
                                        src={banner_faq}
                                        className="img-fluid scaleX-n1"
                                        alt="FAQ Image"
                                        data-app-light-img="illustrations/boy-working-light.png"
                                        data-app-dark-img="illustrations/boy-working-dark.png"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="payment">
                                    <div className="gap-3 d-flex mb-3">
                                        <div>
                                            <span className="badge bg-label-mali rounded-2 p-2">
                                                <RiQuestionMark className="bx bx-credit-card fs-3 lh-1"></RiQuestionMark>
                                            </span>
                                        </div>
                                        <div>
                                            <h5 className="mb-0">
                                                <span className="align-middle">{t('faq.text-title-faq')}</span>
                                            </h5>
                                            <span>{t('faq.text-bottom-general-questions')}</span>
                                        </div>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item className="mb-2" eventKey="0">
                                            <Accordion.Header>{t('faq.text-title-section-1')}</Accordion.Header>
                                            <Accordion.Body>
                                                {t('faq.text-detail-of-section-1')}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-2" eventKey="1">
                                            <Accordion.Header>{t('faq.text-title-section-2')}</Accordion.Header>
                                            <Accordion.Body>
                                                {t('faq.text-detail-of-section-2')}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item className="mb-2" eventKey="2">
                                            <Accordion.Header>{t('faq.text-title-section-3')}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t('faq.text-detail-of-section-3')}</p>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item className="mb-2" eventKey="3">
                                            <Accordion.Header>{t('faq.text-title-section-4')}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t('faq.text-detail-section-4')}</p>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item className="mb-2" eventKey="4">
                                            <Accordion.Header>{t('faq.text-title-section-5')}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t('faq.text-detail-section-5')}</p>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item className="mb-2" eventKey="5">
                                            <Accordion.Header>{t('faq.text-title-section-6')}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t('faq.text-detail-section-6')}</p>

                                            </Accordion.Body>
                                        </Accordion.Item>



                                    </Accordion>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <div className="row mt-5">
                    <div className="col-12 text-center mb-4">
                        <div className="badge bg-label-mali">{t('faq.text-question-small-box')}</div>
                        <h4 className="my-2">{t('faq.text-bottom-dyhq')}</h4>
                        <p>
                            {t('faq.text-detail-of-dyhq')}
                        </p>
                    </div>
                </div>
                <div className="row gap-sm-0 gap-3 justify-content-center text-center">
                    <div className="col-sm-6">
                        <div className="bg-faq-section rounded text-center py-3">
                            <span className="badge bg-label-mali rounded-3 p-2 my-3">
                                <BsTelephone className="bx bx-phone bx-sm"></BsTelephone>
                            </span>
                            <h4 className="mb-2"><a className="h4" href="tel:+(810)25482568">+ (66) 84-790-9941</a></h4>
                            <p>{t('faq.text-bottom-open-day')}</p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="bg-faq-section rounded text-center py-3">
                            <span className="badge bg-label-mali rounded-3 p-2 my-3">
                                <VscMail className="bx bx-envelope bx-sm"></VscMail>
                            </span>
                            <h4 className="mb-2"><a className="h4" href="mailto:help@ma-li.io">help@ma-li.io</a></h4>
                            <p>{t('faq.text-bottom-way-response')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq
