import React from "react";
import {useSelector} from "react-redux";
import {loading} from "../../features/reducer/utility";
import {useTranslation} from "react-i18next";

const SubmitButton = ({handleClick, text, className, children}) => {

    const {t} = useTranslation();
    const isLoading = useSelector(loading)
    return (
        <button onClick={e => handleClick(e)} disabled={isLoading === `LOADING`} className={`btn btn-primary ${typeof className !== `undefined` ? className : ``}`}>
            {isLoading === `LOADING` ? t('etc.btn-wait') : (children ? children : text)}
        </button>
    )
}

export default SubmitButton
