import ImageUserProfile from "../../utility/ImageUserProfile";
import ReactTooltip from "react-tooltip";
import {ContactType} from "../../../common/config/app";
import React, {useEffect, useState} from "react";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {useDispatch} from "react-redux";
import {addAsyncContactByUsername} from "../../../features/reducer/contact/contactThunk";
import {useTranslation} from "react-i18next";

const ReceiverAddBySearch = ({searchResult, resetSearchResult}) => {
    const dispatch = useDispatch()
    const [user, setUser] = useState()
    const {t} = useTranslation();


    useEffect(() => {
        setUser(searchResult)
    }, [searchResult]);

    const handleSetContactType = (e) => {
        const {checked, value} = e.target
        setUser({
            ...user,
            uType: checked ? value : ""
        })
    }

    const handleSubmit = () => {
        if (typeof user?.uType === `undefined` || user.uType === "") {
            notificationErrorCustomMessage(t('addreceiver.alert-choose-receiver'))
            return
        }
        let u = {
            id: user.id,
            username: user.username,
            type: user.uType
        }
        dispatch(addAsyncContactByUsername(u))
    }

    return (
        <>
            <div className="form-check custom-option custom-option-icon border_acive_theme mt-3">
                <div className="card-body text-center">
                    <div className="d-flex justify-content-center mb-3">
                        <ImageUserProfile user={user}/>
                    </div>
                    <h5 className="card-title mb-1">{user?.firstName} {user?.lastName}</h5>
                    <span>{user?.nickName}</span>
                </div>
            </div>

            <div className="mt-3">
                <label className="form-label" htmlFor="modalEditUserPhone">{t('addreceiver.text-choose-receiver')}</label>
                <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                <div className="row">
                    {ContactType.map(type => (
                        <div className="col-md mb-2 mb-md-0">
                            <div className="form-check custom-option custom-option-icon border_acive_theme">
                                <label className="form-check-label custom-option-content" htmlFor={type.type}>
                                    <span className="custom-option-body">
                                        <div className="mb-1">{type.icon}</div>
                                        <div className={`badge mb-1 rounded-pill ${type.className}`} data-tip={`ระบุอยู่ในกลุ่ม ${type.name}`}>{type.type}</div>
                                        <div><small>{type.name}</small></div>
                                    </span>
                                    <input
                                        name="customRadioIcon"
                                        className="form-check-input"
                                        type="radio"
                                        value={type.type}
                                        onClick={e => handleSetContactType(e)}
                                        id={type.type}
                                        checked={user?.uType == type.type}
                                    />
                                </label>
                            </div>
                        </div>
                    ))}


                </div>
            </div>

            <div className="mt-4 text-center">
                <button type="button" onClick={() => handleSubmit()} className="btn btn-primary me-1 me-sm-3">{t('addreceiver.text-confirm-page')}</button>

                <button
                    type="button"
                    onClick={resetSearchResult}
                    className="btn btn-label-secondary"
                    aria-label={t('addreceiver.text-cancel-page')}
                >
                    {t('addreceiver.text-cancel-page')}
                </button>
            </div>
        </>
    )
}
export default ReceiverAddBySearch
