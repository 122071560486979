import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {Col, Row, Tab} from "react-bootstrap";
import banner_faq from "../../../common/assets/img/explan/men_tel_walk.png";
import {NotificationManager} from "react-light-notifications";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {getSelectPackages, subscribeState} from "../../../features/reducer/subscribe/subscribeSlice";
import moment from "moment";
import DatePicker from "react-datepicker";
import {addSubscription} from "../../../features/reducer/subscribe/subscribeThunk";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PaymentBank = () => {
    const dispatch = useDispatch()
    const subscribe = useSelector(subscribeState)
    const myPackage = useSelector(getSelectPackages)
    const navigate = useNavigate();
    const inputRef = useRef();
    const {t} = useTranslation();
    const [data, setData] = useState({
        date: new Date()
    })
    const [errors, setErrors] = useState({})
    // const [image, setImage] = useState()

    useEffect(() => {
        dispatch(activeMenu({menu: `price`}))
    }, [dispatch])

    useEffect(() => {
        if (typeof myPackage === `undefined` || myPackage === null) {
            navigate(`/price`)
        }
    }, [myPackage])

    useEffect(() => {
        if (subscribe.submitAction == 1 || subscribe.submitAction == 2) {
            onReset()
        }
    }, [subscribe.submitAction])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleInput = e => {
        const {name, value} = e.target
        setErrors({
            ...errors,
            [name]: false
        })
        setData({...data, [name]: value})
    }

    const handleInputDate = (value, name) => {
        setErrors({
            ...errors,
            [name]: false
        })
        setData({...data, [name]: value})
    }

    const handleFileChange = (event, name = `file`, size = (1024 * 5)) => {
        let files = event.target.files;

        setErrors({
            ...errors,
            [name]: false
        })

        if (typeof files[0]?.size === 'undefined') {
            setData({
                ...data,
                [name]: ``
            })
            return false
        }
        if (files[0].size / 1024 > size) {
            // NotificationManager.warning({
            //     title: "แจ้งเตือน",
            //     message: "ขนาดไฟล์ใหญ่เกินกว่าที่กำหนด",
            // });
            notificationErrorCustomMessage(t('payment.maximum-file-limit'), `warning`)
            setData({
                ...data,
                [name]: ``
            })
            return false
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setData({
                ...data,
                [name]: e.target.result
            })
        }
    }

    const onReset = () => {
        setData({})
        inputRef.current.value = ``
    }

    const sendSubmit = () => {
        let dt = {...data, code: myPackage.packageCode}
        const required = ['file', 'date', 'time'];
        let error = {...errors}, hasError = false;
        required.map(v => {
            if (typeof dt[v] === `undefined` || dt[v] === ``) {
                error[v] = true
                hasError = true
            }
        })
        if (hasError) {
            notificationErrorCustomMessage(t('payment.bank-alert-input-all'), `info`)
            setErrors(error)
            return
        }
        dt.date = moment(dt.date).format(`YYYY-MM-DD`)
        dt.date = moment(`${dt.date} ${dt.time}`).format(`YYYY-MM-DD HH:mm:ss`)
        // console.log(dt)
        dispatch(addSubscription(dt))
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="faq-header d-flex flex-column justify-content-center align-items-center margin_bottom_25px">
                    <h3 className="zindex-1 text-center">{t('payment.bank-topic-bank-transfer')}</h3>

                    <p className="zindex-1 text-center text-body px-3 mb-0">
                        {t('payment.bank-topic-bank-transfer-detail')}
                    </p>
                </div>


                <Tab.Container id="left-tabs-example" defaultActiveKey="payment">
                    <Row>
                        <Col sm={3}>
                            <div className="d-none d-md-block">
                                <div className="mt-5">
                                    <img
                                        src={banner_faq}
                                        className="img-fluid"
                                        alt="FAQ Image"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={9}>
                            <div className="bg_white  mb-4">
                                <h5 className="card-header">{t('payment.bank-topic-form-noti-price')}</h5>
                                <hr className="my-0"/>
                                <div className="card-body">
                                    <form id="formAccountSettings" method="POST" onSubmit="return false">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className={`form-group ${errors?.file && `has-error`}`}>
                                                    <label htmlFor="file" className="form-label">{t('payment.bank-topic-slip-attach')}</label>
                                                    <input
                                                        ref={inputRef}
                                                        className="form-control"
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={e => handleFileChange(e)}
                                                        autoFocus
                                                    />
                                                    <div className="fv-plugins-message-container invalid-feedback">
                                                        {errors?.file ? t('payment.bank-alert-please-input-all-2') : ``}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`form-group ${errors?.date && `has-error`}`}>
                                                    <label htmlFor="date" className="form-label">{t('payment.bank-topic-date-transfer')} <span className="text-danger">*</span></label>
                                                    <DatePicker dateFormat="yyyy-MM-dd" placeholderText={t('payment.bank-title-choose-date')} selected={data?.date} className="form-control" onChange={(date) => handleInputDate(date, `date`)}/>
                                                    {/*<input className="form-control"*/}
                                                    {/*       type="text"*/}
                                                    {/*       name="date"*/}
                                                    {/*       id="date"*/}
                                                    {/*       onChange={e => handleInput(e)}*/}
                                                    {/*       value={data?.date ?? ''}*/}
                                                    {/*/>*/}
                                                    <div className="fv-plugins-message-container invalid-feedback">
                                                        {errors?.date ? t('payment.bank-alert-please-input-all-2') : ``}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className={`form-group ${errors?.time && `has-error`}`}>
                                                    <label htmlFor="lastName" className="form-label">{t('payment.bank-topic-date-time')} <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="time"
                                                        name="time"
                                                        onChange={e => handleInput(e)}
                                                        value={data?.time ?? ''}
                                                    />
                                                    <div className="fv-plugins-message-container invalid-feedback">
                                                        {errors?.time ? t('payment.bank-alert-please-input-all-2') : ``}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button type="button" onClick={() => sendSubmit()} className="btn btn-primary me-2">{t('payment.bank-btn-send')}</button>
                                            <button type="button" onClick={() => onReset()} className="btn btn-label-secondary">{t('payment.bank-btn-cancel')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}

export default PaymentBank
