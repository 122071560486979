import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {Col, Row, Tab} from "react-bootstrap";
import banner_faq from "../../../common/assets/img/explan/sit_typing.png";
import {BsTelephone} from "react-icons/bs";
import {VscMail} from "react-icons/vsc";
import {getContactData, resetContact, setContact} from "../../../features/reducer/email/emailSlice";
import {NotificationManager} from "react-light-notifications";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {sendContactAsyncEmail, sendReportAsyncEmail} from "../../../features/reducer/email/emailThunk";
import {useTranslation} from "react-i18next";

const ReportIssue = () => {
    const dispatch = useDispatch()
    const data = useSelector(getContactData)
    const inputRef = useRef();
    const {t} = useTranslation();


    useEffect(() => {
        dispatch(activeMenu({menu: `issue`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (typeof data?.file !== `undefined` && data?.file == null) {
            // inputRef.current.value = ``
        }
    }, [data])

    const handleInput = e => {
        const {name, value} = e.target
        dispatch(setContact({name: name, value: value}))
    }

    const handleFileChange = (event, name = `file`, size = (1024 * 5)) => {
        let files = event.target.files;
        if (typeof files[0]?.size === 'undefined') {
            dispatch(setContact({name: name, value: null}))
            return false
        }
        if (files[0].size / 1024 > size) {
            // NotificationManager.warning({
            //     title: "แจ้งเตือน",
            //     message: "ขนาดไฟล์ใหญ่เกินกว่าที่กำหนด",
            // });
            notificationErrorCustomMessage(t('etc.noti-file-max-size'), `warning`)
            dispatch(setContact({name: name, value: null}))
            return false
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            dispatch(setContact({name: name, value: e.target.result}))
        }
    }

    const onReset = () => {
        dispatch(resetContact())
    }

    const sendSubmit = () => {
        let dt = {...data}
        // console.log(dt)
        const required = ['firstName', 'lastName', 'subject', 'detail', 'email', 'phone'];
        let errors = false
        required.map(v => {
            if (typeof dt[v] === `undefined` || dt[v] === ``) {
                errors = true
            }
        })
        if (errors) {
            notificationErrorCustomMessage(t('etc.alert-please-input-all-star'))
            return
        }

        // dispatch(setContact(dt))
        dispatch(sendReportAsyncEmail(dt))
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="faq-header d-flex flex-column justify-content-center align-items-center margin_bottom_25px">
                    <h3 className="zindex-1 text-center">{t('reportaproblem.header-topic-report-problem')}</h3>

                    <p className="zindex-1 text-center text-body px-3 mb-0">
                        {t('reportaproblem.detail-of-report-problem')}
                    </p>
                </div>


                <Tab.Container id="left-tabs-example" defaultActiveKey="payment">
                    <Row>
                        <Col sm={3}>
                            <div className="d-none d-md-block">
                                <div className="mt-5">
                                    <img
                                        src={banner_faq}
                                        className="img-fluid"
                                        alt="FAQ Image"
                                        data-app-light-img="illustrations/boy-working-light.png"
                                        data-app-dark-img="illustrations/boy-working-dark.png"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={9}>
                            <div className="bg_white  mb-4">
                                <h5 className="card-header">{t('reportaproblem.title-problem-report-form')}</h5>
                                <hr className="my-0"/>
                                <div className="card-body">
                                    <form id="formAccountSettings" method="POST" onSubmit="return false">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="subject" className="form-label">{t('reportaproblem.text-title-problems-encountered')} <span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="subject"
                                                    name="subject"
                                                    onChange={e => handleInput(e)}
                                                    value={data?.subject ?? ''}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="detail" className="form-label">{t('reportaproblem.text-title-details')} <span className="text-danger">*</span></label>
                                                <textarea
                                                    className="form-control textarea_hight"
                                                    type="text"
                                                    id="detail"
                                                    name="detail"
                                                    onChange={e => handleInput(e)}
                                                    value={data?.detail ?? ''}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="file" className="form-label">{t('reportaproblem.text-title-picture')}</label>
                                                <input
                                                    ref={inputRef}
                                                    className="form-control"
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    onChange={e => handleFileChange(e)}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="firstName" className="form-label">{t('reportaproblem.text-title-first-name')} <span className="text-danger">*</span></label>
                                                <input className="form-control"
                                                       type="text"
                                                       name="firstName"
                                                       id="firstName"
                                                       onChange={e => handleInput(e)}
                                                       value={data?.firstName ?? ''}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="lastName" className="form-label">{t('reportaproblem.text-title-last-name')} <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    name="lastName"
                                                    onChange={e => handleInput(e)}
                                                    value={data?.lastName ?? ''}
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="email" className="form-label">{t('reportaproblem.text-title-email')} <span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    onChange={e => handleInput(e)}
                                                    value={data?.email ?? ''}
                                                    placeholder=""
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label className="form-label" htmlFor="phone">{t('reportaproblem.text-title-send')} <span className="text-danger">*</span></label>
                                                <div className="input-group input-group-merge">
                                                    <span className="input-group-text">TH (+66)</span>
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        name="phone"
                                                        onChange={e => handleInput(e)}
                                                        value={data?.phone ?? ''}
                                                        className="form-control"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button type="button" onClick={() => sendSubmit()} className="btn btn-primary me-2">{t('reportaproblem.text-button-send')}</button>
                                            <button type="button" onClick={() => onReset()} className="btn btn-label-secondary">{t('reportaproblem.text-button-cancel')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
                <div className="row gap-sm-0 gap-3 justify-content-center text-center">
                    <div className="col-sm-6">
                        <div className="bg-faq-section rounded text-center py-3">
                            <span className="badge bg-label-mali rounded-3 p-2 my-3">
                                <BsTelephone className="bx bx-phone bx-sm"></BsTelephone>
                            </span>
                            <h4 className="mb-2"><a className="h4" href="tel:+(810)25482568">+ (66) 84-790-9941</a></h4>
                            <p>{t('reportaproblem.text-bottom-open-day')}</p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="bg-faq-section rounded text-center py-3">
                            <span className="badge bg-label-mali rounded-3 p-2 my-3">
                                <VscMail className="bx bx-envelope bx-sm"></VscMail>
                            </span>
                            <h4 className="mb-2"><a className="h4" href="mailto:help@ma-li.io">natthapon.b@ma-li.io</a></h4>
                            <p>{t('reportaproblem.text-bottom-way-response')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportIssue
