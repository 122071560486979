import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import logo from "../../../common/assets/img/mali/icon_app.png";
import Iframe from "react-iframe";
import {useTranslation} from "react-i18next";

const Rule = () => {
    const dispatch = useDispatch()

    const {t} = useTranslation();

    useEffect(() => {
        dispatch(activeMenu({menu: `rule`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <><div className="container-xxl container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">{t('rule.page-nav-title-1')} /</span> {t('rule.page-nav-title-2')}
            </h4>
            <div className="row invoice-edit">
                <div className="col-12 col-lg-12 mb-4 mb-lg-0">
                    <div className="card border h-100 margin-bottom-10">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center me-3">
                                <img src={logo} alt="Avatar" className="rounded-circle me-3" width="54"/>
                                <div className="card-title mb-0">
                                    <h5 className="mb-0">{t('rule.page-nav-title-2')}</h5>
                                    <small className="text-muted"></small>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <Iframe url="https://ma-li.io/termsofservice_frame.php"
                                    className="frame_about"
                                    display="initial"
                                    position="relative"/>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default Rule
