import {createSlice} from "@reduxjs/toolkit";
import {
    addAsyncWidgetJsonMemo,
    fetchAsyncMyMemo,
    fetchAsyncReceiverMemo,
    getAsyncMemo, getMemoGuideAsync, getMyMemoGuideAsync,
    updateAsyncMemo
} from "./memoThunk";
import {decryptJson} from "../../../common/functions/encrypt";


const mockWidget = {
    type: ``, message: ``, data: {
        message: ``, file: {name: ``, base64: ``},
        link: ``,
        password: {title: ``, url: ``, username: ``, password: ``},
        fileInput: null
    }, sq: 0, memoId: 0,
}

const initialState = {
    step: `receiver`,
    memoGuide: [],
    myMemoGuide: [],
    items: {
        data: [],
    },
    writeMemo: {
        id: 0,
        receiver: [],
        widgets: [],
    },
    receiverName: [],
    dataWriteMemo: {
        id: 0,
        progress: 0,
        data: [],
    },
    widgets: [],
    dataSetting: {
        title: ``, type: ``, deathTrigger: 0, kycRequired: 1, receiverAlert: 1, memoStatus: `draft`
    },
    myMemoList: {
        data: []
    },
}

const memoSlice = createSlice({
    name: `memo`, initialState: initialState, reducers: {
        setStep: (state, {payload}) => {
            return {...state, step: payload}
        },
        setWidget: (state, {payload}) => {
            // console.log(payload)
            return {
                ...state,
                widgets: payload,
                writeMemo: {
                    ...state.writeMemo, widgets: payload
                }
            }
        },
        setDataSettingInput: (state, {payload}) => {
            return {
                ...state,
                dataSetting: {...state.dataSetting, [payload.name]: payload.value},
            }
        },
        setDataSetting: (state, {payload}) => {
            return {
                ...state,
                dataSetting: payload,
                writeMemo: {
                    ...state.writeMemo,
                    data: {
                        ...state.writeMemo.data,
                        title: payload.title,
                        type: payload.type,
                        deathTriggerAlert: payload.deathTrigger,
                        kycRequired: payload.kycRequired,
                        receiverAlert: payload.receiverAlert,
                        memoStatus: payload.memoStatus,
                    }
                }
            }
        },
        setWriteMemoReceiver: (state, {payload}) => {
            return {
                ...state,
                dataSetting: {
                    ...state.dataSetting,
                    receiver: payload
                },
                writeMemo: {
                    ...state.writeMemo, receiver: payload
                }
            }
        },
        setWriteMemoWidget: (state, {payload}) => {
            return {
                ...state,
                widgets: payload,
                writeMemo: {
                    ...state.writeMemo, widgets: payload
                }
            }
        },
        setDataWriteMemo: (state, {payload}) => {
            return {
                ...state,
                dataWriteMemo: {
                    ...state.dataWriteMemo,
                    data: payload,
                }
            }
        },
        setDataWriteMemoProgress: (state, {payload}) => {
            return {
                ...state, dataWriteMemo: {
                    ...state.dataWriteMemo,
                    progress: payload.progress,
                    id: typeof payload.id === `undefined` ? state.dataWriteMemo.id : payload.id,
                }
            }
        },
        setWidgetAfterSave: (state, {payload}) => {
            return {...state, writeMemo: setWidgetPayload(payload)}
        },
        resetWriteMemo: (state, {payload}) => {
            return {
                ...state, writeMemo: {
                    id: 0,
                    receiver: [],
                    widgets: [],
                }
            }
        },
        setReceiverName: (state, {payload}) => {
            return {...state, receiverName: payload}
        },

    }, extraReducers: {
        [fetchAsyncReceiverMemo.fulfilled]: (state, {payload}) => {
            return {...state, items: payload}
        },
        [fetchAsyncMyMemo.fulfilled]: (state, {payload}) => {
            return {...state, myMemoList: payload}
        },

        [getAsyncMemo.fulfilled]: (state, {payload}) => {
            // console.log(decryptJson(payload))
            const data = setWidgetPayload(decryptJson(payload))
            console.log(data)
            return {
                ...state,
                widgets: data?.widgets,
                writeMemo: data,
                dataSetting: {
                    ...state.dataSetting,
                    id: payload.id,
                    title: payload.title,
                    type: payload.type,
                    deathTrigger: payload.deathTriggerAlert,
                    kycRequired: payload.kycRequired,
                    receiverAlert: payload.receiverAlert,
                    memoStatus: payload.memoStatus,
                },
            }
        },
        [updateAsyncMemo.fulfilled]: (state, {payload}) => {
            const data = setWidgetPayload(payload)
            // console.log(data)
            return {...state, writeMemo: data}
        },
        [getMemoGuideAsync.fulfilled]: (state, {payload}) => {
            return {...state, memoGuide: payload}
        },
        [getMyMemoGuideAsync.fulfilled]: (state, {payload}) => {
            return {...state, myMemoGuide: payload}
        },
    },
})

const setWidgetPayload = (payload) => {
    let receivers = []
    payload?.receiver.map(v => {
        receivers.push(v.id)
    })
    let widgets = []

    let contents = payload?.contents.sort((a, b) => a.sequence - b.sequence)

    // console.log(payload)
    contents.map(v => {
        let dt = {
            ...mockWidget,
            id: v?.contentId,
            type: v?.majorType,
            sq: v?.sequence ?? 0,
            memoId: v?.memoId,
            data: {
                ...mockWidget.data,
                file: {
                    name: v?.fileName,
                    base64: `${process.env.REACT_APP_API_BASE}/media/${v?.content}`
                },

            },
        }
        if (v.majorType == `message` || v.majorType == `link`) {
            dt.message = v.content
        }

        if (v.majorType == `password`) {
            dt.data = {
                ...dt.data,
                password: {
                    title: v?.content?.title,
                    url: v?.content?.url,
                    username: v?.content?.username,
                    password: v?.content?.password
                },
            }
        }

        widgets.push(dt)
    })
    return {
        id: payload.id,
        receiver: receivers,
        widgets: widgets,
        data: payload
    }
}

export const getReceiverMemo = (state) => state.memo.items
export const getWriteMemo = (state) => state.memo.writeMemo
export const getDataWriteMemo = (state) => state.memo.dataWriteMemo
export const getReceiverName = (state) => state.memo.receiverName
export const getMemoGuides = (state) => state.memo.memoGuide
export const getMyMemoGuides = (state) => state.memo.myMemoGuide
export const memoState = (state) => state.memo
export const {
    setStep,
    setWriteMemoReceiver,
    setWriteMemoWidget,
    resetWriteMemo,
    setDataWriteMemo,
    setDataWriteMemoProgress,
    setWidgetAfterSave,
    setWidget,
    setDataSetting,
    setDataSettingInput,
    setReceiverName
} = memoSlice.actions

export default memoSlice.reducer
