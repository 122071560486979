import httpClient, {httpObjQueryString, httpClientForm} from "../config/httpClient";
import {convertJsonToFormData} from "../functions/usages";
import {getMyMemoGuides} from "../../features/reducer/memo/memoSlice";


export const fetchMemoByReceiverApi = (filter) => {
    const f = {
        page: filter.page,
        pageSize: filter.pageSize
    }
    return httpClient.get(`/memo/${filter.id}/receiver?${httpObjQueryString(f)}`)
}
export const fetchMyMemoByApi = (filter) => {
    return httpClient.get(`/memo/me?${httpObjQueryString(filter)}`)
}
export const getMemoApi = (id) => {
    return httpClient.get(`/memo/${id}`)
}

export const updateMemoApi = (data) => {
    return httpClient.put(`/memo/${data.id}`, data)
}

export const deleteMemoApi = id => {
    return httpClient.delete(`/memo/${id}`)
}

export const addWidgetApi = (data) => {
    return httpClient.post(`/memo/widget`, data)
}
export const addWidgetJsonApi = (data) => {
    // if (data?.mode == `formValue`) {
    // const params = httpObjQueryString(data)
    return httpClientForm.post(`/memo/widget/json?mode=formValue`, data)
    // } else {
    //     return httpClient.post(`/memo/widget/json?mode=json`, data)
    // }
}

export const deleteWidgetApi = id => {
    return httpClientForm.delete(`/memo/widget/${id}`)
}


export const getMemoGuideApi = () => {
    return httpClient.get(`/memo/guide`)
}

export const getMyMemoGuideApi = () => {
    return httpClient.get(`/memo/myguide`)
}


// export const addMemoApi = (data) => {
//     const dt = {...data}
//     dt.widgets = dt.widgets.map(item => {
//         if (item.majorType == `image` || item.majorType == `video` || item.majorType == `video`) {
//             delete item.content
//         }
//         return item
//     })
//     const params = convertJsonToFormData(dt)
//     return httpClientForm.post(`/memo/add`, params)
// }

export const addMemoApi = (data) => {
    return httpClient.post(`/memo/add`, data)
}


export const addLogGuideMemoApi = (data) => {
    return httpClient.post(`/logguide`, data)
}
