import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setisAuthenticated, tooManyLogin} from "../../features/reducer/user/userSlice";
import {Alert} from "@mui/material";
import {loginAsyncUser} from "../../features/reducer/user/userThunk";

import '../../common/assets/vendor/css/pages/page-auth.css'
import imgLoginLight from '../../common/assets/img/explan/send_gift.png'
import imgLogoSmall from '../../common/assets/img/mali/logo_small.png'
import {Link, useNavigate} from "react-router-dom";
import SubmitButton from "../utility/SubmitButton";
import {redirect, setRedirect} from "../../features/reducer/utility";
import localStorageService from "../../services/localStorageService";
import {useTranslation} from "react-i18next";

function Login() {
    const {i18n} = useTranslation()
    const dispatch = useDispatch()
    const localStorage = localStorageService.getService()
    const tooManyLogined = useSelector(tooManyLogin)
    const [data, setData] = useState({
        username: ``, password: ``
    })
    const [lang, setLang] = useState(i18n.language)
    const [errors, setErrors] = useState({})
    const isRedirect = useSelector(redirect)
    let navigate = useNavigate();
    const {t} = useTranslation();

    localStorage.setWelcome(0);

    useEffect(() => {
        if (typeof i18n.language != `undefined`) {
            setLang(i18n.language)
        }
    }, [i18n.language]);

    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            dispatch(setisAuthenticated(true))
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [isRedirect])

    const handleInput = (e) => {
        const {name, value} = e.target
        setErrors({
            ...errors,
            [name]: false
        })
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let hasError = false
        let err = {...errors}
        if (data.username == ``) {
            // setErrors({...errors, username: true})
            err.username = true
            hasError = true
        }

        if (data.password == ``) {
            // setErrors({...errors, password: true})
            err.password = true
            hasError = true
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.username))) {
            err.username = true
            hasError = true
        }
        if (hasError) {
            setErrors(err)
            return
        }

        dispatch(loginAsyncUser(data))
    }


    const changeLanguageHandle = (e, lang) => {
        e.preventDefault()
        setLang(lang)
        i18n.changeLanguage(lang)

    }
    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
                        <div className="flex-row text-center mx-auto">
                            <img src={imgLoginLight} alt="Auth Cover Bg color" width="520" className="img-fluid authentication-cover-img"/>
                            <div className="mx-auto">
                                <h3>{t('login.header-title-left')}</h3>
                                <p>
                                    {t('login.bottom-title-left')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="authentication-bg d-flex col-12 col-lg-5 col-xl-4 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="/" className="app-brand-link gap-2 mb-2">
                                    <img width="40%" src={imgLogoSmall}/>
                                </a>
                            </div>
                            <h4 className="mb-2">{t('login.header-title-right')}</h4>
                            <p className="mb-4">{t('login.bottom-title-right')}</p>

                            {tooManyLogined && (
                                <Alert severity="error" className="mb-4">{t('login.alert-input-fail-delay')}</Alert>
                            )}

                            <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" method="POST" noValidate="novalidate">
                                <div className={`mb-3 fv-plugins-icon-container ${errors?.username ? `has-error` : ``}`}>
                                    <label htmlFor="email" className="form-label">{t('login.title-login-top')}</label>
                                    <input type="text" className="form-control" id="email" name="username" onChange={e => handleInput(e)} autoComplete="new-password" placeholder="" autoFocus=""/>
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors?.username ? t('login.alert-please-input-all-mail') : ``}
                                    </div>
                                </div>
                                <div className={`form-password-toggle mb-3 fv-plugins-icon-container  ${errors?.password ? `has-error` : ``}`}>
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">{t('login.title-password-top')}</label>
                                        <Link to="/forgotpassword"><small>{t('login.text-forgot-password')}</small></Link>
                                    </div>
                                    <div className="input-group input-group-merge has-validation">
                                        <input type="password" id="password" className="form-control" name="password" onChange={e => handleInput(e)} autoComplete="new-password" placeholder="············" aria-describedby="password"/>
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors?.password ? t('login.alert-please-input-all') : ``}
                                    </div>
                                </div>
                                <br></br>

                                {/*}
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="remember-me"/>
                                        <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                                    </div>
                                </div>
                                {*/}
                                {/*<button className="btn btn-primary d-grid w-100">Sign in</button>*/}
                                {/*<button onClick={e => handleSubmit(e)} disabled={submited} className="btn btn-primary d-grid w-100">*/}
                                {/*    {submited ? 'Login...' : 'Sign In'}*/}
                                {/*</button>*/}
                                <SubmitButton handleClick={handleSubmit} className={`btn btn-primary d-grid w-100`}>{t('login.text-login-button')}</SubmitButton>
                                <div></div>
                                <input type="hidden"/>
                            </form>

                            <p className="text-center">
                                <span>{t('login.text-ask-new-user')} </span>
                                <Link to={"/register"}>
                                    <span>{t('login.text-click-here')}</span>
                                </Link>
                            </p>

                            <p className="text-center">
                                {(lang == `en` || lang == `en-GB` || lang == `en-US`) ? (
                                    <>
                                        <span>English / </span>
                                        <a href="/" onClick={e => changeLanguageHandle(e, 'th')}>ภาษาไทย</a>
                                    </>
                                ) : (
                                    <>
                                        <a href="/" onClick={e => changeLanguageHandle(e, 'en')}>English </a> / <span>ภาษาไทย</span>
                                    </>
                                )}
                            </p>


                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Login;
