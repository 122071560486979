import logo from "../../../../common/assets/img/mali/icon_app.png";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getMyMemoGuides,getMemoGuides} from "../../../../features/reducer/memo/memoSlice";
import MemoTypeTag from "../../../utility/MemoTypeTag";

const MemoNotifications = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch()
    const [showMemoGuide, setShowMemoGuide] = useState(false)
    const queryParams = new URLSearchParams(window.location.search)
    const [guideCodeParams] = useState(queryParams.get("guide"))
    const guides = useSelector(getMyMemoGuides)
    const [lang, setLang] = useState(i18n.language)

   // alert(guides?.length);

    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language]);


    const handleSetMemoGuide = (open) => {
        setShowMemoGuide(open)
    }

    useEffect(() => {
        if (typeof guides !== `undefined` && guides?.length > 0) {
            let guide = guides.find(r => r.code === guideCodeParams)
            if (typeof guide?.code !== `undefined`) {
                handleSetMemoGuide(true)
            }
        }

    }, [guides, guideCodeParams, dispatch]);


    let guide = guides.find(r => r.code === guideCodeParams)


    return (
        <>

            {showMemoGuide === true &&

                <div className="col-xl-12 margin-b-t-20">
                    <div className="card">
                        <div className="card-header align-items-center">
                            <img src={logo} alt="" className="w-px-60 h-auto rounded-circle margin-r-10"/>
                            <span className="fw-bold topic_memories_guide">Memories Guide</span>
                            <MemoTypeTag size={`lg`} classNameuse={`margin-l-10`} name={guide?.type}/>
                            <p className="padding-top-20 fw-bold topic_memories_guide font_i">
                                {lang == `th` ? guide?.title : guide?.titleEN}
                                {/*{guide?.title}*/}
                            </p>
                            <p className="fw-normal">
                                {lang == `th` ? guide?.Detail : guide?.DetailEN}
                                {/*{guide?.Detail}*/}
                            </p>
                        </div>
                    </div>
                </div>
            }


            {showMemoGuide === false &&
                <div className="col-xl-12 margin-b-t-20">
                    <div className="card">
                        <div className="card-header align-items-center">
                            <img src={logo} alt="" className="w-px-60 h-auto rounded-circle margin-r-10"/>
                            <span className="fw-bold topic_memories_guide">Memories Guide</span>

                            <p className="padding-top-20 fw-bold topic_memories_guide font_i">{t('writememo.memories-guide-text-detail-1')}
                                <Link to="/dashboard"><a href="javascript:void(0);">{t('writememo.memories-guide-text-detail-click')}</a></Link>
                            </p>
                        </div>
                    </div>
                </div>
            }


        </>
    )
}

export default MemoNotifications
