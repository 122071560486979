import {createSlice} from "@reduxjs/toolkit";
import {getNewAsyncMemo, updateMemoAsync} from "./memoWriterThunk";
import {decryptJson, decryptString} from "../../../common/functions/encrypt";

const defaultWidget = {
    widgetId: 0, memoId: 0,
    code: ``, majorType: `message`, subType: 1, content: ``, media: null,
    fileName: ``, desc: ``, hash: ``, sequence: 0,
    title: ``, url: ``, username: ``, password: ``,
    createdAt: ``, updatedAt: ``, deletedAt: null
}
const initialState = {
    step: `receiver`,
    writter: {},
    receivers: [],
    widgets: [defaultWidget],
    description: {
        memoId: 0,
        code: ``,
        title: ``,
        type: `memo`,
        deathTrigger: 0,
        kycRequired: 1,
        receiverAlert: 1,
        memoStatus: `publish`,
        createdAt: ``,
        updatedAt: ``,
        deletedAt: null
    },
    leave: false,
}

const memoWriterSlice = createSlice({
    name: `memoWriter`,
    initialState: initialState,
    reducers: {
        resetMemo: (state, {payload}) => {
            return {...initialState}
        },
        setMemoWritter: (state, {payload}) => {
            //console.log(payload)
            return {...state, writter: payload}
        },
        setMemoStep: (state, {payload}) => {
            return {...state, step: payload}
        },
        setMemoReceviers: (state, {payload}) => {
            return {...state, receivers: payload}
        },
        setMemoDescription: (state, {payload}) => {
            return {...state, description: {...state.description, [payload.name]: payload.value}}
        },
        setMemoWidgets: (state, {payload}) => {
            return {...state, widgets: payload}
        },
        addDefaultMemoWidget: (state, {payload}) => {
            return {...state, widgets: [...state.widgets, defaultWidget]}
        },
        updateMemoWidgetByIndex: (state, {payload}) => {
            return {
                ...state, widgets: state.widgets.map((item, index) => index == payload.index ? payload.widget : item)
            }
        },
        setLeaveMemoPage: (state, {payload}) => {
            return {...state, leave: payload}
        }
    },
    extraReducers: {
        [updateMemoAsync.fulfilled]: (state, {payload}) => {

        },
        [getNewAsyncMemo.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                writter: payload.writer,
                widgets: payload.contents.map(item => {
                    const content = decryptString(item.content)
                    if (item.majorType == `image` || item.majorType == `video` || item.majorType == `audio`) {
                        item.content = `${process.env.REACT_APP_API_BASE}/media/${content}`
                    } else {
                        item.content = content
                    }
                    return item
                }),
                receivers: payload.memoReceiver.map(item => {
                    if (typeof item?.contact !== `undefined` && item?.contact !== null) {
                        return item?.contact
                    }
                }),
                description: {
                    memoId: payload.memoId,
                    code: payload.code,
                    title: payload.title,
                    type: payload.type,
                    deathTrigger: payload.deathTriggerAlert,
                    kycRequired: payload.kycRequired,
                    receiverAlert: payload.receiverAlert,
                    memoStatus: payload.memoStatus,
                    createdAt: payload.createdAt,
                    updatedAt: payload.updatedAt,
                    deletedAt: null
                },
            }
        },
    }
})


export const memoWriteState = (state) => state.memoWrite
export const {
    resetMemo,
    setMemoWritter,
    setMemoStep,
    setMemoReceviers,
    setMemoWidgets,
    setMemoDescription,
    updateMemoWidgetByIndex,
    addDefaultMemoWidget,
    setLeaveMemoPage
} = memoWriterSlice.actions

export default memoWriterSlice.reducer
