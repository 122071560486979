import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import userReducer from './reducer/user/userSlice'
import menuReducer from './reducer/menu/menuSlice'
import contactReducer from './reducer/contact/contactSlice'
import memoReducer from './reducer/memo/memoSlice'
import memoWriteReducer from './reducer/memo/memoWriterSlice'
import emailReducer from './reducer/email/emailSlice'
import utilityReducer from './reducer/utility'
import subscribeReducer from './reducer/subscribe/subscribeSlice'
import {memoWriteState} from "./reducer/memo/memoWriterSlice";


const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

export const store = configureStore({
    reducer: {
        users: userReducer,
        menu: menuReducer,
        contact: contactReducer,
        memo: memoReducer,
        memoWrite: memoWriteReducer,
        utility: utilityReducer,
        email: emailReducer,
        subscribe: subscribeReducer
    },
    middleware: customizedMiddleware,
})
