import {AiOutlineMessage} from "react-icons/ai";
import {MdOutlineKeyboardVoice, MdOutlinePhotoSizeSelectActual} from "react-icons/md";
import {RiDeleteBinLine, RiLockPasswordLine, RiVideoLine} from "react-icons/ri";
import {HiOutlineLink} from "react-icons/hi";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import Message from "./Message";
import {useDispatch, useSelector} from "react-redux";
import {
    memoWriteState,
    setMemoWidgets,
    updateMemoWidgetByIndex
} from "../../../../../features/reducer/memo/memoWriterSlice";
import Media from "./Media";
import Link from "./Link";
import Account from "./Account";
import {useTranslation} from "react-i18next";

const disabledButton = (widget, type, typeOfContent) => {
    switch (type) {
        case `message` :
        case `link` :
            if (widget?.content !== ``) {
                return true
            }
            break
        case `image` :
        case `video` :
        case `audio` :
            if (widget?.fileName !== `` && typeof widget?.fileName !== `undefined`) {
                return true
            }
            break
        case `password` :
            let hasValue = false
            if (widget?.title !== `` || widget?.url !== `` || widget?.username !== `` || widget?.password !== ``)
                hasValue = true
            return hasValue
    }
}


const Toolbars = ({widget, setType, type, handleRemove, handleDown, handleUp}) => {

    const {t} = useTranslation();

    const checkDisabledButton = (typeOfContent) => {
        if (type != typeOfContent) {
            const disabled = disabledButton(widget, type, typeOfContent)
            if (disabled) {
                return `disabled`
            }
        }
    }

    const buttonActive = (typeOfContent) => {
        return type == typeOfContent ? `active` : ``
    }

    return (
        <>
            <div className="tools  align-items-center">
                <div className="left">
                    <span onClick={() => setType(`message`)} className={`${checkDisabledButton(`message`)} box_icon ` + (type == `message` ? "active" : "")} data-tip={t('writememo.tooltip-message')}><AiOutlineMessage className="icon"/></span>
                    <span onClick={() => setType(`image`)} className={`${checkDisabledButton(`image`)} box_icon ` + (type == `image` ? "active" : "")} data-tip={t('writememo.tooltip-image')}><MdOutlinePhotoSizeSelectActual className="icon"/></span>
                    <span onClick={() => setType(`video`)} className={`${checkDisabledButton(`video`)} box_icon ` + (type == `video` ? "active" : "")} data-tip={t('writememo.tooltip-video')}><RiVideoLine className="icon"/></span>
                    <span onClick={() => setType(`audio`)} className={`${checkDisabledButton(`audio`)} box_icon ` + (type == `audio` ? "active" : "")} data-tip={t('writememo.tooltip-audio')}><MdOutlineKeyboardVoice className="icon"/></span>
                    <span onClick={() => setType(`link`)} className={`${checkDisabledButton(`link`)} box_icon ` + (type == `link` ? "active" : "")} data-tip={t('writememo.tooltip-link')}><HiOutlineLink className="icon"/></span>
                    <span onClick={() => setType(`password`)} className={`${checkDisabledButton(`password`)} box_icon ` + (type == `password` ? "active" : "")} data-tip={t('writememo.tooltip-password')}><RiLockPasswordLine className="icon"/></span>
                </div>

                <div className="right">
                    <span onClick={() => handleUp()} className="box_icon" data-tip={t('writememo.tooltip-up')}><FiChevronUp className="icon"/></span>
                    <span onClick={() => handleDown()} className="box_icon" data-tip={t('writememo.tooltip-down')}><FiChevronDown className="icon"/></span>
                    <span onClick={() => handleRemove()} className="box_icon" data-tip={t('writememo.tooltip-delete')}><RiDeleteBinLine className="icon"/></span>
                </div>
            </div>
        </>
    )
}

const Widget = ({index = 0}) => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [widget, setWidget] = useState()

    useEffect(() => {
        if (typeof memo.widgets[index] !== `undefined`) {
            setWidget(memo.widgets[index])
        }
    }, [memo.widgets[index]])


    const WidgetType = () => {
        switch (widget?.majorType) {
            case `image`:
                return <Media typeName={`รูปภาพ`} size={1} extentions={`JPG,JPEG,PNG`} index={index}/>
            case `video`:
                return <Media typeName={`วีดีโอ`} extentions={`AVI,MP4,MOV`} size={50} index={index}/>
            case `audio`:
                return <Media typeName={`เสียง`} extentions={`mp3`} size={15} index={index}/>
            case `link`:
                return <Link index={index}/>
            case `password`:
                return <Account index={index}/>
            default:
                return <Message widget={widget} index={index}/>
        }

    }

    const handleSetType = (type) => {
        const disabled = disabledButton(widget, widget.majorType, type)
        if (disabled) {
            return
        }
        let mWidget = {...widget, majorType: type}
        dispatch(updateMemoWidgetByIndex({widget: mWidget, index: index}))
    }

    const handleRemoveWidget = () => {
        let widgets = [...memo.widgets]
        if (typeof widgets[index] !== "undefined") {
            widgets.splice(index, 1)
            dispatch(setMemoWidgets(widgets))
        }
    }

    const handleUp = () => {
        let widgets = [...memo.widgets]
        // alert(index)
        if (index > 0) {
            const up = index - 1
            const tempUp = widgets[up]
            widgets[up] = widget
            widgets[index] = tempUp
            console.log(widgets)
            dispatch(setMemoWidgets(widgets))
        }
    }

    const handleDown = () => {
        let widgets = [...memo.widgets]
        if (index < widgets.length - 1) {
            const down = index + 1
            const tempDown = widgets[down]
            widgets[down] = widget
            widgets[index] = tempDown
            dispatch(setMemoWidgets(widgets))
        }
    }

    return (
        <>
            <div className={`widget-${widget?.majorType} widget-index-${index}`}>
                <div className="wyswyg_container">
                    <Toolbars widget={widget} type={widget?.majorType} handleUp={handleUp} handleDown={handleDown} handleRemove={handleRemoveWidget} setType={handleSetType}/>
                    <div style={{minHeight: `172px`}}>
                        {WidgetType()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Widget
