import {
    changePasswordApi,
    deleteUserApi,
    fetchUsersApi,
    getProfileApi,
    getUserApi,
    loginUserApi,
    newUserApi, updateProfileApi,
    updateUserApi
} from "../../../common/api/userApi";
import {notificationErrorMessage, notificationSuccessMessage} from "../../../common/config/message";
import {createAsyncThunk} from "@reduxjs/toolkit";

import {httpRequestFunc} from "../../../common/config/httpClient";
import {setRedirect} from "../utility";
import {
    checkUsernameApi,
    otpApi,
    otpVerifyApi,
    registerUserApi,
    resetPasswordLinkApi, resetPasswordNewApi
} from "../../../common/api/registerApi";
import {setReisterStep, setSMSCode} from "./userSlice";

// export const loginAsyncUser = createAsyncThunk(
//     'users/loginAsyncUser',
//     async ({username, password}, {
//         rejectWithValue
//     }) => {
//         try {
//             const response = await loginUserApi(username, password)
//             return {
//                 accessToken: response.data.access_token,
//                 refreshToken: response.data.refresh_token
//             }
//         } catch (e) {
//             notificationErrorMessage(e)
//             const data = e?.response?.data
//             throw rejectWithValue(data?.message)
//         }
//     }
// )


export const loginAsyncUser = createAsyncThunk(
    'users/loginAsyncUser',
    async ({username, password}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, loginUserApi(username, password))
            baseThunk.dispatch(setRedirect(`/`))
            return {
                accessToken: response?.data.access_token,
                refreshToken: response?.data.refresh_token
            }
        } catch (e) {
        }

    }
)

export const otpAsyncRequest = createAsyncThunk(
    'users/otpAsyncRequest',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, otpApi(data.phone))
            const dt = response.data
            if (typeof dt?.code !== `undefined`) {
                if (typeof data.callback === `function`) {
                    data.callback(60)
                }
                baseThunk.dispatch(setSMSCode(dt?.code))
                baseThunk.dispatch(setRedirect(`/otp`))
            } else {
                notificationErrorMessage(`ไม่สามารถดำเนินการได้`)
            }
        } catch (e) {
            console.log(e)
        }

    }
)
export const otpVerifyAsyncRequest = createAsyncThunk(
    'users/otpVerifyAsyncRequest',
    async (data, baseThunk) => {
        try {

            const ref = localStorage.getItem(`ref`)
            const ads = localStorage.getItem(`ads`)
            let dt = {...data}
            if (typeof ref !== `undefined` && ref !== null && ref !== ``) {
                dt.ref = ref
            }
            if (typeof ads !== `undefined` && ads !== null && ads !== ``) {
                dt.ads = ads
            }

            const response = await httpRequestFunc(baseThunk, otpVerifyApi(dt))
            localStorage.removeItem(`ref`)
            localStorage.removeItem(`ads`)
            baseThunk.dispatch(setRedirect(`/`))
            return {
                accessToken: response?.data.access_token,
                refreshToken: response?.data.refresh_token
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export const checkUsernameAsyncUser = createAsyncThunk(
    'users/checkUsernameAsyncUser',
    async (username, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, checkUsernameApi(username))
            if (response.status === 200) {
                baseThunk.dispatch(setReisterStep(2))
            }
        } catch (e) {

        }
    }
)
export const registerAsyncUser = createAsyncThunk(
    'users/newAsyncUser',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, registerUserApi(data))
            if (response.status === 200) {
                baseThunk.dispatch(setRedirect(`/`))
                return {
                    accessToken: response?.data.access_token,
                    refreshToken: response?.data.refresh_token
                }
            }
        } catch (e) {
            notificationErrorMessage(e)
        }
    }
)


export const fetchAsyncUsers = createAsyncThunk(
    'users/fetchAsyncUsers',
    async (filter) => {
        const response = await fetchUsersApi(filter)
        return response.data
    }
)

export const getAsyncProfile = createAsyncThunk(
    'users/getAsyncUsers',
    async () => {
        try {
            const response = await getProfileApi()
            let img = ``
            if (typeof response?.data?.imageUrl !== `undefined` && response?.data?.imageUrl !== null && response?.data?.imageUrl !== ``) {
                img = `${process.env.REACT_APP_API_BASE}/media/${response?.data?.imageUrl}`
            }
            let dt = {
                ...response.data,
                imageUrl: img,
            }
            return dt
        } catch (e) {

        }
    }
)


export const getAsyncUser = createAsyncThunk(
    'users/getAsyncUser',
    async ({id}) => {
        const response = await getUserApi(id)
        return response.data
    }
)

export const newAsyncUser = createAsyncThunk(
    'users/newAsyncUser',
    async ({data}, {
        dispatch
    }) => {
        try {
            const response = await newUserApi(data)
            if (response.status === 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                const user = response.data
                dispatch(setRedirect(`/user`))
                return user
            }
        } catch (e) {
            notificationErrorMessage(e)
        }
    }
)

export const updateAsyncUser = createAsyncThunk(
    'users/updateAsyncUser',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateUserApi(data.username, data))
            // const response = await updateUserApi(data.username, data)
            if (response.status === 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                baseThunk.dispatch(getAsyncProfile())
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const updateAsyncUserProfile = createAsyncThunk(
    'users/updateAsyncUserProfile',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateProfileApi(data))
            // const response = await updateUserApi(data.username, data)
            if (response.status === 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                baseThunk.dispatch(getAsyncProfile())
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const changePasswordAsyncUser = createAsyncThunk(
    'users/changePasswordAsyncUser',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, changePasswordApi(data.username, data))
            if (response.status === 200) {
                notificationSuccessMessage(`แก้ไขรหัสผ่านสำเร็จ`)
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)


export const deleteAsyncUser = createAsyncThunk(
    'users/deleteAsyncUser',
    async ({id}) => {
        try {
            const response = await deleteUserApi(id)
            if (response.status === 200) {
                notificationSuccessMessage(`ทำรายการสำเร็จ`)
                return id
            }
        } catch (e) {
            notificationErrorMessage(e)
        }
    }
)


export const resetPasswordAsyncUser = createAsyncThunk(
    'users/resetPasswordAsyncUser',
    async (email, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, resetPasswordLinkApi(email))
            if (response.status === 200) {
                notificationSuccessMessage(`กรุณาตรวจสอบอีเมล์`)
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)
export const resetPasswordNewAsyncUser = createAsyncThunk(
    'users/resetPasswordAsyncUser',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, resetPasswordNewApi(data))
            if (response.status === 200) {
                notificationSuccessMessage(`คุณสามารถเข้าสู่ระบบได้ทันที ด้วยรหัสผ่านใหม่`)
                baseThunk.dispatch(setRedirect(`/login`))
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)
