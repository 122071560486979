import React from "react";
import {useTranslation} from "react-i18next";

const ReceiverTag = ({name}) => {

    const {t} = useTranslation();


    switch (name) {
        case 'friend' :
            return (
                <span className="badge rounded-pill bg-label-friends" data-tip={t('receivertag.tag-friends')}>{t('receivertag.tag-friends')}</span>
            )
            break
        case 'family' :
            return (
                <span className="badge rounded-pill bg-label-info" data-tip={t('receivertag.tag-family')}>{t('receivertag.tag-family')}</span>
            )
            break
        case 'business' :
            return (
                <span className="badge rounded-pill label_business" data-tip={t('receivertag.tag-business')}>{t('receivertag.tag-business')}</span>
            )
            break
        default:
            return (
                <span className="badge rounded-pill bg-label-secondary" data-tip={t('receivertag.tag-unknown')}>{t('receivertag.tag-unknown')}</span>
            )
    }
}

export default ReceiverTag
