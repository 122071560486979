import cover_step1 from "../../../../common/assets/img/cover/cover_write_memo_step1.gif";
import {GrAdd, GrFormNext} from "react-icons/gr";
import React, {useEffect, useState} from "react";
import {memoWriteState, setMemoReceviers, setMemoStep} from "../../../../features/reducer/memo/memoWriterSlice";
import {useDispatch, useSelector} from "react-redux";
import {getMyContacts} from "../../../../features/reducer/contact/contactSlice";
import {fetchAsyncContacts} from "../../../../features/reducer/contact/contactThunk";
import {Link} from "react-router-dom";
import ImageUserProfile from "../../../utility/ImageUserProfile";
import ReceiverTag from "../../../utility/ReceiverTag";
import {notificationErrorCustomMessage} from "../../../../common/config/message";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {getProfile} from "../../../../features/reducer/user/userSlice";

const MemoReceiver = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(window.location.search)
    const myContacts = useSelector(getMyContacts)
    const memo = useSelector(memoWriteState)
    const profile = useSelector(getProfile)

    const [receiverParams] = useState(queryParams.get("receiver"))

    useEffect(() => {
        dispatch(fetchAsyncContacts({page: 1, pageSize: 20}))
    }, [dispatch])

    useEffect(() => {
        if (typeof myContacts !== `undefined` && myContacts?.data.length > 0) {
            let user = myContacts.data.find(r => r.code === receiverParams)
            if (typeof user?.code !== `undefined`) {
                handleAddReceiver(user)
            }
        }
    }, [myContacts, receiverParams])


    const handleStep = key => {
        let receivers = [...memo.receivers]
        if (receivers.length < 1) {
            return notificationErrorCustomMessage(t('writememo.receiver-alert-choose-receiver'), `info`)
        }
        dispatch(setMemoStep(key))
    }

    const handleCheckChecked = (id) => {
        let receivers = [...memo.receivers]
        const index = receivers.findIndex(item => item?.id == id)
        return index !== -1
    }

    const handleAddReceiver = (user) => {
        let receivers = [...memo.receivers]
        const i = receivers.findIndex(item => item.id == user.id)
        if (i === -1) {
            receivers.push(user)
        } else {
            receivers.splice(i, 1)
        }
        dispatch(setMemoReceviers(receivers))
    }
    return (
        <>
            <div id="memo-receiver" className={"content active"}>
                <div className="row g-3">

                        <div className="d-flex col-12 justify-content-end">
                            <button className="btn btn-primary btn-next" onClick={() => handleStep('setting')}>
                                {t('writememo.next-button-page2')}<GrFormNext className="memo_write_icon"/>
                            </button>
                        </div>


                    <div className="col-12">
                        <div className="img-fluid rounded-2 py-5 w-100 d-flex align-items-center  flex-column justify-content-center">
                            <h4 className="fw-bold">1. {t('writememo.title-topic-select-receiver')}</h4>
                            <img className="image_gif_cover" src={cover_step1}/>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-6 box_write_receiver col-lg-6 col-xl-4">
                                <div className="form-check custom-option custom-option-icon">
                                    <div className="card-body text-center sub d-flex justify-content-center align-items-center">
                                        <div>
                                            <div className="mx-auto mb-3 d-flex justify-content-center align-items-center">
                                                <Link to="/receiver/add" className="menu-link">
                                                    <div className="rounded-circle-avatar-icon bg_blank w-px-100 h-px-100 d-flex justify-content-center align-items-center">
                                                        <GrAdd className="icon_avatar"/>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="gap-2 d-flex justify-content-center align-items-center my-3">
                                                <Link to="/receiver/add" className="menu-link">
                                                    <button type="button" className="btn btn-mali-outline">
                                                        {t('writememo.addreceiver-button')}
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {myContacts?.data?.length > 0 && myContacts.data.map(user => (
                                <div className="col-md-6 box_write_receiver col-lg-6 col-xl-4" key={user.id}>
                                    <div className={`form-check custom-option custom-option-icon ${handleCheckChecked(user?.id) ? `receiver_memo_active` : ``}`} onClick={() => handleAddReceiver(user)}>
                                        <div className="card-body text-center sub">
                                            <div className="d-flex justify-content-center mb-3">
                                                <ImageUserProfile size={`lg`} user={user}/>
                                            </div>
                                            <h5 className="card-title mb-1">{user?.firstName} {user?.lastName}</h5>
                                            <span>{user?.nickName}</span>
                                            <div className="gap-2 d-flex justify-content-center align-items-center my-3">
                                                <ReceiverTag name={user?.type}/>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <input
                                                    name="customRadioIcon"
                                                    className="form-check-input checkbox_ui"
                                                    type="checkbox"
                                                    value={user?.id}
                                                    checked={handleCheckChecked(user?.id)}
                                                    onChange={() => handleAddReceiver(user)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                        <div className="d-flex col-12 justify-content-end">
                            <button className="btn btn-primary btn-next" onClick={() => handleStep('setting')}>
                                {t('writememo.next-button-page2')}<GrFormNext className="memo_write_icon"/>
                            </button>
                        </div>

                </div>
            </div>
        </>
    )
}

export default MemoReceiver
