import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import {FaCheck} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {getPackages, setSelectPackage} from "../../../features/reducer/subscribe/subscribeSlice";
import {fetchPackageAsync} from "../../../features/reducer/subscribe/subscribeThunk";
import CurrencyFormat from 'react-currency-format';
import {getProfile} from "../../../features/reducer/user/userSlice";
import {setOpenModalContent} from "../../../features/reducer/utility";
import parse from "html-react-parser";
import logo from "../../../common/assets/img/mali/icon_app.png";
import TrialCodeBoxModal from "../dashboard/TrialCodeBoxModal";
import {useCookies} from "react-cookie";


const ServiceCharge = () => {
    const dispatch = useDispatch()
    const {i18n, t} = useTranslation();
    const packages = useSelector(getPackages)
    const navigate = useNavigate();
    const profile = useSelector(getProfile)
    const [cookies] = useCookies(['refInterview']);
    const [cookieInterview, setCookieInterview] = useState(null);
    const [lang, setLang] = useState(i18n.language)

    useEffect(() => {
        dispatch(activeMenu({menu: `serviceCharge`}))
        dispatch(fetchPackageAsync())
    }, [dispatch])

    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        // console.log(cookies.refInterview)
        if (typeof cookies?.refInterview !== `undefined` && cookies.refInterview !== ``) {
            setCookieInterview(cookies?.refInterview)
        }
    }, [cookies])

    useEffect(() => {
        if (typeof packages !== `undefined`) {
            // console.log(packages)
        }
    }, [packages])

    const onSelectPackage = (packageItem) => {
        dispatch(setSelectPackage(packageItem))
        navigate("/payment")
    }

    const trialDays = (text) => {
        const days = profile?.trialDays ?? 0
        if (days !== 0 && !isNaN(days)) {
            return text.replaceAll(`@days`, profile?.trialDays ?? 0)
        }
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI /</span> {t('priceservice.page-nav-title')}
                </h4>
                <div className="card">
                    <div className="pricing-plans rounded-top pb-2 pb-sm-5">
                        <div className="container py-5">
                            <h2 className="text-center mb-4">PRICING SERVICE</h2>
                            <h5 className="text-center">
                                {t('priceservice.text-button-sign-up')}
                            </h5>
                            <br></br>

                            <div className="row gy-3 mx-0 d-flex justify-content-center">
                                {packages?.map((item, index) => (
                                    <div className="lg-4 col-md-4 mb-lg-0 ">
                                        <div className="card shadow-none border">
                                            <div className="card-body">
                                                <h5 className="text-start text-uppercase">
                                                    <span className="badge bg-label-primary">{item?.packageName}</span>
                                                </h5>

                                                <div className="position-relative text-center pb-1 mb-4">
                                                    <div className="d-flex mb-2">
                                                        <h1 className="price-toggle text-primary price-yearly mb-0">
                                                            {cookieInterview !== null ? (
                                                                <CurrencyFormat value="0" displayType={'text'} thousandSeparator={true} prefix={'฿'}/>
                                                            ) : (
                                                                <CurrencyFormat value={item?.packagePrice ?? 0} displayType={'text'} thousandSeparator={true} prefix={'฿'}/>
                                                            )}

                                                            {/*<CurrencyFormat value="0" displayType={'text'} thousandSeparator={true} prefix={'฿'}/>*/}
                                                        </h1>
                                                        <sub className="h5 pricing-duration text-muted mt-auto mb-2">/ {t('priceservice.text-bottom-year-1')}</sub>
                                                    </div>
                                                </div>

                                                <p>
                                                    {lang === `th` ? item?.packageDesc : item?.packageDescEN}
                                                    {/*{item?.packageDesc}*/}
                                                </p>
                                                <hr/>

                                                <ul className="list-unstyled pt-2 pb-1">
                                                    {lang === `th` ? (
                                                        <>
                                                            {item.packageInfo.map((info) => (
                                                                <li className="mb-2">
                                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                                    </span>
                                                                    {info?.name ?? ''}
                                                                </li>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item?.packageInfoEN?.length > 0 && item?.packageInfoEN.map((info) => (
                                                                <li className="mb-2">
                                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                                    </span>
                                                                    {info?.name ?? ''}
                                                                </li>
                                                            ))}
                                                        </>
                                                    )}

                                                </ul>
                                                {!profile?.isExpired && (
                                                    <Link to="/settings/bill">
                                                        <div className="btn bg-label-primary d-grid w-100">{t('priceservice.topic-success-member-active')}</div>
                                                    </Link>
                                                )}

                                                {profile?.isExpired && (
                                                    <div className="btn btn-primary d-grid w-100" onClick={() => onSelectPackage(item)}>{t('priceservice.text-button-apply-member')}</div>
                                                )}

                                                {profile?.isExpired && (
                                                    <div className="text-center text-trial">
                                                        <a href="javascript:void(0);" onClick={() => dispatch(setOpenModalContent({
                                                            title: parse('<img src=' + logo + ' alt="" className="w-px-40 h-auto rounded-circle margin-r-10"/>' + trialDays(t('priceservice.text-trial'))),
                                                            size: `md`, //sm lg xl
                                                            content:
                                                                <TrialCodeBoxModal guide={profile?.trialcode}/>, //component or html tag
                                                        }))}><u>{t('priceservice.text-click-this')}</u> {trialDays(t('priceservice.text-click-this-for-trial'))}
                                                        </a>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row gy-3 mx-0 d-flex justify-content-center">
                                    <div className="hide lg-4 col-md-4 mb-lg-0 ">
                                        <div className="card border-2 border-primary border">
                                            <div className="card-body">

                                                <div className="d-flex flex-wrap justify-content-between mb-3">
                                                    <h5 className="text-start text-uppercase mb-0">MALI FRIENDS</h5>
                                                    <span className="badge rounded-pill bg-primary">ยอดนิยม</span>
                                                </div>

                                                <div className="position-relative text-center pb-1 mb-4">
                                                    <div className="d-flex mb-2">
                                                        <h1 className="price-toggle text-primary price-yearly mb-0">฿659</h1>
                                                        <sub className="h5 pricing-duration text-muted mt-auto mb-2">/ ปี</sub>
                                                    </div>
                                                </div>

                                                <p>สำหรับกลุ่มคนที่มีเพื่อนหรือคนที่รักต้องการชวนมาใช้งาน MALI</p>

                                                <hr/>

                                                <ul className="list-unstyled pt-2 pb-1">
                                                    <li className="mb-2">
                                                        <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                            <FaCheck className="price_check_icon"></FaCheck>
                                                        </span>
                                                        1 Premium Accounts
                                                    </li>
                                                    <li className="mb-2">

                                                        <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                            <FaCheck className="price_check_icon"></FaCheck>
                                                        </span>
                                                        จำนวนผู้รับไม่จำกัด
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                            <FaCheck className="price_check_icon"></FaCheck>
                                                        </span>
                                                        พื้นที่จัดเก็บ 5 GB
                                                    </li>

                                                    <li className="mb-2">
                                                        <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                            <FaCheck className="price_check_icon"></FaCheck>
                                                        </span>
                                                        Widget Media
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                            <FaCheck className="price_check_icon"></FaCheck>
                                                        </span>
                                                        DeathTrigger
                                                    </li>
                                                </ul>

                                                <Link to="/payment" className="btn btn-label-primary d-grid w-100"
                                                >เลือก</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hide lg-4 col-xl mb-lg-0">
                                    <div className="card shadow-none border">
                                        <div className="card-body">
                                            <h5 className="text-start text-uppercase">MALI FAMILY</h5>

                                            <div className="position-relative text-center pb-1 mb-4">
                                                <div className="d-flex mb-2">
                                                    <h1 className="price-toggle text-primary price-yearly mb-0">฿3,090</h1>
                                                    <sub className="h5 pricing-duration text-muted mt-auto mb-2">/ ปี</sub>
                                                </div>
                                            </div>

                                            <p>สำหรับกลุ่มคนที่ต้องการให้คนในครอบครัวใช้งาน MALI จำนวนมาก</p>

                                            <hr/>

                                            <ul className="list-unstyled pt-2 pb-1">
                                                <li className="mb-2">
                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                    </span>
                                                    10 Premium Accounts
                                                </li>
                                                <li className="mb-2">

                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                    </span>
                                                    จำนวนผู้รับไม่จำกัด
                                                </li>
                                                <li className="mb-2">
                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                    </span>
                                                    พื้นที่จัดเก็บ 5 GB
                                                </li>

                                                <li className="mb-2">
                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                    </span>
                                                    Widget Media
                                                </li>
                                                <li className="mb-2">
                                                    <span className="badge badge-center price_check_icon  rounded-pill bg-label-primary me-2">
                                                        <FaCheck className="price_check_icon"></FaCheck>
                                                    </span>
                                                    DeathTrigger
                                                </li>
                                            </ul>

                                            <Link to="/notification/list" className="btn btn-label-primary d-grid w-100"
                                            >เลือก</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceCharge
