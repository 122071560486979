import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import writememo_banner_light from "../../../common/assets/img/cover/cover_my_mali.gif";
import {Link} from "react-router-dom";
import {IoIosLock, IoIosPaperPlane} from "react-icons/io";
import {MdOutlineMarkEmailUnread} from "react-icons/md";
import {BiHide} from "react-icons/bi";
import {VscMailRead} from "react-icons/vsc";
import ReactTooltip from "react-tooltip";
import imgProfile from "../../../common/assets/img/mali/profile_temp/cot.jpg";
import {RiDeleteBinLine, RiEditBoxLine, RiEditLine, RiSlideshow4Line} from "react-icons/ri";
import writememo_profile3 from "../../../common/assets/img/mali/profile_temp/zhai.jpg";
import writememo_profile2 from "../../../common/assets/img/mali/profile_temp/noon.jpg";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {updateUserApi} from "../../../common/api/userApi";
import {notificationErrorMessage, notificationSuccessMessage} from "../../../common/config/message";
import {getAsyncProfile} from "../../../features/reducer/user/userThunk";
import {sendContactEmailApi} from "../../../common/api/emailApi";

const Mali = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(activeMenu({menu: `mali`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSendMail = async () => {
        try {
            const response = await sendContactEmailApi({})
            // const response = await updateUserApi(data.username, data)
            if (response.status === 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
            }
        } catch (e) {
            notificationErrorMessage(e)
        }
    }

    return (<>
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">MALI /</span> MALI ของคุณ
            </h4>
            <div className="card">
                <div className="card-header border-bottom">
                    <div className="col-12">
                        <div className="img-fluid rounded-2 w-100">
                            <center>

                                <br></br>
                                <br></br>
                                <h4 className="fw-bold">รายการบันทึกความทรงจำ MALI ของสมาชิกท่านอื่น ที่ได้ถูกจัดส่งถึงคุณแล้ว</h4>
                                <img className="image_gif_cover" src={writememo_banner_light}/>


                                <button className="btn btn-primary" onClick={() => handleSendMail()}>Send Mail</button>
                            </center>
                        </div>
                    </div>
                </div>


                <div className="card-datatable table-responsive">
                    <table className="datatables-users table border-top">
                        <thead>
                        <tr>
                            <th className="table_date">วันที่บันทึก</th>
                            <th>Title</th>
                            <th>สถานะ</th>
                            <th>จัดการ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="sorting_1 table_date">
                                <ReactTooltip place="top" effect="solid" className="tooltip_style"/>

                                <div className="DateMonth">MAY 2022</div>
                                <div className="boxDate">
                                    <span className="DateDay">22</span>
                                    <span className="DateDayText text-muted">MON</span>
                                </div>
                                <br/>
                                <div className="badge rounded-pill bg-label-i-wish tags" data-tip="I-Wish">I-Wish</div>
                            </td>
                            <td>
                                <span>ฝากจัดการหลังจากที่จากไปด้วยนะ ตามรายการนี้ได้เลย</span>
                                <div className="d-flex flex-column">
                                    <small className="text-muted">จาก Zencott</small></div>
                                <ul className="table_list_avatar">
                                    <li>
                                        <div className="avatar-wrapper">
                                            <div className="avatar avatar-sm me-3 ">
                                                <img src={imgProfile} alt="Avatar" className="rounded-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <span className="badge bg-mali" data-tip="ยังไม่เปิดอ่าน"><MdOutlineMarkEmailUnread className="icon_table_size"/> ยังไม่ได้เปิดอ่าน</span>
                            </td>
                            <td>

                                <Link to="/memo/view" data-tip="ดูข้อมูล Memo">
                                    <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                <Link to="/memo/del" data-tip="ซ่อน Memo"><BiHide className="manage_icon icon_table_size"/></Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="sorting_1 table_date">
                                <ReactTooltip place="top" effect="solid" className="tooltip_style"/>

                                <div className="DateMonth"> FEB 2022</div>
                                <div className="boxDate">
                                    <span className="DateDay">12</span>
                                    <span className="DateDayText text-muted">SUN</span>
                                </div>
                                <br/>
                                <div className="badge rounded-pill bg-label-memo tags" data-tip="Memo">Memo</div>
                            </td>
                            <td>
                                <span>บันทึกถึงเพื่อนที่ดีที่สุดของเราค๊อตโต้</span>
                                <div className="d-flex flex-column">
                                    <small className="text-muted">จาก ทราย</small></div>

                                <ul className="table_list_avatar">
                                    <li>
                                        <div className="avatar-wrapper">
                                            <div className="avatar avatar-sm me-3 ">
                                                <img src={writememo_profile3} alt="Avatar" className="rounded-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>


                            </td>
                            <td>
                                <span className="badge bg-mali-darft" data-tip="เปิดอ่านแล้ว"><VscMailRead className="icon_table_size"/> เปิดอ่านแล้ว</span>
                            </td>
                            <td>

                                <Link to="/memo/view" data-tip="ดูข้อมูล Memo">
                                    <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                <Link to="/memo/del" data-tip="ซ่อน Memo"><BiHide className="manage_icon icon_table_size"/></Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="sorting_1 table_date">
                                <ReactTooltip place="top" effect="solid" className="tooltip_style"/>

                                <div className="DateMonth">JAN 2022</div>
                                <div className="boxDate">
                                    <span className="DateDay">26</span>
                                    <span className="DateDayText text-muted">MON</span>
                                </div>
                                <br/>
                                <div className="badge rounded-pill bg-label-password tags" data-tip="Password">Password</div>
                            </td>
                            <td>
                                <span>บันทึกถึงเพื่อนที่ดีที่สุดของเราค๊อตโต้</span>
                                <div className="d-flex flex-column">
                                    <small className="text-muted">จาก Zencott</small></div>
                                <ul className="table_list_avatar">
                                    <li>
                                        <div className="avatar-wrapper">
                                            <div className="avatar avatar-sm me-3 ">
                                                <img src={imgProfile} alt="Avatar" className="rounded-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <span className="badge bg-mali-darft" data-tip="เปิดอ่านแล้ว"><VscMailRead className="icon_table_size"/> เปิดอ่านแล้ว</span>
                            </td>
                            <td>

                                <Link to="/memo/view" data-tip="ดูข้อมูล Memo">
                                    <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                <Link to="/memo/del" data-tip="ซ่อน Memo"><BiHide className="manage_icon icon_table_size"/></Link>
                            </td>
                        </tr>
                        <tr>
                            <td className="sorting_1 table_date">
                                <ReactTooltip place="top" effect="solid" className="tooltip_style"/>

                                <div className="DateMonth">JAN 2022</div>
                                <div className="boxDate">
                                    <span className="DateDay">1</span>
                                    <span className="DateDayText text-muted">FRI</span>
                                </div>
                                <br/>
                                <div className="badge rounded-pill bg-label-i-wish tags" data-tip="I-Wish">I-Wish</div>
                            </td>
                            <td>
                                <span>บันทึกถึงเพื่อนที่ดีที่สุดของเราค๊อตโต้</span>
                                <div className="d-flex flex-column">
                                    <small className="text-muted">จาก Zencott</small></div>
                                <ul className="table_list_avatar">
                                    <li>
                                        <div className="avatar-wrapper">
                                            <div className="avatar avatar-sm me-3 ">
                                                <img src={imgProfile} alt="Avatar" className="rounded-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <span className="badge bg-mali-darft" data-tip="เปิดอ่านแล้ว"><VscMailRead className="icon_table_size"/> เปิดอ่านแล้ว</span>
                            </td>
                            <td>
                                <Link to="/memo/view" data-tip="ดูข้อมูล Memo">
                                    <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                <Link to="/memo/del" data-tip="ซ่อน Memo"><BiHide className="manage_icon icon_table_size"/></Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>)
}

export default Mali
