import forgotImg from '../../common/assets/img/explan/stand_think.gif'
import {Link, useNavigate} from "react-router-dom";
import {BiChevronLeft} from "react-icons/bi";
import imgLogoSmall from "../../common/assets/img/mali/logo_small.png";
import React, {useEffect, useState} from "react";
import {notificationErrorCustomMessage} from "../../common/config/message";
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordNewAsyncUser} from "../../features/reducer/user/userThunk";
import {redirect, setRedirect} from "../../features/reducer/utility";
import {useTranslation} from "react-i18next";

const ConfirmPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search)
    const [resetKey, setResetKey] = useState(queryParams.get('key'))
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const isRedirect = useSelector(redirect)
    const {t} = useTranslation();

    useEffect(() => {
        const key = queryParams.get('key')
        if (typeof key === `undefined` || key === `` || key === null) {
            setResetKey(``)
            notificationErrorCustomMessage(t('confirmpass.noti-nodata-reset-pass'), 'info')
        } else {
            setResetKey(key)
        }
    }, [queryParams.get('key')])


    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [dispatch, isRedirect])


    const handleInput = (e) => {
        const {name, value} = e.target
        setData({...data, [name]: value})
    }

    const handleSubmit = () => {
        let hasError = false
        let err = {...errors}
        if (typeof data?.password === `undefined` || data?.password === `` || data?.password === null) {
            err.password = true
            hasError = true
        }

        if (typeof data?.confirmPassword === `undefined` || data?.confirmPassword === `` || data?.confirmPassword === null) {
            err.confirmPassword = true
            hasError = true
        }

        if (hasError) {
            setErrors(err)
            return
        }

        dispatch(resetPasswordNewAsyncUser({...data, key: resetKey}))
    }

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
                        <div className="flex-row text-center mx-auto">
                            <img src={forgotImg} alt="Auth Cover Bg color" width="400" className="img-fluid authentication-cover-img"/>
                            <div className="mx-auto">
                                <h3>{t('confirmpass.text-h3-title')}</h3>
                                <p>
                                    {t('confirmpass.text-p-desc')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="authentication-bg d-flex col-12 col-lg-5 col-xl-4 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="/" className="app-brand-link gap-2 mb-2">
                                    <img width="40%" src={imgLogoSmall}/>
                                </a>
                            </div>
                            <h4 className="mb-2">{t('confirmpass.topic-set-pass')}</h4>
                            <p className="mb-4">{t('confirmpass.topic-input-pass')}</p>
                            <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" action="auth-reset-password-cover.html" method="POST" noValidate="novalidate">
                                <div className={`mb-3 fv-plugins-icon-container ${errors?.password ? `has-error` : ``}`}>
                                    <label htmlFor="email" className="form-label">{t('confirmpass.text-new-pass')}</label>
                                    <input type="password" className="form-control" id="email" name="password" onChange={e => handleInput(e)} placeholder="" autoFocus=""/>
                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.password ? t('confirmpass.alert-please-input-all') : ``}</div>
                                </div>
                                <div className={`mb-3 fv-plugins-icon-container ${errors?.confirmPassword ? `has-error` : ``}`}>
                                    <label htmlFor="email" className="form-label">{t('confirmpass.text-confirm-new-pass')}</label>
                                    <input type="password" className="form-control" id="email" name="confirmPassword" onChange={e => handleInput(e)} placeholder="" autoFocus=""/>
                                    <div className="fv-plugins-message-container invalid-feedback">{errors?.confirmPassword ? t('confirmpass.alert-please-input-all') : ``}</div>
                                </div>
                                <button type="button" disabled={resetKey == ``} onClick={() => handleSubmit()} className="btn btn-primary d-grid w-100">{t('confirmpass.text-confirm-button')}</button>
                                <div></div>
                                <input type="hidden"/>
                            </form>
                            <div className="text-center">
                                <Link to="/login" className="d-flex justify-content-center align-items-center">
                                    <BiChevronLeft className="scaleX-n1-rtl"/> {t('confirmpass.text-back-to-login')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmPassword
