import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import image_credit from "../../../common/assets/img/explan/payment_credit.jpg";
import image_redeem from "../../../common/assets/img/explan/payment_redeem.jpg";
import image_bank from "../../../common/assets/img/explan/payment_bank.jpg";
import {useTranslation} from "react-i18next";
import {FaCheck} from "react-icons/fa";
import {getSelectPackages} from "../../../features/reducer/subscribe/subscribeSlice";
import CurrencyFormat from "react-currency-format";


const Payment = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const myPackage = useSelector(getSelectPackages)
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(activeMenu({menu: `serviceCharge`}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (typeof myPackage === `undefined` || myPackage === null) {
            navigate(`/price`)
        }
    }, [myPackage])

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI /</span> {t('priceservice.text-title-payment-method')}
                </h4>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3 className="card-title mb-2">{t('priceservice.text-title-credit-card')}</h3>
                                <span className="d-block text-nowrap mb-4">{t('priceservice.text-bottom-all-types')}</span>
                            </div>
                            <div className="card-body">
                                <div className="row align-items-end">
                                    <div className="col-6">
                                        <div className="d-flex mb-2">
                                            <h1 className="price-toggle text-primary price-yearly mb-0"> <CurrencyFormat value={myPackage?.packagePrice ?? 0} displayType={'text'} thousandSeparator={true} prefix={'฿'}/></h1>
                                            <sub className="h5 pricing-duration text-muted mt-auto mb-2">/ {t('priceservice.text-bottom-year-1')}</sub>
                                        </div>
                                        <small className="d-block mb-3">{t('priceservice.text-bottom-auto-renew')}</small>
                                        <Link to="/payment/card">
                                         <a href="javascript:;" className="btn btn-md btn-primary">{t('priceservice.text-button-choose-1')}</a>
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src={image_credit}
                                            width="140"
                                            className="rounded-start"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3 className="card-title mb-2">{t('priceservice.text-title-bank-tranfer')}</h3>
                                <span className="d-block text-nowrap mb-4">{t('priceservice.text-bottom-pay-balance')}</span>
                            </div>
                            <div className="card-body">
                                <div className="row align-items-end">
                                    <div className="col-6">
                                        <div className="d-flex mb-2">
                                            <h1 className="price-toggle text-primary price-yearly mb-0"><CurrencyFormat value={myPackage?.packagePrice ?? 0} displayType={'text'} thousandSeparator={true} prefix={'฿'}/></h1>
                                            <sub className="h5 pricing-duration text-muted mt-auto mb-2">/ {t('priceservice.text-bottom-year-2')}</sub>
                                        </div>
                                        <small className="d-block mb-3">{t('priceservice.text-bottom-remind-noti')}</small>
                                        <Link to="/payment/bank">
                                            <a href="javascript:;" className="btn btn-md btn-primary">{t('priceservice.text-button-choose-2')}</a>
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src={image_bank}
                                            width="140"
                                            height="150"
                                            className="rounded-start"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card h-100">
                            <div className="card-header">
                                <h3 className="card-title mb-2">{t('priceservice.text-title-redeem-code')}</h3>
                                <span className="d-block text-nowrap mb-4">{t('priceservice.text-title-redeem-code')}</span>
                            </div>
                            <div className="card-body">
                                <div className="row align-items-end">
                                    <div className="col-6">
                                        <div className="d-flex mb-2">
                                            <h1 className="price-toggle text-primary price-yearly mb-0">0฿</h1>
                                            <sub className="h5 pricing-duration text-muted mt-auto mb-2">/ {t('priceservice.text-bottom-year-3')}</sub>
                                        </div>
                                        <small className="d-block mb-3">{t('priceservice.text-bottom-get-redeem')}</small>
                                        <Link to="/settings/bill#redeem">
                                            <a href="javascript:;" className="btn btn-md btn-primary">{t('priceservice.text-button-choose-3')}</a>
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src={image_redeem}
                                            width="140"
                                            height="150"
                                            className="rounded-start"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Payment
