import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: 'WAIT',
    redirect: false,
    activeMenu: null,
    modal: false,
    modalContent: {
        title: ``,
        size: `lg`,
        content: ``
    },
    popupConfirm: false,
    progressPopup: {
        show: false,
        percent: 11,
    },
    progressDialog: false,
}

const utilitySlice = createSlice({
    name: `utility`,
    initialState: initialState,
    reducers: {
        setLoading: (state, {payload}) => {
            return {...state, loading: payload.loading}
        },
        setRedirect: (state, {payload}) => {
            return {...state, redirect: payload}
        },
        setActiveMenu: (state, {payload}) => {
            return {...state, activeMenu: payload}
        },
        setModal: (state, {payload}) => {
            return {...state, modal: payload}
        },
        setPopupConfirm: (state, {payload}) => {
            return {...state, popupConfirm: payload}
        },
        setOpenModalContent: (state, {payload}) => {
            return {...state, modal: true, modalContent: payload}
        },
        rmOpenModalContent: (state, {payload}) => {
            return {...state, modal: false, modalContent: payload}
        },
        setProgressPopup: (state, {payload}) => {
            return {...state, progressPopup: payload}
        },
        setProgressDialog: (state, {payload}) => {
            return {...state, progressDialog: payload}
        },
    }
})

export const loading = (state) => state.utility.loading
export const redirect = (state) => state.utility.redirect
export const activeMenu = (state) => state.utility.activeMenu
export const myModal = (state) => state.utility.modalContent
export const modal = (state) => state.utility.modal
export const popupConfirm = (state) => state.utility.popupConfirm
export const popupProgress = (state) => state.utility.progressPopup
export const progressDialog = (state) => state.utility.progressDialog

export const {
    setLoading,
    setRedirect,
    setActiveMenu,
    setModal,
    setOpenModalContent,
    rmOpenModalContent,
    setPopupConfirm,
    setProgressPopup,
    setProgressDialog
} = utilitySlice.actions

export default utilitySlice.reducer
