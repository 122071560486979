import {createSlice} from "@reduxjs/toolkit";
import {
    addSubscription,
    addSubscriptionOmiseAsync,
    addSubscriptionRedeemAsync,
    fetchPackageAsync
} from "./subscribeThunk";

const initialState = {
    submitAction: -1,
    packages: [],
    selectPackage: null,
    redeemCodeExpired: null,
}


const subscribeSlice = createSlice({
    name: `subscribe`,
    initialState: initialState,
    reducers: {
        setSelectPackage: (state, {payload}) => {
            return {...state, selectPackage: payload}
        },
        setSubmitAction: (state, {payload}) => {
            return {...state, submitAction: payload}
        }
    },
    extraReducers: {
        [addSubscription.pending]: (state, {payload}) => {
            return {...state, submitAction: 0}
        },
        [addSubscription.fulfilled]: (state, {payload}) => {
            return {...state, submitAction: 1}
        },
        [addSubscription.rejected]: (state, {payload}) => {
            return {...state, submitAction: 2}
        },

        [fetchPackageAsync.fulfilled]: (state, {payload}) => {
            return {...state, packages: payload}
        },


        [addSubscriptionRedeemAsync.pending]: (state, {payload}) => {
            return {...state, submitAction: 0}
        },
        [addSubscriptionRedeemAsync.fulfilled]: (state, {payload}) => {
            if (typeof payload?.expiredDate !== `undefined`) {
                return {...state, submitAction: 5, redeemCodeExpired: payload?.expiredDate}
            }
        },
        [addSubscriptionRedeemAsync.rejected]: (state, {payload}) => {
            return {...state, submitAction: 2}
        },

        [addSubscriptionOmiseAsync.fulfilled]: (state, {payload}) => {
            if (typeof payload?.expiredDate !== `undefined`) {
                return {...state, submitAction: 5, redeemCodeExpired: payload?.expiredDate}
            }
        },
    }
})
export const subscribeState = (state) => state.subscribe
export const getPackages = (state) => state.subscribe.packages
export const getSelectPackages = (state) => state.subscribe.selectPackage
export const {
    setSelectPackage,
    setSubmitAction
} = subscribeSlice.actions
export default subscribeSlice.reducer;
