import {createSlice} from "@reduxjs/toolkit";
import {fetchAsyncContacts, getAsyncContact} from "./contactThunk";


const initialState = {
    items: {
        data: [],
    },
    contact: null
}

const contactSlice = createSlice({
    name: `contact`,
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [fetchAsyncContacts.fulfilled]: (state, {payload}) => {
            return {...state, items: payload}
        },
        [getAsyncContact.fulfilled]: (state, {payload}) => {
            return {...state, contact: payload}
        },
    },
})


export const getMyContacts = (state) => state.contact.items
export const getContact = (state) => state.contact.contact

export default contactSlice.reducer
