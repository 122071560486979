import forgotImg from '../../common/assets/img/explan/stand_think.gif'
import {Link} from "react-router-dom";
import {BiChevronLeft} from "react-icons/bi";
import imgLogoSmall from "../../common/assets/img/mali/logo_small.png";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {resetPasswordAsyncUser} from "../../features/reducer/user/userThunk";
import {notificationErrorCustomMessage} from "../../common/config/message";
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState(``)
    const [errors, setErrors] = useState(false)
    const {t} = useTranslation();
    useEffect(() => {

    }, [])

    const handleInput = (e) => {
        const {value} = e.target
        setErrors(false)
        setEmail(value)
    }
    const handleSendLinkPassword = () => {
        if (email === ``) {
            notificationErrorCustomMessage(t('forgotpass.text-please-input-email'), `info`)
            setErrors(true)
            return
        }
        dispatch(resetPasswordAsyncUser(email))
    }

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
                        <div className="flex-row text-center mx-auto">
                            <img src={forgotImg} alt="Auth Cover Bg color" width="400" className="img-fluid authentication-cover-img"/>
                            <div className="mx-auto">
                                <h3>{t('forgotpass.header-title-left')}</h3>
                                <p>
                                    {t('forgotpass.bottom-title-left')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="authentication-bg d-flex col-12 col-lg-5 col-xl-4 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="/" className="app-brand-link gap-2 mb-2">
                                    <img width="40%" src={imgLogoSmall}/>
                                </a>
                            </div>
                            <h4 className="mb-2">{t('forgotpass.header-title-right')}</h4>
                            <p className="mb-4">{t('forgotpass.bottom-title-right')}</p>
                            <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" action="javascript:void(0);" method="POST" noValidate="novalidate">
                                <div className={`mb-3 fv-plugins-icon-container ${errors ? `has-error` : ``}`}>
                                    <label htmlFor="email" className="form-label">{t('forgotpass.title-topbox')}</label>
                                    <input type="text" className="form-control" onChange={e => handleInput(e)} id="email" name="email" placeholder={t('forgotpass.text-inbox')} autoFocus=""/>
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors ? t('forgotpass.alert-please-input-all') : ``}
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary d-grid w-100" onClick={() => handleSendLinkPassword()}>{t('forgotpass.text-send-link-button')}</button>
                                <div></div>
                                <input type="hidden"/>
                            </form>
                            <div className="text-center">
                                <Link to="/login" className="d-flex justify-content-center align-items-center">
                                    <BiChevronLeft className="scaleX-n1-rtl"/> {t('forgotpass.text-come-back-button')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
