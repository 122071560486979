import httpClient, {httpObjQueryString} from "../config/httpClient";

export const loginUserApi = (username, password) => {
    return httpClient.post(`/user/login`, {
        username: username,
        password: password
    })
}

export const fetchUsersApi = (filter) => {
    return httpClient.get(`/users?${httpObjQueryString(filter)}`)
}

export const getUserApi = (id) => {
    return httpClient.get(`/user/${id}`)
}

export const newUserApi = (data) => {
    return httpClient.post(`/user`, data)
}

export const updateUserApi = (id, data) => {
    return httpClient.put(`/user/${id}`, data)
}

export const deleteUserApi = (id) => {
    return httpClient.delete(`/user/${id}`)
}

export const updateProfileApi = (data) => {
    return httpClient.put(`/user/me`, data)
}

export const getProfileApi = () => {
    return httpClient.get(`/user/me`)
}

export const changePasswordApi = (username, data) => {
    return httpClient.put(`/user/${username}/password`, data)
}
