import {userImageSrc} from "../../utility/ImageUserProfile";
import ReactTooltip from "react-tooltip";
import {ContactType} from "../../../common/config/app";
import React, {useEffect, useState} from "react";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {useDispatch} from "react-redux";
import {addAsyncContact, updateAsyncContact} from "../../../features/reducer/contact/contactThunk";
import {AiOutlineUpload} from "react-icons/ai";
import {BiReset} from "react-icons/bi";
import {NotificationManager} from "react-light-notifications";
import imgProfile from "../../../common/assets/img/mali/profile_temp/img.jpg";
import {useTranslation} from "react-i18next";

const ReceiverAddByManual = ({search, searchType, resetSearchResult, gUser}) => {
    const dispatch = useDispatch()
    const [user, setUser] = useState()
    const [image, setImage] = useState()
    const {t} = useTranslation();


    useEffect(() => {
        if (typeof gUser === `undefined`) {
            setUser({
                ...user,
                [searchType]: search
            })
        }
    }, [searchType]);

    useEffect(() => {
        if (typeof gUser !== `undefined`) {
            // console.log(gUser)
            setUser(gUser)
        }
    }, [gUser]);


    const handleSetContactType = (e) => {
        const {checked, value} = e.target
        setUser({
            ...user,
            type: checked ? value : ""
        })
    }

    const handleInput = (e) => {
        const {value, name} = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleSubmit = () => {
        if (typeof user?.type === `undefined` || user.type === "") {
            notificationErrorCustomMessage(t('addreceiver.alert-choose-type-receiver'))
            return
        }

        const required = ['firstName', 'lastName', 'nickName']
        let error = false
        required.map(r => {
            if (typeof user[r] === `undefined` || user[r] === ``) {
                error = true
                return
            }
        })
        if (error) {
            notificationErrorCustomMessage(t('addreceiver.alert-please-input-all'))
            return
        }
        if (typeof user?.id !== `undefined` && user?.id != ``) {
            const dt = {...user, image: image}
            dispatch(updateAsyncContact(dt))
        } else {
            dispatch(addAsyncContact(user))
        }
    }

    const handleFileChange = (event, name = `image`, size = (1024 * 5)) => {
        let files = event.target.files;
        if (typeof files[0]?.size == 'undefined') {
            setImage(``)
            return false
        }
        if (files[0].size / 1024 > size) {
            // NotificationManager.warning({
            //     title: "แจ้งเตือน",
            //     message: "ขนาดไฟล์ใหญ่เกินกว่าที่กำหนด",
            // });
            notificationErrorCustomMessage(t('addreceiver.alert-maximum-limit-file'), `warning`)
            return false
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setImage(e.target.result)
        }
    }

    return (
        <>

            {user?.id && (
                <div className="gap-4 d-flex align-items-start align-items-sm-center">
                    {image ? (
                        <>
                            <div className={`avatar-image normal`}>
                                <div className={`img-card-placeholder img-card-s1`}>
                                    <div className="image" style={{backgroundImage: `url(${image})`}}>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`avatar-image normal`}>
                                <div className={`img-card-placeholder img-card-s1`}>
                                    <div className="image" style={{backgroundImage: `url(${user?.imageUrl ? userImageSrc(user) : imgProfile})`}}>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="button-wrapper">
                        <label htmlFor="upload" className="btn btn-primary me-2 mb-4" tabIndex="0">
                            <span className="d-none d-sm-block">{t('addreceiver.text-edit-image')}</span>
                            <AiOutlineUpload className=" d-sm-none d-block"/>
                            <input
                                onChange={e => handleFileChange(e)}
                                type="file"
                                id="upload"
                                className="account-file-input"
                                hidden
                                accept="image/png, image/jpeg"
                            />
                        </label>

                        <button type="button" className="btn btn-label-secondary account-image-reset d-none mb-4">
                            <BiReset className=" d-sm-none d-block "/>
                            <span className="d-none d-sm-block">{t('addreceiver.text-reset-image')}</span>
                        </button>

                        <p className="mb-0">{t('addreceiver.text-support-ext')}</p>
                    </div>
                </div>
            )}


            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label">{t('addreceiver.text-name')} <span className="text-danger">*</span></label>
                        <input
                            defaultValue={user?.firstName}
                            type="text"
                            id="modalEditUserFirstName"
                            name="firstName"
                            onChange={e => handleInput(e)}
                            className="form-control"
                            placeholder=""
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="modalEditUserLastName">{t('addreceiver.text-lastname')} <span className="text-danger">*</span></label>
                        <input
                            defaultValue={user?.lastName}
                            type="text"
                            id="modalEditUserLastName"
                            name="lastName"
                            onChange={e => handleInput(e)}
                            className="form-control"
                            placeholder=""
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="modalEditUserName">{t('addreceiver.text-nickname')} <span className="text-danger">*</span></label>
                        <input
                            defaultValue={user?.nickName}
                            type="text"
                            id="modalEditUserName"
                            name="nickName"
                            onChange={e => handleInput(e)}
                            className="form-control"
                            placeholder=""
                        />
                    </div>
                </div>
                <div className={`col-12 ${searchType == `email` && `d-none`}`}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="modalEditUserEmail">{t('addreceiver.text-email')}</label>
                        <input
                            defaultValue={user?.email}
                            value={searchType == `email` ? search : user?.email}
                            type="text"
                            id="modalEditUserEmail"
                            name="email"
                            onChange={e => handleInput(e)}
                            className="form-control"
                            placeholder=""
                        />
                    </div>
                </div>
                <div className={`col-12 ${searchType == `phone` && `d-none`}`}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="modalEditUserEmail">{t('addreceiver.text-telephone-number')}</label>
                        <input
                            defaultValue={user?.phone}
                            type="text"
                            id="modalEditUserPhone"
                            name="phone"
                            value={searchType == `phone` ? search : user?.phone}
                            onChange={e => handleInput(e)}
                            className="form-control"
                            placeholder=""
                        />
                    </div>
                </div>


                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="modalEditUserEmail">{t('addreceiver.text-note')}</label>
                        <textarea className="form-control fix_min_height" rows="3" name="note" defaultValue={user?.note} onChange={e => handleInput(e)}></textarea>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <label className="form-label" htmlFor="modalEditUserPhone">{t('addreceiver.text-choose-receiver-form')}</label>
                <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                <div className="row">
                    {ContactType.map(type => (
                        <div className="col-md mb-2 mb-md-0">
                            <div className={`form-check custom-option custom-option-icon ${user?.type == type.type ? `border_acive_theme` : ``}`}>
                                <label className="form-check-label custom-option-content" htmlFor={type.type}>
                                    <span className="custom-option-body">
                                        <div className="mb-1">{type.icon}</div>
                                        <div className={`badge mb-1 rounded-pill ${type.className}`} data-tip={`${type.name}`}>{type.type}</div>
                                        <div><small>{type.name}</small></div>
                                    </span>
                                    <input
                                        name="customRadioIcon"
                                        className="form-check-input"
                                        type="radio"
                                        value={type.type}
                                        onClick={e => handleSetContactType(e)}
                                        id={type.type}
                                        checked={user?.type == type.type}
                                    />
                                </label>
                            </div>
                        </div>
                    ))}


                </div>
            </div>

            <div className="mt-4 text-center">
                <button type="button" onClick={() => handleSubmit()} className="btn btn-primary me-1 me-sm-3">{t('addreceiver.text-confirm-page')}</button>

                <button
                    type="button"
                    onClick={resetSearchResult}
                    className="btn btn-label-secondary"
                    aria-label={t('addreceiver.text-cancel-page')}
                >
                    {t('addreceiver.text-cancel-page')}
                </button>
            </div>
        </>
    )
}
export default ReceiverAddByManual
