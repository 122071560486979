import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {activeMenu} from "../../../features/reducer/menu/menuSlice";
import logo from '../../../common/assets/img/mali/icon_app.png'
import writememo_banner_light from "../../../common/assets/img/illustrations/shopping-girl-light.png";
import ReactTooltip from "react-tooltip";
import imgProfileCot from "../../../common/assets/img/mali/profile_temp/cot.jpg";
import {MdOutlineMarkEmailUnread, MdInfoOutline} from "react-icons/md";
import {RiSlideshow4Line} from "react-icons/ri";
import {FaPaperPlane, FaInfo} from "react-icons/fa";
import {BsFillPersonFill} from "react-icons/bs";
import {CgLock} from "react-icons/cg";
import writememo_profile3 from "../../../common/assets/img/mali/profile_temp/zhai.jpg";
import {VscMailRead, VscTrash} from "react-icons/vsc";

const NotificationList = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(activeMenu({menu: ``}))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="container-xxl container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">User Profile /</span> Notification
                </h4>

                <div className="card">
                    <div className="card-header border-bottom">
                        <div className="col-12">
                            <img
                                src={writememo_banner_light}
                                className="img-fluid rounded-2 w-100 border"
                                alt="shopping girl"
                                data-app-light-img="{writememo_banner_light}"
                                data-app-dark-img="{writememo_banner_dark}"
                            />
                        </div>
                    </div>


                    <div className="card-datatable table-responsive">
                        <table className="datatables-users table border-top">
                            <thead>
                            <tr>
                                <th className="table_date">วันที่แจ้ง</th>
                                <th>From</th>
                                <th>ประเภท</th>
                                <th>Title</th>
                                <th>สถานะ</th>
                                <th>จัดการ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="DateMonth">2 นาทีที่ผ่านมา</div>
                                </td>
                                <td>
                                    <ul className="table_list_avatar">
                                        <li>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={logo} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="noti_type_icon">
                                    <div className="text-truncate d-flex align-items-center security">
                                        <div className="badge badge-center rounded-pill bg-label-danger w-px-30 h-px-30 me-2">
                                            <CgLock className="icon"/>
                                        </div>
                                        Security
                                    </div>
                                </td>
                                <td>
                                    <span>มีการล๊อคอินเข้าสู่ระบบจากสถานที่อื่น โปรดตรวจสอบว่าเป็นคุณหรือไม่</span>
                                </td>
                                <td>
                                    <span className="badge bg-mali" data-tip="ยังไม่เปิดอ่าน"><MdOutlineMarkEmailUnread className="icon_table_size"/> ยังไม่ได้เปิดอ่าน</span>
                                </td>
                                <td>

                                    <Link to="/notification/view" data-tip="ดูข้อมูลแจ้งเตือน">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <Link to="/notification/del" data-tip="ลบรายการแจ้งเตือน"><VscTrash className="manage_icon icon_table_size"/></Link>
                                </td>
                            </tr>
                            <tr>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="DateMonth">10 นาทีที่ผ่านมา</div>
                                </td>
                                <td>
                                    <ul className="table_list_avatar">
                                        <li>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={logo} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>

                                <td className="noti_type_icon">
                                    <div className="text-truncate d-flex align-items-center notice">
                                        <div className="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2">
                                            <FaInfo className="icon"/>
                                        </div>
                                        Notice
                                    </div>
                                </td>
                                <td>
                                    <span>สมาชิกท่านใดที่ยังไม่ได้ลงทะเบียน KYC กับทางระบบ สามารถจัดการได้จนถึงวันที่ 15 มิถุนายน 2565 หากเลยวันดังกล่าวระบบจะหยุดการใช้งานชั่วคราว</span>
                                </td>
                                <td>
                                    <span className="badge bg-mali" data-tip="ยังไม่เปิดอ่าน"><MdOutlineMarkEmailUnread className="icon_table_size"/> ยังไม่ได้เปิดอ่าน</span>
                                </td>
                                <td>

                                    <Link to="/notification/view" data-tip="ดูข้อมูลแจ้งเตือน">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <Link to="/notification/del" data-tip="ลบรายการแจ้งเตือน"><VscTrash className="manage_icon icon_table_size"/></Link>
                                </td>
                            </tr>
                            <tr>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="DateMonth">15 นาทีที่ผ่านมา</div>
                                </td>
                                <td>
                                    <ul className="table_list_avatar">
                                        <li>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={logo} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="noti_type_icon">
                                    <div className="text-truncate d-flex align-items-center receiver">
                                        <div className="badge badge-center rounded-pill bg-label-success w-px-30 h-px-30 me-2">
                                            <BsFillPersonFill className="icon"/>
                                        </div>
                                        Receiver
                                    </div>
                                </td>
                                <td>
                                    <span>ยินดีด้วย! มีผู้รับเจตนาของคุณได้ลงทะเบียนการใช้งานกับมะลิเรียบร้อย โปรดตรวจสอบรายละเอียดเพื่อยืนยันว่าใช่บุคคลที่คุณต้องการส่งข้อมูลถึงหรือไม่</span>
                                </td>
                                <td>
                                    <span className="badge bg-mali" data-tip="ยังไม่เปิดอ่าน"><MdOutlineMarkEmailUnread className="icon_table_size"/> ยังไม่ได้เปิดอ่าน</span>
                                </td>
                                <td>

                                    <Link to="/notification/view" data-tip="ดูข้อมูลแจ้งเตือน">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <Link to="/notification/del" data-tip="ลบรายการแจ้งเตือน"><VscTrash className="manage_icon icon_table_size"/></Link>
                                </td>
                            </tr>
                            <tr>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="DateMonth">1 ชั่วโมงที่ผ่านมา</div>
                                </td>
                                <td>
                                    <ul className="table_list_avatar">
                                        <li>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={logo} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="noti_type_icon">
                                    <div className="text-truncate d-flex align-items-center memo">
                                        <div className="badge badge-center rounded-pill bg-label-primary w-px-30 h-px-30 me-2">
                                            <FaPaperPlane className="icon"/>
                                        </div>
                                        Memo
                                    </div>
                                </td>
                                <td>
                                    <span>มีผู้ใช้มะลิเขียน MEMO ถึงคุณ 1 ฉบับ</span>
                                </td>
                                <td>
                                    <span className="badge bg-mali" data-tip="ยังไม่เปิดอ่าน"><MdOutlineMarkEmailUnread className="icon_table_size"/> ยังไม่ได้เปิดอ่าน</span>
                                </td>
                                <td>

                                    <Link to="/notification/view" data-tip="ดูข้อมูลแจ้งเตือน">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <Link to="/notification/del" data-tip="ลบรายการแจ้งเตือน"><VscTrash className="manage_icon icon_table_size"/></Link>
                                </td>
                            </tr>
                            <tr>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="DateMonth">3 ชั่วโมงที่ผ่านมา</div>
                                </td>
                                <td>
                                    <ul className="table_list_avatar">
                                        <li>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={imgProfileCot} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td className="noti_type_icon">
                                    <div className="text-truncate d-flex align-items-center memo">
                                        <div className="badge badge-center rounded-pill bg-label-primary w-px-30 h-px-30 me-2">
                                            <FaPaperPlane className="icon"/>
                                        </div>
                                        Memo
                                    </div>
                                </td>
                                <td>
                                    <span>PAWARIS WONGKURSORN ได้เขียน MEMO ถึงคุณ 1 ฉบับ</span>
                                </td>
                                <td>
                                    <span className="badge bg-mali-darft" data-tip="เปิดอ่านแล้ว"><VscMailRead className="icon_table_size"/> เปิดอ่านแล้ว</span>
                                </td>
                                <td>

                                    <Link to="/notification/view" data-tip="ดูข้อมูลแจ้งเตือน">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <Link to="/notification/del" data-tip="ลบรายการแจ้งเตือน"><VscTrash className="manage_icon icon_table_size"/></Link>
                                </td>
                            </tr>

                            <tr>
                                <td className="sorting_1 table_date">
                                    <ReactTooltip place="top" effect="solid" className="tooltip_style"/>
                                    <div className="DateMonth">1 วันที่ผ่านมา</div>
                                </td>
                                <td>
                                    <ul className="table_list_avatar">
                                        <li>
                                            <div className="avatar-wrapper">
                                                <div className="avatar avatar-sm me-3 ">
                                                    <img src={logo} alt="Avatar" className="rounded-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>

                                <td className="noti_type_icon">
                                    <div className="text-truncate d-flex align-items-center notice">
                                        <div className="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2">
                                            <FaInfo className="icon"/>
                                        </div>
                                        Notice
                                    </div>
                                </td>
                                <td>
                                    <span>ยินด้วย! คุณได้ทำการเขียน MEMO ใหม่ครั้งแรก เราหวังว่าคุณจะเขียนเพิ่มเรื่อยๆอีกนะ</span>
                                </td>
                                <td>
                                    <span className="badge bg-mali-darft" data-tip="เปิดอ่านแล้ว"><VscMailRead className="icon_table_size"/> เปิดอ่านแล้ว</span>
                                </td>
                                <td>

                                    <Link to="/notification/view" data-tip="ดูข้อมูลแจ้งเตือน">
                                        <RiSlideshow4Line className="manage_icon icon_table_size"/></Link>
                                    <Link to="/notification/del" data-tip="ลบรายการแจ้งเตือน"><VscTrash className="manage_icon icon_table_size"/></Link>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default NotificationList
