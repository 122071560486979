import httpClient from "../config/httpClient";


export const addSubscribeApi = (data) => {
    return httpClient.post(`/subscribe`, data)
}

export const addSubscribeReddemCodeApi = (data) => {
    return httpClient.post(`/subscribe/redeem`, data)
}

export const addSubscribeOmiseApi = (data) => {
    return httpClient.post(`/subscribe/omise`, data)
}

export const fetchPackageApi = () => {
    return httpClient.get(`/subscribe/packages`)
}
