import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {memoWriteState} from "../../../../features/reducer/memo/memoWriterSlice";
import React, {useEffect, useState} from "react";
import {getNewAsyncMemo} from "../../../../features/reducer/memo/memoWriterThunk";
import banner_cover from "../../../../common/assets/img/cover/cover1.jpg";
import ImageUserProfile from "../../../utility/ImageUserProfile";
import MemoTypeTag from "../../../utility/MemoTypeTag";
import moment from "moment";
import nl2br from "react-nl2br";
import {LinkPreview} from "@dhaiwat10/react-link-preview";
import {AiFillEye, AiFillLock} from "react-icons/ai";
import {Link, useNavigate} from "react-router-dom";
import {RiCheckboxCircleFill, RiCloseCircleFill, RiDeleteBin6Line, RiEditLine} from "react-icons/ri";
import {confirmAlert} from "react-confirm-alert";
import {deleteAsyncMemo} from "../../../../features/reducer/memo/memoThunk";
import {
    MemoDeathTrigger,
    MemoKycRequired,
    MemoReceiverAlert,
    MemoStatus,
    MemoType
} from "../../../../common/config/app";
import {useTranslation} from "react-i18next";

const MemoView = () => {

    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const {id} = useParams();
    const navigate = useNavigate();
    const [description, setDescription] = useState()
    const {t} = useTranslation();

    useEffect(() => {
        if (typeof id !== `undefined`) {
            const memoId = id
            dispatch(getNewAsyncMemo(memoId))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (memo?.widgets?.length) {
            setDescription({
                deathTrigger: MemoDeathTrigger.find(v => v.key == memo?.description?.deathTrigger),
                receiverAlert: MemoReceiverAlert.find(v => v.key == memo?.description?.receiverAlert),
                type: MemoType.find(v => v.key == memo?.description?.type),
                kyc: MemoKycRequired.find(v => v.key == memo?.description?.kycRequired),
                status: MemoStatus.find(v => v.key == memo?.description?.memoStatus),
            })
        }
    }, [memo.widgets])

    const handleSearch = () => {
        navigate("/memo/me")
    }

    const ClickConfirmAlert = (e, id) => {
        e.preventDefault()
        confirmAlert({
            title: t('writememo.view-delete-confirm'), message: t('writememo.view-delete-confirm-msg'), buttons: [{
                label: t('writememo.step-alert-save-draft-msg-yes'), onClick: () => {
                    dispatch(deleteAsyncMemo({id, handleSearch}))
                }
            }, {
                label: t('writememo.step-alert-save-draft-msg-no')
            }]
        });
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="breadcrumb-wrapper py-3 mb-4">
                    <span className="text-muted fw-light">MALI / {t('writememo.header-navi-my-memo')} /</span> {t('writememo.header-navi-view')}
                </h4>

                <div className="row">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="user-profile-header-banner">
                                <img src={banner_cover} alt="Banner image" className="rounded-top"/>
                                <div className="cover_opacity"></div>

                                <div className="boxcenter">
                                    <h4>"{memo?.description?.title}"</h4>
                                    <div className="owner">
                                        <div className="avatar-wrapper d-flex justify-content-center">
                                            <ImageUserProfile size={`lg`} user={memo?.writter}/>
                                        </div>
                                        <div className="owner_name">{memo?.writter?.firstName} {memo?.writter?.lastName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                <div className="mt-n2 flex-shrink-0 mx-auto mx-sm-0">
                                    <div className="ml-4">
                                        <ImageUserProfile size={`lg`} className={'sBorder'} user={memo?.receivers[0]}/>
                                    </div>
                                </div>
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div className="gap-4 d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-center align-items-sm-start align-items-md-end mx-4">
                                        <div className="user-profile-info">
                                            <span>{t('writememo.view-topic-memo-to')}</span>
                                            <h4 className="mb-1">
                                                {memo?.receivers[0]?.firstName} {memo?.receivers[0]?.lastName}
                                                {memo?.receivers && memo?.receivers?.length > 0 && memo?.receivers.map((item, i) => (
                                                    <>
                                                        {i > 0 &&
                                                            <span> / {item.firstName} {item.lastName}</span>
                                                        }
                                                    </>
                                                ))}
                                            </h4>
                                            <div className="mb-2"><MemoTypeTag name={memo?.description?.type}/></div>
                                            <div>
                                                <ul className="table_list_avatar mb-0">
                                                    {memo?.receivers && memo?.receivers?.length > 0 && memo?.receivers.map(r => (
                                                        <li className="mr-1">
                                                            <ImageUserProfile size={`sm`} user={r}/>
                                                        </li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row invoice-edit">
                    <div className="col-12 col-lg-9 mb-4 mb-lg-0">
                        <div className="card invoice-preview-card">
                            <div className="text_top_invoice">{t('writememo.preview-topic-moment-date')} {moment(memo?.description?.updatedAt !== `` ? memo?.description?.updatedAt : new Date()).format("YYYY/MM/DD HH:mm")} {t('writememo.preview-topic-moment-date-2')}</div>
                            <div className="card-body widget_content">
                                {memo?.widgets?.length && memo?.widgets?.map(widget => (
                                    <div className="mb-5">
                                        {widget?.majorType === `message` && (
                                            <div>{nl2br(widget?.content)}</div>
                                        )}
                                        {widget?.majorType === `image` && (
                                            <div className="text-center"><img src={widget?.content} alt=""/></div>
                                        )}

                                        {widget?.majorType === `video` && (
                                            <div className="text-center">
                                                <div className="image-preview">
                                                    <video key={widget?.fileName} controls>
                                                        <source type="video/mp4" src={widget?.content}/>
                                                    </video>
                                                    <div className="mt-2 text-center">
                                                        {widget?.fileName}
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                        {widget?.majorType === `audio` && (
                                            <div className="text-center">
                                                <div className="image-preview">
                                                    <audio key={widget?.fileName} controls>
                                                        <source src={widget?.content}/>
                                                    </audio>
                                                    <div className="mt-2 text-center">
                                                        {widget?.fileName}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {widget?.majorType === `link` && (
                                            <div className="row justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="widget_content_type">
                                                        <LinkPreview className="link" url={widget?.content}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {widget?.majorType === `password` && (
                                            <div className="widget_content_type">
                                                <div className="col-md password">
                                                    <div className="border mb-3">
                                                        <div className="row g-0">
                                                            <div className="col-md-4 bg d-flex justify-content-center  align-items-center  ">
                                                                <AiFillLock className="icon"/>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{widget?.url}</h5>
                                                                    <p className="card-text">
                                                                        {widget?.username}
                                                                    </p>
                                                                    <p className="card-text d-flex  ">
                                                                        <span className="text-muted">**********</span>
                                                                        <AiFillEye className="icon_small"/>
                                                                    </p>
                                                                    <p className="card-text">
                                                                        <small>{widget?.title}</small>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                    <div className="invoice-actions col-12 col-lg-3">
                        <div className="card mb-4">
                            <div className="card-body">
                                <Link to={`/memo/${memo?.description?.code}`} className="menu-link">
                                    <button type="button" className="btn btn-primary  w-100">
                                        <RiEditLine className="icon" data-tip={t('writememo.view-btn-edit')}/>&nbsp; {t('writememo.view-btn-edit')}
                                    </button>
                                </Link>

                                <br/>

                                <a href="#" onClick={e => ClickConfirmAlert(e, memo?.description?.code)} className="menu-link">
                                    <button type="button" className="btn btn-label-danger suspend-user  w-100">
                                        <RiDeleteBin6Line className="icon" data-tip={t('writememo.view-btn-del')}/>&nbsp; {t('writememo.view-btn-del')}
                                    </button>
                                </a>
                            </div>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="gap-4 d-flex flex-wrap mt-4 mb-5">
                                    <div className="d-flex flex-column me-2">
                                        <h6>{t('writememo.setting-input-time-perior')}</h6>
                                        <span data-tip={t('writememo.view-text-deathtrigger-work')} className="badge bg-label-danger">{description?.deathTrigger?.name}</span>
                                    </div>
                                    <div className="d-flex flex-column me-2">
                                        <h6>{t('writememo.view-title-status')}</h6>
                                        {description?.status?.key === `draft` &&
                                            <span className="badge bg-mali-darft">{description?.status?.name}</span>}
                                        {description?.status?.key === `publish` &&
                                            <span className="badge bg-success">{description?.status?.name}</span>}
                                    </div>
                                    <div className="d-flex flex-column me-2">
                                        <h6>{t('writememo.review-title-condition')}</h6>
                                        {description?.kyc?.key === 1 &&
                                            <span className="d-flex  align-items-center"><RiCheckboxCircleFill className="color_mali margin_right_3px"/> {description?.kyc?.name}
                                            </span>}
                                        {description?.kyc?.key === 0 &&
                                            <span className="d-flex  align-items-center"><RiCloseCircleFill className="color_mali margin_right_3px text-danger"/> {description?.kyc?.name}
                                            </span>}
                                    </div>
                                    <div className="d-flex flex-column me-2">
                                        <h6>{t('writememo.view-title-sms-alert')}</h6>
                                        {description?.receiverAlert?.key === 1 &&
                                            <span className="d-flex  align-items-center"><RiCheckboxCircleFill className="color_mali margin_right_3px"/> {description?.receiverAlert?.name}
                                            </span>}
                                        {description?.receiverAlert?.key === 0 &&
                                            <span className="d-flex  align-items-center"><RiCloseCircleFill className="color_mali margin_right_3px text-danger"/> {description?.receiverAlert?.name}
                                            </span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="invoice-actions col-12 col-lg-3">


                    </div>
                </div>

            </div>
        </>
    )
}

export default MemoView
