import cover_step4 from "../../../../common/assets/img/cover/cover_write_memo_step4.gif";
import banner_cover from "../../../../common/assets/img/cover/cover1.jpg";
import ImageUserProfile from "../../../utility/ImageUserProfile";
import MemoTypeTag from "../../../utility/MemoTypeTag";
import moment from "moment";
import nl2br from "react-nl2br";
import {LinkPreview} from "@dhaiwat10/react-link-preview";
import {AiFillEye, AiFillLock} from "react-icons/ai";
import {GrFormNext, GrFormPrevious} from "react-icons/gr";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {memoWriteState, setMemoStep} from "../../../../features/reducer/memo/memoWriterSlice";
import {useTranslation} from "react-i18next";

const MemoPreview = () => {
    const dispatch = useDispatch()
    const memo = useSelector(memoWriteState)
    const [description, setDesc] = useState()
    const [profile, setProfile] = useState()
    const {t} = useTranslation();

    useEffect(() => {
        setDesc(memo.description)
    }, [memo.description])

    useEffect(() => {
        setProfile(memo.writter)
    }, [memo.writter])

    const handleStep = key => {
        dispatch(setMemoStep(key))
    }

    return (
        <>
            <div className="row">
                <div className="col-12">

                    <div className="d-flex col-12 justify-content-between">
                        <button className="btn btn-primary btn-prev" onClick={() => handleStep('write')}>
                            <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                        </button>
                        <button className="btn btn-primary btn-next" onClick={() => handleStep('complete')}>
                            {t('writememo.next-button-page2')}<GrFormNext className="memo_write_icon"/>
                        </button>
                    </div>
                    <div className="img-fluid rounded-2 py-5 w-100 d-flex align-items-center  flex-column justify-content-center">
                        <h4 className="fw-bold">4. {t('writememo.topic-example-guide-h1')}</h4>
                        <img className="image_gif_cover" src={cover_step4}/>
                    </div>
                    <div className="box_border mb-4">
                        <div className="user-profile-header-banner">
                            <img src={banner_cover} alt="Banner image" className="rounded-top"/>
                            <div className="cover_opacity"></div>

                            <div className="boxcenter">
                                <h4>"{description?.title}"</h4>
                                <div className="owner">
                                    <div className="avatar-wrapper d-flex justify-content-center">
                                        <ImageUserProfile size={`lg`} user={profile}/>
                                    </div>
                                    <div className="owner_name">{profile?.firstName} {profile?.lastName}</div>
                                </div>
                            </div>
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                            <div className="mt-n2 flex-shrink-0 mx-auto mx-sm-0">
                                <div className="ml-4">
                                    <ImageUserProfile size={`lg`} className={'sBorder'} user={memo?.receivers[0]}/>
                                </div>
                            </div>
                            <div className="flex-grow-1 mt-3 mt-sm-5">
                                <div className="gap-4 d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-center align-items-sm-start align-items-md-end mx-4">
                                    <div className="user-profile-info">
                                        <span>{t('writememo.preview-topic-memo-to')}</span>
                                        <h4 className="mb-1">{memo?.receivers[0]?.firstName} {memo?.receivers[0]?.lastName}</h4>
                                        <div className="mb-2"><MemoTypeTag name={memo?.description?.type}/></div>
                                        <div>
                                            <ul className="table_list_avatar mb-0">
                                                {memo?.receivers && memo?.receivers?.length > 0 && memo?.receivers.map(r => (
                                                    <li className="mr-1">
                                                        <ImageUserProfile size={`sm`} user={r}/>
                                                    </li>))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-12 mb-4 mb-lg-0">
                <div className=" invoice-preview-card">
                    <div className="text_top_invoice pl-0">{t('writememo.preview-topic-moment-date')} {moment(memo?.description?.updatedAt !== `` ? memo?.description?.updatedAt : new Date()).format("YYYY/MM/DD HH:mm")} {t('writememo.preview-topic-moment-date-2')}</div>
                    {memo?.widgets?.length && memo?.widgets?.map(widget => (
                        <div className="mb-5">
                            {widget?.majorType === `message` && (
                                <div>{nl2br(widget?.content)}</div>
                            )}
                            {widget?.majorType === `image` && (
                                <div className="text-center"><img src={widget?.content} alt=""/></div>
                            )}

                            {widget?.majorType === `video` && (
                                <div className="text-center">
                                    <div className="image-preview">
                                        <video key={widget?.fileName} controls>
                                            <source type="video/mp4" src={widget?.content}/>
                                        </video>
                                        <div className="mt-2 text-center">
                                            {widget?.fileName}
                                        </div>
                                    </div>
                                </div>
                            )}


                            {widget?.majorType === `audio` && (
                                <div className="text-center">
                                    <div className="image-preview">
                                        <audio key={widget?.fileName} controls>
                                            <source src={widget?.content}/>
                                        </audio>
                                        <div className="mt-2 text-center">
                                            {widget?.fileName}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {widget?.majorType === `link` && (
                                <div className="row justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="widget_content_type">
                                            <LinkPreview className="link" url={widget?.content}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {widget?.majorType === `password` && (
                                <div className="widget_content_type">
                                    <div className="col-md password">
                                        <div className="border mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-4 bg d-flex justify-content-center  align-items-center  ">
                                                    <AiFillLock className="icon"/>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{widget?.url}</h5>
                                                        <p className="card-text">
                                                            {widget?.username}
                                                        </p>
                                                        <p className="card-text d-flex  ">
                                                            <span className="text-muted">**********</span>
                                                            <AiFillEye className="icon_small"/>
                                                        </p>
                                                        <p className="card-text">
                                                            <small>{widget?.title}</small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    ))}

                </div>
            </div>
            <div className="d-flex col-12 justify-content-between">
                <button className="btn btn-primary btn-prev" onClick={() => handleStep('write')}>
                    <GrFormPrevious className="memo_write_icon"/> {t('writememo.previous-button-page1')}
                </button>
                <button className="btn btn-primary btn-next" onClick={() => handleStep('complete')}>
                    {t('writememo.next-button-page2')}<GrFormNext className="memo_write_icon"/>
                </button>
            </div>
        </>
    )
}

export default MemoPreview
